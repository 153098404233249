/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SONOS_DEVICE_ACTION = gql`
  mutation sonosDeviceAction($deviceId: String!, $action: String!) {
    sonosDeviceAction(deviceId: $deviceId, action: $action)
  }
`;

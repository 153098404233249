/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_DEVICE_NEXT_SONG } from 'domain/dashboard/mutations/update-device-next-song';

export default function useUpdateDeviceNextSong() {

	const [updateDeviceNextSong, {loading}] = useMutation(UPDATE_DEVICE_NEXT_SONG, {
		onError: (e) => console.log('Could not switch song: ', e)
	});

	return { updateDeviceNextSong, loading };
}

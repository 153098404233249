/**
 * External dependencies
 */
import React from 'react';

const SystemDetail = ({ icon, title, info }) => {
  return (
    <div className="system-detail">
      {icon && <div className="system-detail__icon">{icon}</div>}

      <div className="system-detail__content">
        <h4>{title}</h4>

        <h6 style={{ lineHeight: '18px' }}>{info}</h6>
      </div>
    </div>
  );
};

export default SystemDetail;

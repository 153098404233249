import React, { useState, useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';

const artists = ['e.g. Talking Heads', 'e.g. Motorhead', 'e.g. Nirvana'];

const Artists = ({ setSelectedStack, stack }) => {
  const { businessName, handleArtistInputChange, artistValues } =
    useContext(BoardingContext);
  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      progress={{ firstBar: '100', secondBar: '66', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{businessName}</p>
        <p className="modal-boarding__title">On brand artists</p>

        {artists.map((artist, index) => (
          <InputBoarding
            value={artistValues[index]}
            onChange={handleArtistInputChange}
            name={index}
            key={artist}
            placeholder={artist}
            style={{ marginBottom: 10 }}
          />
        ))}
      </div>
    </BoardingWrapper>
  );
};

export default Artists;

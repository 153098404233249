/**
 * External dependencies
 */
import React, { memo } from 'react';
import { Toggle } from 'react-powerplug';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import FormAddDevice from 'blocks/forms/form-add-device';

const Devices = ({ children, showText, searchBar }) => {
  return (
    <div className="devices">
      <div className="devices__head">
        <div className="subtitle">
          <h4 style={{ fontSize: 16 }}>Your devices</h4>

          <Toggle>
            {({ on, toggle }) => (
              <>
                {showText > 0 && (
                  <Button
                    modifier="as-link"
                    onClick={toggle}
                    style={{ fontSize: 16 }}
                  >
                    Add a device
                  </Button>
                )}

                <Modal
                  subtitle="Curator"
                  title="Add a device"
                  visible={on}
                  onClose={toggle}
                >
                  <p>
                    To add a device provide us with the device tag number (6
                    -digits), the location name or store number.
                  </p>

                  <p>
                    If you are requesting access to multiple players, regions or
                    zones, please specify this in your message.
                  </p>

                  <FormAddDevice toggle={toggle} />
                </Modal>
              </>
            )}
          </Toggle>
        </div>
        {searchBar}
      </div>

      <div className="devices__body">{children}</div>
    </div>
  );
};

export default memo(Devices);

/**
 * External dependencies
 */
import React, { useEffect, useContext, useState } from 'react';
import * as rdd from 'react-device-detect';
import { useLocation } from 'react-router-dom';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLinks from 'components/card-links/card-links';
import CardLink from 'components/card-link/card-link';
import Button from 'components/button/button';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import SystemDetailProfile from 'components/system-detail/system-detail-profile';
import { ReactComponent as IcoDown } from 'assets/svg-icons/down.svg';
import { ReactComponent as IcoRight } from 'assets/svg-icons/right.svg';
import useOpenStripeCustomerPortal from 'domain/sonos/hooks/use-open-stripe-customer-portal';
import LogoutButton from 'components/logout-button/logout-mobile';
import Card from './components/card';

const permissions = [
  'Limited',
  'Super Admin',
  'Super User',
  'Admin',
  'Power User',
  'Authorized User',
];

const ProfilePage = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const { user } = useContext(AuthContext);
  const userInfo = {
    firstName: user?.profile?.name?.split(' ')[0] || null,
  };

  const route = useLocation().pathname;

  const { openStripeCustomerPortal, stripePortal } =
    useOpenStripeCustomerPortal();

  const handleClick = () => {
    setLoadingButton(true);
    return openStripeCustomerPortal({
      variables: {
        route,
      },
    });
  };
  useEffect(() => {
    console.log('user info updated');
  }, [user?.id]);

  useEffect(() => {
    if (
      stripePortal?.openStripeCustomerPortal?.startsWith(
        'https://billing.stripe.com/'
      )
    ) {
      window.location.href = stripePortal?.openStripeCustomerPortal;
    }
  }, [stripePortal]);

  const userDetails = (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 10,
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: 16 }}>
        <p style={{ fontSize: 13, opacity: 0.7, lineHeight: '20px' }}>Name</p>
        <p style={{ fontSize: 16, lineHeight: '20px' }}>
          {user?.profile?.name}
        </p>
      </div>

      <div style={{ marginBottom: 16 }}>
        <p style={{ fontSize: 13, opacity: 0.7, lineHeight: '20px' }}>Email</p>
        <p style={{ fontSize: 16, lineHeight: '20px' }}>
          {user?.emails ? user?.emails[0]?.address : 'Unavailable'}
        </p>
      </div>

      <div style={{ marginBottom: 16 }}>
        <p style={{ fontSize: 13, opacity: 0.7, lineHeight: '20px' }}>
          Company
        </p>
        <p style={{ fontSize: 16, lineHeight: '20px' }}>
          {user?.companyName || ''}
        </p>
      </div>

      <div style={{ marginBottom: 16 }}>
        <p style={{ fontSize: 13, opacity: 0.7, lineHeight: '20px' }}>
          Device permissions
        </p>
        <p style={{ fontSize: 16, lineHeight: '20px' }}>
          {permissions[user?.rolesConsole?.roleAudio]}
        </p>
      </div>

      <div>
        <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
          Sonos authorization
        </p>
        <p style={{ fontSize: 16, lineHeight: '20px' }}>
          {user?.profile?.sonosPermit ? 'Yes' : 'No'}
        </p>
      </div>
    </div>
  );

  const [sections, setSections] = useState([
    {
      title: 'Personal Details',
      subtitle: 'View and update',
      info:
        // <div>
        userDetails,
      /* <Button
            modifier="primary"
            style={{ marginTop: 15, padding: '13px 25px' }}
            onClick={() => console.log('Update!')}
          >
            Update
          </Button> */
      // </div>
      icon: false,
      iconShow: true,
    },
    {
      title: 'Payment Credentials',
      subtitle: '',
      info: (
        <Button
          modifier="primary"
          style={{ marginTop: 15, padding: '13px 25px' }}
          onClick={() => !loadingButton && handleClick()}
        >
          Update
        </Button>
      ),
      icon: true,
      iconShow: false,
    },
  ]);

  const handleExpand = (index) => {
    let tempSections = [...sections];
    tempSections[index] = {
      ...tempSections[index],
      icon: !tempSections[index].icon,
    };
    setSections(tempSections);
  };

  return rdd?.isMobileOnly ? (
    <DashboardLayout
      title={`Hey ${userInfo.firstName}! 👋`}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="profile"
    >
      {sections.map((item, index) => {
        return (
          <SystemDetailProfile
            info={item.icon && item.info}
            key={index}
            icon={
              item.icon ? (
                <IcoDown
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleExpand(index)}
                />
              ) : (
                <IcoRight
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleExpand(index)}
                />
              )
            }
            title={item.title}
            subtitle={item.subtitle}
            iconShow={item.iconShow}
          />
        );
      })}
      <div className="logout-mobile">
        <LogoutButton modifier="primary">Log Out</LogoutButton>
      </div>
    </DashboardLayout>
  ) : (
    <DashboardLayout
      title="Profile"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="profile"
    >
      <Card color="#3B45AB">
        <img
          src={require('../../assets/images/GroupProfile.png').default}
          style={{ flex: 1, height: '100%' }}
        />
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            height: 250,
            padding: 50,
            flexDirection: 'column',
          }}
        >
          <h3 style={{ fontSize: 30 }}>Hey {userInfo.firstName}! 👋</h3>
        </div>
      </Card>
      <CardLinks paddedBottom>
        <CardLink
          vertical
          to={`${route}/account`}
          title="Personal Details"
          subtitle="Check out and update your details"
          color="#0B72CE"
          graphic={require('assets/images/profile-1.png').default}
        />

        <CardLink
          vertical
          handleClicked={() => (!loadingButton ? handleClick() : null)}
          buttonText={!loadingButton ? 'Open' : 'Loading...'}
          // to="/"
          hasButton={user?.stripe?.subscriptionPlan?.length > 0}
          title="Payment Credentials"
          subtitle="Update or amend your payment plan"
          color="#FF0040"
          graphic={require('assets/images/profile-2.png').default}
        />

        <CardLink
          vertical
          // to="/"
          title="Communications"
          subtitle="Change how we get in touch"
          color="#0BCEC9"
          graphic={require('assets/images/profile-3.png').default}
        />
      </CardLinks>
    </DashboardLayout>
  );
};

export default ProfilePage;

/**
 * Internal dependencies
 */

import { useQuery } from '@apollo/client';

import { SONOS_QUERY } from '../query/get-sonos-device';

export default function useSonosDevice(userId) {
  const { data, loading, error, refetch } = useQuery(SONOS_QUERY, {
    variables: { userId },
  });

  return {
    device: data?.viewer?.sonosDevice,
    loading,
    error,
    refetch,
  };
}

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CHANGE_DEFAULT_DEVICEID = gql`
  mutation changeDefaultDeviceId($id: String!) {
    changeDefaultDeviceId(id: $id) {
      id
    }
  }
`;

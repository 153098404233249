import React from 'react';

function XSVG({ onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      onClick={onClick}
      className="svg-stroke"
      style={{ marginRight: 32 }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 1L1 17M1 1l16 16"
      ></path>
    </svg>
  );
}

export default XSVG;

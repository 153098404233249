import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import Tag from './category/tag';

const Vibe = ({ setSelectedStack, stack, vibes }) => {
  const { businessName, vibeValues, handleVibes } = useContext(BoardingContext);
  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      disabled={!vibeValues.length}
      progress={{ firstBar: '100', secondBar: '100', thirdBar: '50' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{businessName}</p>
        <p className="modal-boarding__title">What types of vibe?</p>
        <p className="modal-boarding__info">Select one or more</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {vibes.map((item) => (
            <Tag
              key={item?.name}
              title={item?.name}
              isSelected={vibeValues.includes(item?.name)}
              handleOnClick={() => handleVibes(item?.name)}
            />
          ))}
        </div>
      </div>
    </BoardingWrapper>
  );
};

export default Vibe;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_REMOTE_PLAYLIST_MODE = gql`
  mutation UpdateAudioDeviceRemotePlaylistMode($deviceId: String!) {
    updateAudioDeviceRemotePlaylistMode(deviceId: $deviceId) {
      id
    }
  }
`;

/**
 * External dependencies
 */
import React from 'react';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import CardLink from 'components/card-link/card-link';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import MusicListItem from '../components/music-list-item';
import _ from 'lodash';
import useSonosDevice from '../../sonos/hooks/use-sonos-device';
import useAuth from '../../auth/hooks/use-auth';

const SingleVibePage = ({
  match: {
    params: { id, vibe, sonosDeviceId },
  },
}) => {
  const isSonosDevice = !!sonosDeviceId;
  const { user } = useAuth();
  const { device } = isSonosDevice
    ? useSonosDevice(user?.id)
    : useSingleDevice(id);
  const { allScenes } = useAllScenes({
    deviceId: isSonosDevice ? sonosDeviceId : id,
  });

  const allMixes = allScenes?.filter((item) => {
    const genreUpperCase = item.vibe?.map((item) => item.toUpperCase());
    return genreUpperCase.includes(vibe);
  });

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={device?.name || ''}
      title={_.capitalize(vibe)}
      modifier="current-vibe"
    >
      <LoadingProvider loading={!allMixes}>
        {rdd?.isMobileOnly ? (
          <div style={{ marginBottom: 135 }}>
            <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
              {allMixes.length} Scenes Available
            </h4>

            {allMixes?.map((scene) => {
              return (
                <MusicListItem
                  key={scene.id}
                  to={
                    isSonosDevice
                      ? `/sonos/${sonosDeviceId}/search/all/scene/${scene.id}`
                      : `/${id}/search/all/scene/${scene.id}`
                  }
                  cover={scene.cover}
                  name={scene.name}
                  description={scene?.description}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              maxWidth: 980,
              width: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: 20,
              marginBottom: 35,
            }}
          >
            {allMixes?.map((scene) => (
              <CardLink
                triggerPlayNow={{
                  playlistId: scene?.id,
                  devicePlayType: 'SCENE',
                }}
                key={scene?.id}
                to={
                  isSonosDevice
                    ? `/sonos/${sonosDeviceId}/search/all/scene/${scene.id}`
                    : `/${id}/search/all/scene/${scene.id}`
                }
                cover={scene?.cover}
                title={scene?.name}
                color={stc(scene?.name)}
                style={{ margin: 0 }}
                hasButton
              />
            ))}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SingleVibePage;

import React from 'react';

const Tag = ({ title, isSelected, handleOnClick, isDisabled, style }) => {
  return (
    <div
      onClick={handleOnClick}
      style={{
        padding: '15px 23px',
        textAlign: 'center',
        height: 50,
        border: isSelected
          ? '1px solid #030416E5'
          : isDisabled
          ? '1px solid #EBEBEB'
          : '1px solid #AAAAAA',
        borderRadius: 13,
        color: isSelected ? '#FFFFFF' : '#AAAAAA',
        cursor: 'pointer',
        margin: '5px 10px 5px 0px',
        fontWeight: 'bold',
        backgroundColor: isSelected
          ? '#030416E5'
          : isDisabled
          ? '#EBEBEB'
          : 'transparent',
        ...style,
      }}
    >
      {title}
    </div>
  );
};

export default Tag;

import React from 'react';

function Play({ onClick, style, disabled }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="18"
      fill="none"
      viewBox="0 0 12 18"
      className={!disabled ? 'svg-stroke' : 'svg-stroke-disabled'}
      style={{ marginRight: 32, ...style }}
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l10 8-10 8V1z"
      ></path>
    </svg>
  );
}

export default Play;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const HUBSPOT = gql`
  query getHubSpot {
    viewer {
      getHubSpotData
    }
  }
`;

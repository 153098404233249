/**
 * External dependencies
 */
import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Player from 'components/player/player';
import CardLink from 'components/card-link/card-link';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import useDeviceSubscription from 'domain/devices/hooks/use-device-subscription';
import Page404 from 'views/404/404';

import ProgressRangeFooter from '../../../components/volume-slider/volume-slider-footer';

import VolumeSvg from '../../../assets/svgComponents/VolumeSvg';
import PauseSvg from '../../../assets/svgComponents/PauseSvg';
import SkipSvg from '../../../assets/svgComponents/SkipSvg';
import DisplaySvg from '../../../assets/svgComponents/DisplaySvg';
import PlaySvg from '../../../assets/svgComponents/PlaySvg';
import XSVG from '../../../assets/svgComponents/XSVG';

import CopySvg from '../../../assets/svgComponents/CopySvg';
import ShareSvg from '../../../assets/svgComponents/ShareSvg';
import useUpdateDeviceNextSong from '../hooks/use-update-device-next-song';
import useToggleManuallyPaused from '../../devices/hooks/use-toggle-manually-paused';

import { toast, Slide } from 'react-toastify';

const customId = 'prevent-duplicate';

import _ from 'lodash';
import SearchSection from '../../../components/search-section/search-section';
import * as rdd from 'react-device-detect';

import CurrentlyPlayingCard from '../../../components/current-playlist-card/currently-playing-card';
import CurrentlyPlayingCardMobile from '../../../components/current-playlist-card/currently-playing-card-mobile';
import useGetDisplayCode from '../../devices/hooks/use-get-display-code';
import AuthContext from '../../auth/contexts/auth-context/auth-context';

const colors = _.shuffle([
  '#3B46AB',
  '#3B7DAB',
  // '#670BCE',
  '#0B72CE',
  '#E96187',
  '#E961CB',
  '#C361E9',
  '#7F61E9',
  '#6187E9',
  '#FFBF00',
  '#FF4000',
  '#FF0040',
  '#0B11CE',
  '#3BAB69',
  '#0BCEC9',
  '#0BCE69',
]);

const getColors = [
  colors[_.random(0, 4)],
  colors[_.random(4, 7)],
  colors[_.random(7, 10)],
  colors[_.random(11, 14)],
];

const DashboardPage = ({
  match: {
    params: { id },
  },
}) => {
  const { user } = useContext(AuthContext);
  const { device, refetch } = useSingleDevice(id);
  const { updateDeviceNextSong } = useUpdateDeviceNextSong();
  const { toggleManuallyPaused } = useToggleManuallyPaused();
  const [online, setOnline] = useState(false);
  const { getDisplayCode, gotDisplayCode } = useGetDisplayCode();
  const [displayUrl, setDisplayUrl] = useState('...');
  const { deviceData } = useDeviceSubscription({
    deviceId: id,
    userToken: localStorage.getItem('sessionToken'),
  });

  useEffect(() => {
    if (
      deviceData &&
      device &&
      deviceData.AudioDeviceSubscription.node.currentSongId !==
        device?.currentSong.id
    ) {
      // call refetch if new subscription data comes in
      refetch();
    }
  }, [deviceData, refetch]);

  const volumeBar =
    user?.audioClient?.noVolumeBar && user?.rolesConsole?.roleAudio === 5;
  const playPauseButton =
    user?.audioClient?.noPlayPause && user?.rolesConsole?.roleAudio === 5;

  useEffect(() => {
    if (gotDisplayCode?.getDisplayCode) {
      setDisplayUrl(gotDisplayCode.getDisplayCode);
    }
  }, [gotDisplayCode]);

  const [deviceName, setDeviceName] = useState('');

  useEffect(() => {
    if (device) {
      setOnline(device.isOnline);
    }
    if (device?.currentPlaylist?.name !== deviceName) {
      setDeviceName(device?.currentPlaylist?.name);
    }
  }, [device]);

  const notifySkipLimit = () =>
    toast.warning('Sorry, you’re allowed only 3 skips per hour.', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      toastId: customId,
      style: { backgroundColor: '#E96187' },
      bodyStyle: { fontSize: 16, color: '#fff' },
      transition: Slide,
    });

  const notifySkipSong = () =>
    toast.info('Song skipped', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      toastId: customId,
      style: { backgroundColor: '#670BCE' },
      bodyStyle: { fontSize: 16, color: '#fff' },
      transition: Slide,
    });

  const handleNextClick = () => {
    if (device?.skipDisabled) {
      notifySkipLimit();
      return;
    }
    if (id) {
      updateDeviceNextSong({
        variables: {
          deviceId: id,
        },
      })
        .then(() => {
          notifySkipSong();
        })
        .catch((err) => {
          console.log('updateDeviceNextSong error:', err);
        });
    }
  };

  const handlePlayPause = () => {
    toggleManuallyPaused({
      variables: { deviceId: id, manuallyPaused: !device.manuallyPaused },
    });
  };

  if (device && !device) return <Page404 />;

  const [displayMode, setDisplayMode] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleDisplayMode = () => {
    setDisplayMode(true);
    getDisplayCode({ variables: { deviceId: device.id } });
  };

  const copyToClipboard = (input) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(input).then(
        () => {
          console.log('Copied to clipboard successfully.');
        },
        (err) => {
          console.log('Failed to copy the text to clipboard.', err);
        }
      );
    } else if (window.clipboardData) {
      window.clipboardData.setData('Text', input);
    }
    setCopied(true);
  };
  return (
    <DashboardLayout
      subtitle="Devices"
      title={device?.name || null}
      backgroundImage={`url(${
        require('assets/svg-icons/background-O.svg').default
      })`}
      modifier={cx('dashboard', { 'page--offline': !online })}
    >
      {/* removed loading props from player to avoid rerender visual  */}
      <Player
        clientId={id}
        loading={!device}
        title={device?.currentSong?.title || null}
        author={device?.currentSong?.author || null}
        album={device?.currentSong?.album || null}
        cover={device?.currentSong?.cover || null}
        lastOnline={device?.lastOnline || null}
        pausedStatus={device?.pausedStatus || ''}
        hasSchedule={device?.scheduleIds?.length > 0 || null}
        isPlaying={device?.isPlaying}
        manuallyPaused={device?.manuallyPaused}
        isOnline={online}
        hideVolumeBar={true}
      />
      {/* removed loading props from card links to avoid rerender visual  */}
      <div
        style={{
          height: 80,
          maxWidth: 980,
          backgroundColor: '#000',
          borderRadius: 13,
          marginBottom: 30,
          marginLeft: rdd?.isMobileOnly ? 13 : 0,
          marginRight: rdd?.isMobileOnly ? 13 : 0,

          display: 'flex',
          alignItems: 'center',
          justifyContent: displayMode ? 'space-between' : 'center',
        }}
      >
        {!displayMode ? (
          <>
            {!volumeBar && (
              <>
                <VolumeSvg />
                <ProgressRangeFooter
                  deviceId={id}
                  volume={device?.remoteVolume}
                />
              </>
            )}
            {!playPauseButton &&
              (device?.manuallyPaused ? (
                <PlaySvg onClick={handlePlayPause} />
              ) : (
                <PauseSvg onClick={handlePlayPause} />
              ))}
            <SkipSvg onClick={handleNextClick} />
            <DisplaySvg onClick={handleDisplayMode} />
          </>
        ) : (
          <>
            <div className="now-playing__metadata" style={{ padding: 25 }}>
              <p
                style={{
                  marginBottom: 5,
                  fontSize: rdd?.isMobileOnly ? 10 : 13,
                }}
              >
                Open your browser to https://display.activaire.com and enter
                your pairing code
              </p>
              <strong style={{ fontSize: 20 }}>
                {displayUrl?.toUpperCase()}
              </strong>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {copied && (
                <p
                  style={{
                    marginRight: 32,
                  }}
                >
                  Copied!
                </p>
              )}

              <CopySvg
                onClick={() =>
                  copyToClipboard(`https://display.activaire.com/${displayUrl}`)
                }
              />
              <ShareSvg
                onClick={() =>
                  window
                    .open(
                      `https://display.activaire.com/${displayUrl}`,
                      '_blank'
                    )
                    .focus()
                }
              />
              <XSVG onClick={() => setDisplayMode(false)} />
            </div>
          </>
        )}
      </div>
      {device?.isOnline &&
        (!rdd?.isMobileOnly ? (
          <SearchSection title={'Current Playlist'}>
            <CurrentlyPlayingCard device={device} />
          </SearchSection>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'center',
              margin: '13px 13px 68px 13px',
            }}
          >
            <h1 style={{ fontSize: 16, marginBottom: 20 }}>
              Playing off schedule
            </h1>
            <CurrentlyPlayingCardMobile device={device} />
          </div>
        ))}
      {!rdd?.isMobileOnly ? (
        <SearchSection title={'Device Management'}>
          {device && (
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: 20,
                marginBottom: 20,
              }}
            >
              {device && device.isOnline ? (
                <CardLink
                  to={`/${id}/dashboard/schedule`}
                  title="Schedule"
                  description="View and amend the schedule for this  device."
                  color={getColors[1]}
                  graphic={
                    require('../../../assets/images/shop-parkle.png').default
                  }
                />
              ) : (
                <CardLink
                  to={`/${id}/support`}
                  title="Get Online"
                  description="Get in contact and we’ll help you get this device online again."
                  color={getColors[2]}
                  graphic={
                    require('../../../assets/images/shop-parkle2.png').default
                  }
                />
              )}

              <CardLink
                to={`/${id}/dashboard/information`}
                title="Device Info"
                description="See users of this device and other additional information."
                color={getColors[3]}
                graphic={
                  require('../../../assets/images/shop-parkle2.png').default
                }
              />
            </div>
          )}
        </SearchSection>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 120,
          }}
        >
          <h1 style={{ fontSize: 16, marginBottom: 20, marginLeft: 13 }}>
            Device Management
          </h1>
          <div
            style={{ overflow: 'auto', whiteSpace: 'nowrap', display: 'flex' }}
          >
            {device && device.isOnline ? (
              <CardLink
                style={{
                  height: 300,
                  width: 320,
                  padding: '40px 26px',
                  display: 'inline-block',
                  whiteSpace: 'normal',
                  marginRight: 20,
                  marginLeft: 13,
                }}
                to={`/${id}/dashboard/schedule`}
                title="Schedule"
                description="View and amend the schedule for this  device."
                color={getColors[1]}
                graphic={
                  require('../../../assets/images/shop-parkle.png').default
                }
              />
            ) : (
              <CardLink
                style={{
                  height: 300,
                  width: 320,
                  padding: '40px 26px',
                  display: 'inline-block',
                  whiteSpace: 'normal',
                  marginRight: 20,
                  marginLeft: 13,
                }}
                to={`/${id}/support`}
                title="Get Online"
                description="Get in contact and we’ll help you get this device online again."
                color={getColors[2]}
                graphic={
                  require('../../../assets/images/shop-parkle2.png').default
                }
              />
            )}

            <CardLink
              style={{
                height: 300,
                width: 320,
                padding: '40px 26px',
                display: 'inline-block',
                whiteSpace: 'normal',
                marginRight: 13,
              }}
              to={`/${id}/dashboard/information`}
              title="Device Info"
              description="See users of this device and other additional information."
              color={getColors[3]}
              graphic={
                require('../../../assets/images/shop-parkle2.png').default
              }
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default DashboardPage;

/**
 * External dependencies
 */
import React, { useState, useEffect, useContext, Suspense } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from '../../layouts/dashboard-layout';

const InstallSvg = React.lazy(() =>
  import('../../assets/svg-icons/Auto Layout Vertical')
);
const InstallMobileSvg = React.lazy(() =>
  import('../../assets/svg-icons/MobileSonosInstall')
);
import Modal from '../../components/modal/modal';
import Button from '../../components/button/button';

import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import { useHistory } from 'react-router-dom';

import { Toggle } from 'react-powerplug';

const toggle = (
  <Toggle>
    {({ on, toggle }) => (
      <>
        <a
          onClick={toggle}
          style={{
            position: 'absolute',
            right: 265,
            bottom: 135,
            textDecoration: 'underline',
          }}
        >
          ‘Add a music service to Sonos‘
        </a>

        <Modal
          subtitle="Sonos.com"
          title="Add a music service"
          visible={on}
          onClose={toggle}
        >
          <p style={{ lineHeight: '20px' }}>
            This article will show you how to browse available music services
            and add them to Sonos. You can also add multiple accounts for the
            same service by following the steps below for each account. Then see
            our article on using multiple accounts to learn how to switch
            between them. If you’re unable to find or add a music service, see
            our article about issues with adding music services for help.
          </p>
          <b>iOS or Android:</b>

          <ol
            style={{
              listStylePosition: 'inside',
              marginTop: 20,
              marginBottom: 20,
              lineHeight: '20px',
            }}
          >
            <li>Open the Sonos app.</li>
            <li>From the Settings tab, tap Services & Voice.</li>
            <li>Under Music & Content, tap Add a Service.</li>
            <li>Tap the service you would like to add.</li>
            <li>Tap Add to Sonos.</li>
            <li>
              Follow the on-screen instructions to add the music service
              account.
            </li>
          </ol>
          <b>Mac or PC:</b>
          <ol
            style={{
              listStylePosition: 'inside',
              marginTop: 20,
              marginBottom: 30,
              lineHeight: '20px',
            }}
          >
            <li>Open the Sonos app.</li>
            <li>
              Under the Select a Music Source section, click on Add Music
              Services.
            </li>
            <li>Click on the service you would like to add.</li>
            <li>
              Follow the on-screen instructions to add the music service
              account.
            </li>
          </ol>

          <p style={{ lineHeight: '20px', marginBottom: 100 }}>
            If you are unable to find the service you are looking for, see our
            article on missing music services.
          </p>
        </Modal>
      </>
    )}
  </Toggle>
);

const SonosInstallScreen = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  return (
    <DashboardLayout
      title="Sonos"
      subtitle="Curator"
      modifier="profile"
      showSwitcher={
        user?.serviceType?.includes('CURATOR') &&
        user?.serviceType?.includes('SONOS')
      }
    >
      <div className="sonos-install-mobile">
        <p
          style={{
            position: 'absolute',
            padding: '0px 40px',
            bottom: 145,
            lineHeight: '20px',
          }}
        >
          Then follow the steps in this support article from Sonos{' '}
          <Toggle>
            {({ on, toggle }) => (
              <>
                <a onClick={toggle} style={{ textDecorationLine: 'underline' }}>
                  'Add a music service to Sonos'
                </a>

                <Modal
                  subtitle="Sonos.com"
                  title="Add a music service"
                  visible={on}
                  onClose={toggle}
                  styles={{ marginTop: 170 }}
                >
                  <p style={{ lineHeight: '20px' }}>
                    This article will show you how to browse available music
                    services and add them to Sonos. You can also add multiple
                    accounts for the same service by following the steps below
                    for each account. Then see our article on using multiple
                    accounts to learn how to switch between them. If you’re
                    unable to find or add a music service, see our article about
                    issues with adding music services for help.
                  </p>
                  <b>iOS or Android:</b>

                  <ol
                    style={{
                      listStylePosition: 'inside',
                      marginTop: 20,
                      marginBottom: 20,
                      lineHeight: '20px',
                    }}
                  >
                    <li>Open the Sonos app.</li>
                    <li>From the Settings tab, tap Services & Voice.</li>
                    <li>Under Music & Content, tap Add a Service.</li>
                    <li>Tap the service you would like to add.</li>
                    <li>Tap Add to Sonos.</li>
                    <li>
                      Follow the on-screen instructions to add the music service
                      account.
                    </li>
                  </ol>
                  <b>Mac or PC:</b>
                  <ol
                    style={{
                      listStylePosition: 'inside',
                      marginTop: 20,
                      marginBottom: 30,
                      lineHeight: '20px',
                    }}
                  >
                    <li>Open the Sonos app.</li>
                    <li>
                      Under the Select a Music Source section, click on Add
                      Music Services.
                    </li>
                    <li>Click on the service you would like to add.</li>
                    <li>
                      Follow the on-screen instructions to add the music service
                      account.
                    </li>
                  </ol>

                  <p style={{ lineHeight: '20px', marginBottom: 100 }}>
                    If you are unable to find the service you are looking for,
                    see our article on missing music services.
                  </p>
                </Modal>
              </>
            )}
          </Toggle>
        </p>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            flexWrap: 'wrap',
            width: '92%',
            right: 0,
            bottom: 200,
          }}
        >
          <Button
            onClick={() =>
              window.open('https://www.sonos.com/icr2_appstore', '_blank')
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',
              fontWeight: 'bold',
              padding: '5vw',
              margin: 5,
              right: 0,
            }}
          >
            iOS
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://www.sonos.com/redir/controller_software_pc2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '5vw',
              margin: 5,
              right: 0,
            }}
          >
            Windows
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://market.android.com/details?id=com.sonos.acr2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '5vw',
              margin: 5,
              right: 0,
            }}
          >
            Android
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://www.sonos.com/redir/controller_software_mac2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '5vw',
              margin: 5,
              right: 0,
            }}
          >
            MacOS
          </Button>
          <Button
            onClick={() =>
              window.open(
                'http://www.sonos.com/redir/controller_software_android2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '5vw',
              margin: 5,
              right: 0,
            }}
          >
            FireOS
          </Button>
        </div>
        <Suspense fallback={<div></div>}>
          <InstallMobileSvg
            style={{ padding: '0px 10px', marginBottom: 100 }}
          />
        </Suspense>
      </div>

      <div className="sonos-install-desktop">
        <img
          src={require('../../assets/images/sonos-instal.png').default}
          style={{ position: 'absolute', height: '100%' }}
        />
        {toggle}
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            flexWrap: 'wrap',
            width: 375,
            right: 75,
            top: 160,
          }}
        >
          <Button
            onClick={() =>
              window.open('https://www.sonos.com/icr2_appstore', '_blank')
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '20px 30px',
              margin: 5,
              right: 0,
            }}
          >
            iOS
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://www.sonos.com/redir/controller_software_pc2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '20px 30px',
              margin: 5,
              right: 0,
            }}
          >
            Windows
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://market.android.com/details?id=com.sonos.acr2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '20px 30px',
              margin: 5,
              right: 0,
            }}
          >
            Android
          </Button>
          <Button
            onClick={() =>
              window.open(
                'https://www.sonos.com/redir/controller_software_mac2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '20px 30px',
              margin: 5,
              right: 0,
            }}
          >
            MacOS
          </Button>
          <Button
            onClick={() =>
              window.open(
                'http://www.sonos.com/redir/controller_software_android2',
                '_blank'
              )
            }
            modifier="sonos"
            style={{
              border: '1px solid #fff',

              fontWeight: 'bold',
              padding: '20px 30px',
              margin: 5,
              right: 0,
            }}
          >
            FireOS
          </Button>
        </div>
        <Suspense fallback={<div></div>}>
          <InstallSvg />
        </Suspense>
      </div>
    </DashboardLayout>
  );
};

export default SonosInstallScreen;

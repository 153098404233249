/**
 * External dependencies
 */
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';
import emailPattern from 'helpers/email-pattern';
import useForgotPassword from 'domain/auth/hooks/use-forgot-password';
import { toast } from 'react-toastify';

const FormReset = ({ hidePasswordReset, goBackCallback }) => {
  const { forgotPassword } = useForgotPassword();

  const handleSubmit = (data) => {
    hidePasswordReset();

    forgotPassword({
      variables: {
        forgotPasswordEmail: data.email,
      },
    })
      .then(({ data }) => {
        data?.forgotPassword
          ? toast.success('Check your inbox to reset the password', {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: true,
              bodyStyle: { color: '#fff' },
              style: { backgroundColor: '#670BCE' },
            })
          : toast.dark(
              'Something went wrong, please contact support@activaire.com',
              {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: true,
                bodyStyle: { color: '#fff' },
                style: { backgroundColor: '#670BCE' },
              }
            );
      })
      .catch((err) => {
        console.log('Reset password error:', err);
        toast.dark(
          'Something went wrong, please contact support@activaire.com',
          {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: true,
            bodyStyle: { color: '#fff' },
            style: { backgroundColor: '#670BCE' },
          }
        );
      });
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  return (
    <div className="form form--reset">
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="form__body">
            <h4>
              Enter the email address associated with your account, and we’ll
              email you a password reset link.
            </h4>

            <div className="form__row">
              <FieldValidated
                type="email"
                name="email"
                placeholder="name@domain.com"
                validations={{
                  required: 'Field is required',
                  pattern: {
                    value: emailPattern,
                    message: 'E-Mail address is not valid',
                  },
                }}
              />
            </div>
          </div>

          <div
            className="form__actions2"
            style={{ textAlign: 'left', marginTop: 32 }}
          >
            <Button type="submit" modifier="primary">
              Reset password
            </Button>

            <Button
              style={{ paddingLeft: 20 }}
              modifier="as-link"
              onClick={goBackCallback}
            >
              Cancel
            </Button>

            {methods.formState.isSubmitted && !methods.formState.isValid && (
              <Button
                type="button"
                modifier="as-link"
                onClick={hidePasswordReset}
              >
                Back
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormReset;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SET_SONOS_DEVICE_VOLUME = gql`
  mutation setSonosDeviceVolume($deviceId: String!, $volume: Int!) {
    setSonosDeviceVolume(deviceId: $deviceId, volume: $volume)
  }
`;

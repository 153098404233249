/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { OPEN_STRIPE_CUSTOMER_PORTAL } from 'domain/sonos/mutations/open-stripe-customer-portal';

export default function useCheckStripeCustomer() {
  const [
    openStripeCustomerPortal,
    { loading, data: stripePortal },
  ] = useMutation(OPEN_STRIPE_CUSTOMER_PORTAL);

  return { openStripeCustomerPortal, loading, stripePortal };
}

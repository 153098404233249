/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */

import Modal from 'components/modal/modal';

import termsDefault from '../../../../utils/terms/terms-default';
import termsQsys from '../../../../utils/terms/terms-qsys';

const TermsModal = ({ visible, closeCallback, serviceType }) => {
  let terms;
  switch (serviceType) {
    case 'sonos':
      terms = termsDefault;
      break;
    case 'qsys':
      terms = termsQsys;
      break;
    default:
      terms = termsDefault;
  }
  return (
    <Modal
      title="Terms and Conditions"
      visible={visible}
      onClose={() => closeCallback()}
    >
      <p style={{ lineHeight: '20px' }}>{terms}</p>
    </Modal>
  );
};

export default TermsModal;

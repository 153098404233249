/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import portalsContainer from 'helpers/portals-container';
import { ReactComponent as IcoPlus } from 'assets/svg-icons/plus.svg';
import { ReactComponent as IcoMinus } from 'assets/svg-icons/minus.svg';
import { ReactComponent as IcoLoading } from '../../assets/svg-icons/off-piste.svg';

const ModalPaymentStatus = ({ color, disable }) => {
  const [minimised, setMinimised] = useState(false);
  const divStyle = { backgroundColor: color, bottom: minimised && 0 };
  if (disable) {
    Object.assign(divStyle, { display: 'none' });
  }
  return createPortal(
    <div className="modal-status" style={divStyle}>
      <button
        onClick={() => setMinimised(!minimised)}
        className="modal-status__close"
      >
        {minimised ? <IcoPlus /> : <IcoMinus />}
      </button>
      <h6
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: minimised ? 0 : 5,
        }}
      >
        <IcoLoading style={{ marginRight: 10 }} />
        Credit Card expiring
      </h6>
      {!minimised && (
        <h4 style={{ marginLeft: 28 }}>
          Please update your payment method, enter your{' '}
          <Link to="/profile" style={{ textDecoration: 'underline' }}>
            Payment Details
          </Link>{' '}
          here
        </h4>
      )}
    </div>,
    portalsContainer
  );
};

export default ModalPaymentStatus;

/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';

/**
 * Internal dependencies
 */
import { ReactComponent as IcoNext } from 'assets/svg-icons/ico-next-track.svg';
import useUpdateDeviceVolume from 'domain/dashboard/hooks/use-update-device-volume';
import useUpdateDeviceNextSong from 'domain/dashboard/hooks/use-update-device-next-song';

const ProgressRange = ({ volume, deviceId }) => {
  const [deviceVolume, setDeviceVolume] = useState({ values: [volume] });
  const { updateDeviceVolume, loading } = useUpdateDeviceVolume();
  const { updateDeviceNextSong } = useUpdateDeviceNextSong();
  const [sliding, setSliding] = useState(false);

  useEffect(() => {
    if (!loading && !sliding) {
      setDeviceVolume({ values: [volume] });
    }
  }, [volume]);

  const handleSliderChange = (values) => {
    setDeviceVolume({ values });
    updateDeviceVolume({
      variables: {
        deviceId: deviceId,
        volume: values[0],
      },
    });
  };

  const handleNextClick = (e) => {
    if (deviceId) {
      updateDeviceNextSong({
        variables: {
          deviceId: deviceId,
        },
      });
    }
  };

  return (
    <div className="progress-range">
      <Range
        step={1}
        min={0}
        max={15}
        className="player-range"
        values={deviceVolume.values}
        onChange={(values) => {
          handleSliderChange(values);
          setSliding(true);
        }}
        onFinalChange={() => setSliding(false)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="rangediv"
            style={{
              ...props.style,
              height: '10px',

              backgroundColor: 'rgba(4, 6, 32, .5)',
              borderRadius: '44px',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '30px',
              width: '30px',
              backgroundColor: '#040620',
              borderRadius: '50%',
            }}
          />
        )}
      />

      <button onClick={handleNextClick}>
        <IcoNext />
      </button>
    </div>
  );
};

export default ProgressRange;

/**
 * External dependencies
 */
import { useSubscription } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SUBSCRIBE_DEVICES } from 'domain/devices/subscriptions/subscribe-devices';

export default function useDeviceSubscription(options) {
  const { data, loading } = useSubscription(SUBSCRIBE_DEVICES, {
    shouldResubscribe: false,
    skip: options?.skip || false,
    variables: {
      // made shoudl resub to false 14.07
      token: options?.userToken || '',
      updatedId: options?.deviceId || null,
      updatedFields: [
        'appStatus.status',
        'isPlaying',
        'isOnline',
        'lastOnline',
        'appVersion',
        'name',
        'lastSyncTime',
        'scheduleIds.*',
        'deviceStatus',
        'currentSongId',
        'remoteVolume',
        'manuallyPaused',
        'remotePlaylistMode',
        'skipDisabled',
      ],
    },
  });

  return {
    deviceData: data,
    loadingDeviceSub: loading,
  };
}

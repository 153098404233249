/**
 * External dependencies
 */
import React from 'react';
import stc from 'string-to-color';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import useAllPlaylists from '../../../music/hooks/use-all-playlists';
import LoadingProvider from 'components/loading-provider/loading-provider';
import * as rdd from 'react-device-detect';
import MusicListItem from '../../../music/components/music-list-item';
import useAuth from '../../../auth/hooks/use-auth';
import useTriggerQsysAction from '../../hooks/use-trigger-qsys-action';

const SingleGenrePage = ({
  match: {
    params: { id, genre },
  },
}) => {
  const { allPlaylists } = useAllPlaylists();
  const { currentDevice } = useAuth();
  const { triggerQsysAction } = useTriggerQsysAction();

  const allMixes = allPlaylists?.filter((item) => {
    const genreUpperCase = item.genre?.map((item) => item.toUpperCase());
    return genreUpperCase.includes(genre);
  });

  const handlePlayNowClick = (item, type) => {
    triggerQsysAction({
      variables: {
        deviceId: currentDevice,
        zoneId: Number(id),
        actions: {
          mixId: item.id,
          mixType: type,
        },
      },
    });
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={'Music'}
      title={genre}
      modifier="current-playlist"
    >
      <LoadingProvider loading={!allMixes}>
        {rdd?.isMobileOnly ? (
          <div style={{ marginBottom: 135 }}>
            <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
              {allMixes.length} Playlists Available
            </h4>

            {allMixes?.map((item) => {
              return (
                <MusicListItem
                  key={item.id}
                  to={`/qsys/${id}/search/playlists/${item.id}`}
                  cover={item?.cover}
                  name={item?.name}
                  description={item?.description}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              maxWidth: 980,
              width: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: 20,
              marginBottom: 35,
            }}
          >
            {allMixes?.map((playlist) => (
              <CardLink
                key={playlist?.id}
                to={`/qsys/${id}/search/playlists/${playlist.id}`}
                cover={playlist?.cover}
                title={playlist?.name}
                color={stc(playlist?.name)}
                style={{ margin: 0 }}
                hasButton
                handleClicked={() => handlePlayNowClick(playlist, 'playlist')}
              />
            ))}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SingleGenrePage;

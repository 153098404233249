/**
 * External dependencies
 */
import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';

/**
 * Internal dependencies
 */
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import useLocalStorage from 'utils/use-local-storage';
import jwt_decode from 'jwt-decode';
import { googleLogout } from '@react-oauth/google';
import isSonosAccessTokenAvailable from '../../../../utils/check-sonos-cookie';

const AuthProvider = ({ children }) => {
  const [sessionToken, setSessionToken, removeSessionToken] = useLocalStorage(
    'sessionToken',
    null
  );
  const [accessTokenValid, setAccessTokenValid] = useState(
    isSonosAccessTokenAvailable()
  );
  const [sessionProvider, setSessionProvider, removeSessionProvider] =
    useLocalStorage('sessionProvider', null);
  const [currentDevice, setCurrentDevice, removeCurrentDevice] =
    useLocalStorage('currentDevice', null);

  const [currentClient, setCurrentClient, removeCurrentClient] =
    useLocalStorage('currentClient', null);

  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  // Decoded token information from sessionToken, will be needed to verify if token information is corresponding with user information
  const [decodedToken, setDecodedToken] = useState(
    sessionToken ? jwt_decode(sessionToken) : null
  );
  const [sonosFlow, setSonosFlow] = useState(false);

  const isLoggedIn = useMemo(() => !!sessionToken, [sessionToken]);

  useEffect(() => {
    setAccessTokenValid(isSonosAccessTokenAvailable());
  }, []);

  const loginSuccess = ({
    token,
    user,
    provider,
    sonosAuthObj,
    redirectUrl,
    linkCode,
  }) => {
    setSessionToken(token);
    setDecodedToken(jwt_decode(token));
    setUser(user);
    setSessionProvider(provider);
    if (
      sonosAuthObj?.authToken &&
      sonosAuthObj?.message === 'OK' &&
      redirectUrl &&
      linkCode
    ) {
      window.location.href = `${redirectUrl}?linkCode=${linkCode}&authToken=${sonosAuthObj.authToken}&sonosTrial=activate`;
    }
    setLoading(false);
  };

  const logoutSuccess = () => {
    console.log('logoutSuccess');
    sessionProvider === 'google' && googleLogout();
    // delete local storage
    localStorage.clear();

    // remove sonosAccessToken cookie
    document.cookie =
      'sonosAccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    setUser(null);
    window.location.href = '/';
  };

  if (sessionToken) {
    const decoded = jwt_decode(sessionToken);
    if (decoded && Date.now() >= decoded?.exp * 1000) {
      if (sessionProvider === 'google') {
        googleLogout();
        logoutSuccess();
      } else {
        logoutSuccess();
      }
    }
  }

  const setLoginLoading = (payload) => {
    setLoading(payload);
  };

  const newUserRegisteredHubspotEmail = async ({
    firstname,
    email,
    company,
    servicetype,
    loginmethod,
    householdId,
  }) => {
    try {
      const fields = [
        {
          name: 'firstname',
          value: firstname,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'company',
          value: company,
        },
        {
          name: 'loginmethod',
          value: loginmethod,
        },
      ];
      if (householdId) {
        fields.push({
          name: 'sonos_device',
          value: householdId,
        });
      }
      const data = {
        fields,
      };

      if (servicetype.length > 0) {
        servicetype.forEach((item) => {
          data.fields.push({
            name: 'servicetype',
            value: item,
          });
        });
      }

      const config = {
        method: 'post',
        url: 'https://api.hsforms.com/submissions/v3/integration/submit/2621188/d79e8dd1-f416-4923-9af0-efed750b7452',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      };

      const response = await axios(config);
      return response;
    } catch (e) {
      const errorObj = { ...e };
      return (
        (errorObj?.response?.data?.errors &&
          errorObj?.response?.data?.errors[0]?.errorType) ||
        'OTHER_ERROR'
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: sessionToken,
        user,
        setUser,
        sessionProvider,
        currentDevice,
        setCurrentDevice,
        currentClient,
        setCurrentClient,
        isLoggedIn,
        loginSuccess,
        logoutSuccess,
        setLoginLoading,
        setSessionToken,
        setSessionProvider,
        isLoading,
        newUserRegisteredHubspotEmail,
        sonosFlow,
        setSonosFlow,
        decodedToken,
        setDecodedToken,
        accessTokenValid,
        setAccessTokenValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const GET_SONG_REACTION = gql`
  query getSongReactionQuery($songId: String!, $deviceId: String!) {
    viewer {
      getSongReaction(songId: $songId, deviceId: $deviceId) {
        id
        songReaction
        feedback
      }
    }
  }
`;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { REQUEST_ACCESS } from '../mutations/request-access';

export default function useRequestAccess() {
  const [requestAccess, { loading }] = useMutation(REQUEST_ACCESS);

  return { requestAccess, loading };
}

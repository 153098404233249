import React, { useState, useEffect, useContext } from 'react';

import Modal from '../../components/modal/modal';
import BoardingStart from './components/boarding-start';
import BoardingEnd from './components/boarding-end';
import Business from './components/business';
import Category from './components/category';
import Artists from './components/artists';
import Genre from './components/genre';
import Social from './components/social';
import AvoidGenre from './components/avoid-genre';
import Explicit from './components/explicit';
import Vibe from './components/vibe';
import CategorySpecific from './components/category-specific';

import { BoardingContext } from './context/boardingContext';
import useAllCategories from './hooks/use-all-categories';
import useAllGenresForSonos from '../music/hooks/use-all-genres-for-sonos';
import useAllVibes from './hooks/use-all-vibes';

const stack = [
  'boarding-start',
  'business',
  'category',
  'category-specific',
  'social',
  'genre',
  'artists',
  'avoid-genre',
  'vibe',
  'explicit',
  'boarding-end',
];

const BoardingModal = ({
  boardingModal,
  handleCloseModal,
  setIsWizardCompleted,
}) => {
  const [selectedStack, setSelectedStack] = useState(stack[0]);

  const { allCategories } = useAllCategories();
  const { allVibes } = useAllVibes();
  const { allGenresForSonos } = useAllGenresForSonos();

  const { config } = useContext(BoardingContext);

  useEffect(() => {
    if (!config.businessName) {
      setSelectedStack('boarding-start');
    } else if (!config.selectedCategory) {
      setSelectedStack('category');
    } else if (!config.selectedSpecificCategory) {
      setSelectedStack('category-specific');
    } else if (
      !config.socialValues.Twitter &&
      !config.socialValues.LinkedIn &&
      !config.socialValues.Facebook &&
      !config.socialValues['Spotify / Apple Music / Tidal']
    ) {
      setSelectedStack('social');
    } else if (!config.genreValues.length) {
      setSelectedStack('genre');
    } else if (
      !config.artistValues['0'] &&
      !config.artistValues['1'] &&
      !config.artistValues['2']
    ) {
      setSelectedStack('artists');
    } else if (!config.avoidGenreValues.length) {
      setSelectedStack('avoid-genre');
    } else if (!config.vibeValues.length) {
      setSelectedStack('vibe');
    } else if (!config.isExplicit) {
      setSelectedStack('explicit');
    } else {
      setSelectedStack('boarding-start');
    }
  }, [boardingModal]);

  const filteredCategories = [...allCategories].filter(
    (item) => !['custom', 'scene', 'vibe'].includes(item?.name?.toLowerCase())
  );

  const renderComponents = (stackName) => {
    const props = { setSelectedStack, stack };
    switch (stackName) {
      case 'boarding-start':
        return <BoardingStart {...props} handleCloseModal={handleCloseModal} />;
      case 'business':
        return <Business {...props} />;
      case 'category':
        return <Category {...props} categories={filteredCategories} />;
      case 'category-specific':
        return <CategorySpecific {...props} categories={filteredCategories} />;
      case 'social':
        return <Social {...props} />;
      case 'genre':
        return <Genre {...props} genres={allGenresForSonos} />;
      case 'artists':
        return <Artists {...props} />;
      case 'avoid-genre':
        return <AvoidGenre {...props} genres={allGenresForSonos} />;
      case 'vibe':
        return <Vibe {...props} vibes={allVibes} />;
      case 'explicit':
        return <Explicit {...props} />;
      case 'boarding-end':
        return (
          <BoardingEnd {...props} setIsWizardCompleted={setIsWizardCompleted} />
        );
    }
  };

  return (
    <Modal visible={boardingModal} onClose={handleCloseModal} onPlayCloseButton>
      {renderComponents(selectedStack)}
    </Modal>
  );
};

export default BoardingModal;

import React from 'react';
import { Link } from 'react-router-dom';

const MusicListItem = ({ cover, name, description, to }) => {
  return (
    <Link to={to}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px 13px 13px 13px',
        }}
      >
        <img
          src={cover}
          style={{
            borderRadius: 3,
            width: 94,
            height: 94,
            marginRight: 13,
          }}
        />
        <div className="playlist__song-content">
          <h4>{name}</h4>
          <h5
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </h5>
        </div>
      </div>
    </Link>
  );
};

export default MusicListItem;

/**
 * External dependencies
 */
import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

/**
 * Internal dependencies
 */
import { ALL_AUDIO_DEVICES_IDS } from 'domain/devices/queries/all-audio-devices-ids';

export default function useAllAudioDevices(options) {
  const { currentClient } = useContext(AuthContext);

  const { data, loading, error } = useQuery(ALL_AUDIO_DEVICES_IDS, {
    variables: { clientId: currentClient },
  });

  const count = useMemo(() => {
    return data?.viewer?.allAudioDevicesFavourites?.count;
  }, [data]);

  const mutateData = (edges) =>
    edges?.map((device) => {
      return {
        id: device.node.id,
        name: device.node.name,
      };
    });

  const allDevices = useMemo(() => {
    return mutateData(data?.viewer?.allAudioDevicesFavourites?.edges);
  }, [data, currentClient]);

  return {
    allDevices,
    loading,
    count,
    error,
  };
}

/**
 * External dependencies
 */
import React, { useEffect, useState, useRef, memo, useContext } from 'react';
import { LoadScriptNext } from '@react-google-maps/api';

import * as rdd from 'react-device-detect';
import MapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import 'mapbox-gl/dist/mapbox-gl.css';
import PopupComponent from './popup-component';

/**
 * Internal dependencies
 */
import Button from '../button/button';
import useSuperCluster from 'use-supercluster';
import LoadingProvider from 'components/loading-provider/loading-provider';
import { useHistory } from 'react-router-dom';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import getRandomColor from '../../helpers/get-random-color';

const Map = ({ loading, handleLoadMoreOnScroll, data }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const userLoading = !user;
  const mapRef = useRef();

  const [viewport, setViewport] = useState({
    latitude: 39.9734,
    longitude: -93.0653,
    zoom: 3,
    maxZoom: 20,
    minZoom: 2,
  });

  useEffect(() => {
    const latitude = user?.status?.lastLogin?.latitude;
    const longitude = user?.status?.lastLogin?.longitude;
    if (
      user &&
      latitude &&
      longitude &&
      latitude !== 'null' &&
      longitude !== 'null'
    ) {
      const location = {
        latitude: parseFloat(user.status.lastLogin.latitude),
        longitude: parseFloat(user.status.lastLogin.longitude),
      };
      setViewport((viewport) => {
        return { ...viewport, ...location, zoom: 10 };
      });
    }
  }, [userLoading]);

  const [pins, setPins] = useState([]);
  const [popupInfo, setPopupInfo] = useState(null);

  useEffect(() => {
    const pns = data
      ? data.map((device, index) => {
          return {
            type: 'Feature',
            properties: {
              cluster: false,
              deviceId: device?.id,
              deviceName: device?.name,
              currentSongId: device.isOnline ? device.currentSong?.id : null,
              deviceSongTitle: device?.currentSong?.title || '',
              deviceSongAuthor: device?.currentSong?.author || '',
              color:
                device.isOnline && device.isPlaying
                  ? getRandomColor(index % 14)
                  : 'grey',
            },

            geometry: {
              type: 'Circle',
              coordinates: [device.location.lng, device.location.lat],
            },
          };
        })
      : [];

    setPins(pns);
  }, [data, viewport]);

  const bounds = mapRef?.current?.getMap()
    ? mapRef.current.getMap().getBounds()?.toArray()?.flat() || []
    : null;

  const { clusters, supercluster } = useSuperCluster({
    points: pins,
    zoom: viewport.zoom,
    bounds,
    options: { radius: 10, maxZoom: 20, minZoom: 2 },
  });

  const SIZE = 20;

  return (
    <div className="map">
      <div className="map__body">
        <LoadingProvider loading={loading || userLoading}>
          <LoadScriptNext
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          >
            <MapGL
              {...viewport}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              attributionControl={false}
              mapStyle="mapbox://styles/mapbox/dark-v8"
              width="100%"
              height="100%"
              onViewportChange={(viewport) => setViewport(viewport)}
              ref={mapRef}
            >
              {clusters.map((cluster, index) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } =
                  cluster.properties;
                if (isCluster) {
                  return (
                    <Marker
                      key={index}
                      latitude={latitude}
                      longitude={longitude}
                    >
                      <div
                        className="cluster-marker"
                        onClick={() => {
                          const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            20
                          );

                          setViewport({
                            ...viewport,
                            latitude,
                            longitude,
                            zoom: expansionZoom,
                            transitionInterpolator: new FlyToInterpolator({
                              speed: 2,
                            }),
                            transitionDuration: 'auto',
                          });
                        }}
                      >
                        {pointCount}
                      </div>
                    </Marker>
                  );
                }
                return (
                  <Marker
                    onClick={() =>
                      history.push(`/${cluster.properties.deviceId}`)
                    }
                    key={cluster.properties.deviceId}
                    latitude={latitude}
                    longitude={longitude}
                  >
                    <svg
                      onMouseOver={() => setPopupInfo(cluster)}
                      onMouseLeave={() => setPopupInfo(null)}
                      height="20"
                      width="20"
                      style={{
                        cursor: 'pointer',
                        fill: 'blue',
                        stroke: 'none',
                        transform: `translate(${-SIZE / 2}px,${0}px)`,
                      }}
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="10"
                        fill={cluster.properties.color}
                      />
                    </svg>
                  </Marker>
                );
              })}
              {popupInfo && (
                <PopupComponent
                  popupInfo={popupInfo}
                  setPopupInfo={setPopupInfo}
                />
              )}
            </MapGL>
            <Button
              onClick={(e) => handleLoadMoreOnScroll(e, true)}
              modifier="as-link"
              style={{
                position: 'absolute',
                right: rdd?.isMobileOnly ? 13 : 10,
                bottom: -20,
              }}
            >
              View all
            </Button>
          </LoadScriptNext>
        </LoadingProvider>
      </div>
    </div>
  );
};

export default memo(Map);

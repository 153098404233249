/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_VIBES = gql`
  query allVibesQuery {
    viewer {
      allVibes {
        id
        name
      }
    }
  }
`;

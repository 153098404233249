export default function parseDate (string) {
	const arr = string.split('/');
	
	return new Date(arr[2], arr[0]-1, arr[1]);
}

export const getOrdinalNum = (number) => {
  let selector;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const TOGGLE_MANUALLY_PAUSED = gql`
  mutation updateManuallyPaused($deviceId: String!, $manuallyPaused: Boolean!) {
    updateManuallyPaused(deviceId: $deviceId, manuallyPaused: $manuallyPaused) {
      id
    }
  }
`;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_QSYS_ZONE_VOLUME = gql`
  mutation updateQsysZoneVolume(
    $deviceId: ID!
    $zoneId: Int!
    $volume: Float!
  ) {
    updateQsysZoneVolume(
      deviceId: $deviceId
      zoneId: $zoneId
      volume: $volume
    ) {
      id
      noOfZones
      zones {
        zoneId
        zoneName
        songCount
        songId
        currentAlbum
        currentTitle
        currentArtist
        currentArtwork
        reason
        positionMillis
        durationMillis
        updateTrigger
        songReaction
        mixType
        mixId
        mixName
        volume
      }
    }
  }
`;

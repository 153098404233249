/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SONG_DATA_QUERY = gql`
  query getSongDataQuery($songId: String!) {
    viewer {
      getSongData(songId: $songId) {
        id
        title
        artist
      }
    }
  }
`;

import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import _ from 'lodash';
import getRandomColor from '../../../helpers/get-random-color';

const Card = ({ title, index, to }) => {
  const [randomColor] = useState(getRandomColor(index % 15));
  return (
    <Link to={to}>
      <div
        style={{
          backgroundColor: randomColor,
          height: 95,
          borderRadius: 3,
          padding: '10px 13px 0px 13px',
        }}
      >
        <h4 style={{ fontSize: '16px' }}>{_.capitalize(title)}</h4>
      </div>
    </Link>
  );
};

export default Card;

import React from 'react';
import Button from '../../../components/button/button';

const BoardingStart = ({ setSelectedStack, stack, handleCloseModal }) => {
  return (
    <div className="modal-window__container">
      <div className="modal-window__imgcontainer">
        <img
          className="modal-window__graphics2"
          src={require('../../../assets/images/boarding-start.png').default}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <p className="modal-window__title">Let’s get to know you</p>
        <p className="modal-window__subtitle">
          We want to know what you like so we can curate playlists just for you.
        </p>
        <Button
          modifier="as-link"
          onClick={handleCloseModal}
          style={{ color: '#fff', fontWeight: 'bold', marginRight: 20 }}
        >
          Cancel
        </Button>
        <Button
          modifier="white2"
          onClick={() =>
            setSelectedStack(
              (selectedStack) => stack[stack.indexOf(selectedStack) + 1]
            )
          }
          style={{
            padding: '12px 0px',
            width: 110,
            marginBottom: 30,
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default BoardingStart;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_SONG_REACTIONS = gql`
  mutation UpdateSongReactions(
    $songId: String!
    $deviceId: String!
    $songReaction: Int
    $feedback: String
    $serviceType: String
    $zoneId: Int
  ) {
    updateSongReactions(
      songId: $songId
      deviceId: $deviceId
      songReaction: $songReaction
      feedback: $feedback
      serviceType: $serviceType
      zoneId: $zoneId
    ) {
      id
      songReaction
      feedback
      userName
    }
  }
`;

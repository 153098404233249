/**
 * External dependencies
 */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const ScheduleBlock = ({ timeSpan, color, title, description, isCurrent }) => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  let currentTimeIndex = 0;

  if (isCurrent) {
    if (moment() >= moment('11:00 PM', 'LT')) {
      currentTimeIndex = timeSpan.length - 2;
    } else {
      for (let i = 0; i < timeSpan.length - 1; i++) {
        if (
          moment(timeSpan[i], 'LT') <= moment() &&
          moment(timeSpan[i + 1], 'LT') > moment()
        ) {
          currentTimeIndex = i;
        }
      }
    }
  }
  useEffect(() => {
    ref.current && setParentWidth(ref.current.offsetWidth - 70);
    ref2?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  });
  return (
    <div
      className="schedule__block"
      ref={ref}
      style={{ backgroundColor: color }}
    >
      <div className="schedule__hours">
        {timeSpan &&
          timeSpan.length &&
          timeSpan.map((hour, index) => {
            if (isCurrent && index === currentTimeIndex) {
              return (
                <span key={new Date() + index}>
                  {hour}
                  <hr
                    className="schedule__current"
                    style={{
                      width: 18,
                      marginLeft: -20,
                    }}
                  />
                  <hr className="schedule__current-icon" ref={ref2} />
                  <hr
                    className="schedule__current"
                    style={{
                      width: parentWidth,
                      marginLeft: 59,
                    }}
                  />
                </span>
              );
            } else {
              return <span key={new Date() + index}>{hour}</span>;
            }
          })}
      </div>
      <div className="schedule__content">
        {title && <h4>{title}</h4>}

        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

export default ScheduleBlock;

/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { ReactComponent as LogoColored } from 'assets/svg-icons/logo-colored.svg';

const PoweredBy = () => {
  return (
    <div className="powered-by">
      <h4>Powered by</h4>
      <a target="_blank" href="https://activaire.com">
        <LogoColored />
      </a>
    </div>
  );
};

export default PoweredBy;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { CHECK_STRIPE_CUSTOMER } from 'domain/sonos/mutations/check-stripe-customer';

export default function useCheckStripeCustomer() {
  const [checkStripeCustomer, { loading, data: stripeCheckData }] = useMutation(
    CHECK_STRIPE_CUSTOMER
  );

  return { checkStripeCustomer, loading, stripeCheckData };
}

import React, { useState } from 'react';
import DashboardLayout from '../../layouts/dashboard-layout';
import InfoCard from 'components/info-card/info-card';
import Button from 'components/button/button';
import * as rdd from 'react-device-detect';
import { toast } from 'react-toastify';
import useActivateQsysService from '../devices/hooks/use-activate-qsys-service';
import TermsModal from '../auth/blocks/terms/terms';

const QsysTrialScreen = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [termsModalVisibility, setTermsModalVisibility] = useState(false);

  const { activateQsysService } = useActivateQsysService();

  const handleSignUp = () => {
    setLoadingButton(true);
    activateQsysService().then(() => {
      toast.success('Q-SYS Service is active', {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      });
    });
  };

  const closeCallback = () => {
    setTermsModalVisibility(false);
  };

  const title =
    "GET THE BACKGROUND MUSIC SERVICE FOR Q-SYS YOU'VE BEEN DREAMING OF.";
  const description = 'Monthly subscriptions start at $35 per zone.';
  const helperText =
    'Discover unparalleled music selections from Activaire Curator without the need for additional hardware.';
  const buttonText = 'Start free trial*';
  const backgroundImage = '#F4C042';
  const cardImage = require('../../assets/images/qsys-trial.png').default;

  return (
    <DashboardLayout
      subtitle="Curator"
      title={'Q-SYS'}
      modifier="devices"
      showSwitcher
    >
      <InfoCard
        reverse
        color={backgroundImage}
        image={cardImage}
        imgStyle={{
          width: '100%',
          maxWidth: 450,
          zIndex: 1,
          display: rdd.isMobileOnly && 'none',
        }}
        styles={{ height: 'auto', marginRight: 0 }}
      >
        <h1 style={{ marginBottom: 4, fontSize: 39, lineHeight: '100%' }}>
          {title}
        </h1>

        <h2 style={{ color: '#090E4F', marginBottom: 4, fontSize: 24 }}>
          {description}
        </h2>
        <h4 style={{ color: '#090E4F', marginBottom: 30, fontSize: 16 }}>
          {helperText}
        </h4>

        {/*
          <p style={{ color: '#000000' }}>
            Activaire Curator for Q-SYS is only available in the US and Canada.
          </p>
        */}

        <Button modifier="primary" onClick={() => handleSignUp()}>
          {loadingButton ? 'Loading...' : buttonText}
        </Button>
        <p
          className="available-text"
          style={{ color: '#090E4F', opacity: 0.75, marginTop: 5 }}
        >
          *No Credit Card Necessary
        </p>
      </InfoCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 35,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <p style={{ fontWeight: 'bold' }}>Need help?</p>
          <p>
            Visit{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="https://support.activaire.com/q-sys"
            >
              https://support.activaire.com/q-sys
            </a>
          </p>
        </div>
      </div>
      <TermsModal
        visible={termsModalVisibility}
        closeCallback={closeCallback}
        serviceType={'qsys'}
      />
    </DashboardLayout>
  );
};

export default QsysTrialScreen;

/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Playlist from '../components/playlist';
import PlaylistMobile from '../components/playlist-mobile';
import useSinglePlaylist from '../../music/hooks/use-single-playlist';
import { Howl } from 'howler';
import { SongItem } from '../../music/pages/song-item';
import useAuth from '../../auth/hooks/use-auth';
import useTriggerQsysAction from '../hooks/use-trigger-qsys-action';

const CurrentPlaylistPage = ({
  match: {
    params: { playlistId, id },
  },
}) => {
  const { currentDevice } = useAuth();
  const [songSkip, songLimit] = [0, 30];
  const { playlist, loading: playlistLoading } = useSinglePlaylist(
    playlistId,
    songSkip,
    songLimit,
    currentDevice
  );
  const [allSongs, setAllSongs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(-1);
  const { triggerQsysAction } = useTriggerQsysAction();

  useEffect(() => {
    if (!playlistLoading) {
      setAllSongs(playlist?.allSongs || []);
    }
  }, [playlistLoading]);

  useEffect(() => {
    if (currentIndex > 0) {
      playPreview(currentIndex);
    }
  }, [currentIndex]);

  let sound = window.Howler._howls[0] || null;

  const stopSound = () => {
    sound?.stop();
    sound?.unload();
    window.Howler.unload();
    sound = null;
  };

  const playPreview = (index) => {
    const song = allSongs[index];
    if (sound !== null && song?.preview?.url !== sound._src) {
      stopSound();
    }

    if (sound === null || !sound.playing()) {
      startNewHowl(index);
    }

    if (sound !== null && sound.playing()) {
      setPlayingIndex(-1);
      stopSound();
    }
  };

  const startNewHowl = (index) => {
    stopSound();
    const song = allSongs[index];
    sound = new Howl({
      src: [song?.preview?.url || ''],
      onplay: () => {
        setPlayingIndex(index);
      },
      onend: () => {
        stopSound();
        if (index < allSongs.length - 1) {
          startNewHowl(index + 1);
        } else {
          setCurrentIndex(index + 1);
        }
      },
      html5: true,
    });
    sound?.play();
  };

  const handlePlayNowClick = () => {
    triggerQsysAction({
      variables: {
        deviceId: currentDevice,
        zoneId: Number(id),
        actions: {
          mixId: playlistId,
          mixType: 'playlist',
        },
      },
    });
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={null}
      title={!playlistLoading ? playlist?.name : null}
      isSinglePlaylist={rdd?.isMobileOnly && !!playlistId}
      playlistInfo={{
        deviceId: currentDevice || '',
        devicePlayType: 'PLAYLIST',
        devicePlayTypeId: playlistId,
      }}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="current-playlist"
    >
      {rdd?.isMobileOnly ? (
        <PlaylistMobile
          devicePlayType="PLAYLIST"
          playlistId={playlistId}
          title={!playlistLoading ? playlist?.description : null}
          description={
            !playlistLoading
              ? playlist?.featuredArtists?.join(' - ') || ''
              : null
          }
          color={stc(playlist?.name)}
          cover={
            !playlistLoading
              ? playlist.cover
              : require('assets/images/offline-artwork/offline3.png').default
          }
          showPreviewButton
          handlePreview={() => playPreview(0)}
          handleClicked={() => handlePlayNowClick()}
        >
          {!playlistLoading &&
            allSongs?.map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
        </PlaylistMobile>
      ) : (
        <Playlist
          devicePlayType="PLAYLIST"
          playlistId={playlistId}
          title={!playlistLoading ? playlist?.description : null}
          description={
            !playlistLoading
              ? playlist?.featuredArtists?.join(' - ') || ''
              : null
          }
          color={stc(playlist?.name)}
          cover={
            !playlistLoading
              ? playlist?.cover
              : require('assets/images/offline-artwork/offline3.png').default
          }
          showPreviewButton
          handlePreview={() => playPreview(0)}
          handleClicked={() => handlePlayNowClick()}
        >
          {!playlistLoading &&
            allSongs?.map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
        </Playlist>
      )}
    </DashboardLayout>
  );
};

export default CurrentPlaylistPage;

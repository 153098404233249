/**
 * Internal dependencies
 */

import { useQuery } from '@apollo/client';

import { useMemo } from 'react';
import { ALL_VIBES } from '../query/all-vibes';

export default function useAllVibes() {
  const { data, loading, error } = useQuery(ALL_VIBES);
  const allVibes = useMemo(
    () => [...(data?.viewer?.allVibes || [])].sort((a, b) => a?.name - b?.name),
    [data]
  );

  return {
    allVibes,
    loading,
    error,
  };
}

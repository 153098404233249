/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_CATEGORIES = gql`
  query allCategoriesQuery {
    viewer {
      allCategories {
        id
        name
        subCategories
        sortOrder
      }
    }
  }
`;

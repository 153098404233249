import React, { createContext, useState } from 'react';

import useAuth from '../../auth/hooks/use-auth';

export const BoardingContext = createContext();

const BoardingContextProvider = ({ children }) => {
  const { user } = useAuth();
  const { musicProfile, socialProfile } = user;

  const [businessName, setBusinessName] = useState(user.companyName || '');
  const [subCategories, setSubCategories] = useState(user.companyName || '');
  const [selectedCategory, setSelectedCategory] = useState(
    musicProfile?.category
  );
  const [selectedSpecificCategory, setSelectedSpecificCategory] = useState(
    subCategories.includes(musicProfile?.subCategory)
      ? musicProfile?.subCategory
      : ''
  );
  const [otherSpecificCategoryValue, setOtherSpecificCategoryValue] = useState(
    subCategories.includes(musicProfile?.subCategory)
      ? ''
      : musicProfile?.subCategory
  );
  const [socialValues, setSocialValues] = useState({
    Instagram: socialProfile?.twitter,
    LinkedIn: socialProfile?.linkedIn,
    Facebook: socialProfile?.facebook,
    ['Spotify / Apple Music / Tidal']: socialProfile?.spotify,
  });
  const [genreValues, setGenreValues] = useState(musicProfile?.genres || []);
  const [artistValues, setArtistValues] = useState({
    0: musicProfile?.artists[0],
    1: musicProfile?.artists[1],
    2: musicProfile?.artists[2],
  });
  const [avoidGenreValues, setAvoidGenreValues] = useState(
    musicProfile?.avoidGenres || []
  );
  const [vibeValues, setVibeValues] = useState(musicProfile?.vibe || []);
  const [isExplicit, setIsExplicit] = useState(musicProfile?.explicit || false);

  const handleSocialInputChange = (e) => {
    const { name, value } = e.target;

    setSocialValues({
      ...socialValues,
      [name]: value,
    });
  };

  const handleArtistInputChange = (e) => {
    const { name, value } = e.target;

    setArtistValues({
      ...artistValues,
      [name]: value,
    });
  };

  const handleGenres = (name) => {
    if (genreValues?.length < 5 && !genreValues?.includes(name)) {
      if (avoidGenreValues?.includes(name)) {
        const newAvoidGenreValues = [...avoidGenreValues];
        const findIndex = newAvoidGenreValues.findIndex(
          (item) => item.toLowerCase() === name.toLowerCase()
        );
        newAvoidGenreValues.splice(findIndex, 1);
        setAvoidGenreValues(newAvoidGenreValues);
      }
      setGenreValues([...genreValues, name]);
    } else {
      const filteredGenres = genreValues.filter((item) => item !== name);
      setGenreValues(filteredGenres);
    }
  };

  const handleAvoidGenres = (name) => {
    if (!genreValues.includes(name)) {
      if (avoidGenreValues?.length < 5 && !avoidGenreValues?.includes(name)) {
        setAvoidGenreValues([...avoidGenreValues, name]);
      } else {
        const filteredGenres = avoidGenreValues?.filter(
          (item) => item !== name
        );
        setAvoidGenreValues(filteredGenres);
      }
    }
  };

  const handleVibes = (name) => {
    if (!vibeValues?.includes(name)) {
      setVibeValues([...vibeValues, name]);
    } else {
      const filteredVibes = vibeValues?.filter((item) => item !== name);
      setVibeValues(filteredVibes);
    }
  };

  return (
    <BoardingContext.Provider
      value={{
        businessName,
        setBusinessName,
        selectedCategory,
        setSelectedCategory,
        selectedSpecificCategory,
        setSelectedSpecificCategory,
        otherSpecificCategoryValue,
        setOtherSpecificCategoryValue,
        handleSocialInputChange,
        handleArtistInputChange,
        artistValues,
        socialValues,
        genreValues,
        vibeValues,
        avoidGenreValues,
        handleGenres,
        handleAvoidGenres,
        handleVibes,
        isExplicit,
        setIsExplicit,
        setSubCategories,
        config: {
          businessName,
          selectedCategory,
          selectedSpecificCategory,
          otherSpecificCategoryValue,
          socialValues,
          genreValues,
          avoidGenreValues,
          vibeValues,
          artistValues,
          isExplicit,
        },
      }}
    >
      {children}
    </BoardingContext.Provider>
  );
};

export default BoardingContextProvider;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from 'components/button/button';
import useOpenStripeCustomerPortal from 'domain/sonos/hooks/use-open-stripe-customer-portal';

const SonosErrorPage = ({ randomArtwork }) => {
  const { openStripeCustomerPortal, stripePortal } =
    useOpenStripeCustomerPortal();
  const [loading, setLoading] = useState(false);

  const route = useLocation().pathname;

  const handleClick = () => {
    setLoading(true);
    return openStripeCustomerPortal({
      variables: {
        route,
      },
    });
  };

  useEffect(() => {
    if (
      stripePortal?.openStripeCustomerPortal?.startsWith(
        'https://billing.stripe.com/'
      )
    ) {
      window.location.href = stripePortal?.openStripeCustomerPortal;
    }
  }, [stripePortal]);

  return (
    <div className="player-container">
      <div className="player">
        <aside className="player__image">
          <img src={randomArtwork} />
        </aside>
        <div className="player__content" style={{ backgroundColor: '#E96187' }}>
          <div className="player__description">
            <p>Get Started</p>
            <h1 style={{ fontSize: 23, marginBottom: 21 }}>
              Your payment has failed
            </h1>
            <p style={{ fontSize: 13, marginBottom: 21 }}>
              Please update your credit card details to get online again.
            </p>
            <Button
              modifier={'primary'}
              style={{ padding: '15px 23px', width: 195 }}
              onClick={() => !loading && handleClick()}
            >
              {!loading ? 'Update Payment details' : 'Loading...'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SonosErrorPage;

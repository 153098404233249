/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const TOGGLE_FAVOURITE_DEVICE = gql`
	mutation favoriteDeviceToggleMutation(
		$userId: String!
		$deviceId: String!
		$toggle: Boolean!
	) {
	favoriteDeviceToggle(
		userId: $userId
		deviceId: $deviceId
		toggle: $toggle
	) {
		id
		favouriteDevices
	}
}
`;

/**
 * External dependencies
 */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * Internal dependencies
 */
import DashboardPage from 'domain/dashboard/pages/dashboard';
import SchedulePage from 'domain/schedule/pages/schedule';
import DeviceInfoPage from 'views/device-info/device-info';

const DashboardRoutes = () => {
  return (
    <Switch>
      <Route exact path="/:id/dashboard" component={DashboardPage} />
      <Route exact path="/:id/dashboard/schedule" component={SchedulePage} />
      <Route
        exact
        path="/:id/dashboard/information"
        component={DeviceInfoPage}
      />
    </Switch>
  );
};

export default DashboardRoutes;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_DEVICES_PLAYLIST } from '../mutations/update-device-playlist';

export default function useUpdateDevicesPlaylist() {
  const [updateDevicesPlaylist, { loading, data }] = useMutation(
    UPDATE_DEVICES_PLAYLIST,
    {
      onError: (e) => console.log('Could not update device playlist: ', e),
    }
  );

  return { updateDevicesPlaylist, loading, data };
}

import moment from 'moment';

export default function getHours(start, end) {
  const startTime = getTimeFromMins(start);
  const endTime = getTimeFromMins(end >= 1440 ? 0 : end);

  const duration =
    end < 1440
      ? moment.duration(endTime.diff(startTime))?.asHours()
      : 24 + moment.duration(endTime.diff(startTime))?.asHours();

  const result = [];

  result.push(startTime.format('hh:mm A'));

  if (duration < 1.001) {
    result.push(moment(startTime).add(duration, 'hours').format('hh:mm A'));
  } else {
    if (result[0] !== moment(startTime).startOf('hour').format('hh:mm A')) {
      result.push(startTime.startOf('hour').format('hh:mm A'));
    }

    while (
      moment(result[result.length - 1], 'LT') < moment(endTime).startOf('hour')
    ) {
      result.push(
        moment(result[result.length - 1], 'LT')
          .add(1, 'hours')
          .format('hh:mm A')
      );
    }

    while (duration === 24 && result.length < 24) {
      result.push(
        moment(result[result.length - 1], 'LT')
          .add(1, 'hours')
          .format('hh:mm A')
      );
    }
  }

  if (end === 1440) {
    result.push('12:00 AM');
  }

  if (result[result.length - 1] !== endTime.format('hh:mm A')) {
    result.push(endTime.format('hh:mm A'));
  }

  while (result.length < 3) {
    result.push('----');
  }

  return result;
}

const getTimeFromMins = (mins) => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError(
      'Valid input should be greater than or equal to 0 and less than 1440.'
    );
  }
  var h = (mins / 60) | 0,
    m = mins % 60 | 0;
  return moment().hours(h).minutes(m) /*.format('hh:mm A')*/;
};

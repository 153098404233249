/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SONOS_DEVICE_ACTION } from '../mutations/sonos-device-action';

export default function useSonosDeviceAction() {
  const [sonosDeviceAction, { loading, data }] =
    useMutation(SONOS_DEVICE_ACTION);

  return { sonosDeviceAction, loading, data };
}

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { REFRESH_TOKEN } from '../mutations/refresh-token';

/**
 * Internal dependencies
 */

export default function useRefreshToken() {
  const [refreshToken, { loading }] = useMutation(REFRESH_TOKEN);
  return {
    refreshToken,
    loading,
  };
}

import { gql } from '@apollo/client';

export const NOTIFICATION = gql`
  query notificationQuery {
    viewer {
      notification {
        id
        title
        message
        link
        level
      }
    }
  }
`;

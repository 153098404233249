/**
 * External dependencies
 */
import { useSubscription } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SUBSCRIBE_SONOS_DEVICE } from 'domain/sonos/subscriptions/subscribe-sonos-device';

export default function useSonosDeviceSubscription() {
  const { data, loading } = useSubscription(SUBSCRIBE_SONOS_DEVICE, {
    variables: {},
  });

  return {
    sonosDevicesSubscription: data,
    sonosDevicesSubscriptionloading: loading,
  };
}

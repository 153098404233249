/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SIGN_OUT_CLEAR = gql`
  mutation signOutClearMutation {
    signOutClear
  }
`;

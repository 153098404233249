/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_SONG_REACTIONS } from 'domain/dashboard/mutations/update-song-reactions';

export default function useUpdateSongReaction() {
  const [updateSongReaction, { loading }] = useMutation(UPDATE_SONG_REACTIONS, {
    onError: (e) => console.log('Could not update song reactions: ', e),
    onCompleted: () => null,
  });

  return { updateSongReaction, loading };
}

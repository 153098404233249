/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../button/button';
import * as rdd from 'react-device-detect';

const SearchSection = ({
  collectionId,
  title,
  children,
  hasViewAll,
  isInSearchSection,
  customStyle,
  onClick,
  textStyle,
  to,
}) => {
  return (
    <section className="section-search-result">
      <div className="section-search-result__head" style={customStyle}>
        <h4 style={{ ...textStyle, marginLeft: rdd?.isMobileOnly ? 13 : 0 }}>
          {title}
        </h4>

        {hasViewAll && isInSearchSection && (
          <Button onClick={onClick} modifier="as-link">
            View all
          </Button>
        )}
        {collectionId && hasViewAll && isInSearchSection === undefined && (
          <Link to={to}>View All</Link>
        )}
      </div>

      <div className="section-search-result__body">{children}</div>
    </section>
  );
};

export default SearchSection;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_QSYS_ZONES = gql`
  mutation updateQsysZones($deviceId: ID!, $zones: [ZoneInput!]) {
    updateQsysZones(deviceId: $deviceId, zones: $zones) {
      id
      noOfZones
      zones {
        zoneId
        zoneName
        songCount
        songId
        currentAlbum
        currentTitle
        currentArtist
        currentArtwork
        reason
        positionMillis
        durationMillis
        updateTrigger
        songReaction
        mixType
        mixId
        mixName
        volume
      }
    }
  }
`;

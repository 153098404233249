/**
 * Mount the portals container.
 */
let portalsContainer = document.querySelector('#portals-container');

if (!portalsContainer) {
	portalsContainer = document.createElement('div');
	portalsContainer.id = 'portals-container';

	document.body.appendChild(portalsContainer);
}

export default portalsContainer;

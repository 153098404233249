/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

/**
 * Internal dependencies
 */
import useSetSonosDeviceVolume from '../../domain/sonos/hooks/use-set-sonos-device-volume';

const ProgressRangeFooter = ({ volume, deviceId }) => {
  const [deviceVolume, setDeviceVolume] = useState({ values: [volume] });
  const { setSonosDeviceVolume, loading } = useSetSonosDeviceVolume();

  const [sliding, setSliding] = useState(false);

  useEffect(() => {
    if (!loading && !sliding) {
      setDeviceVolume({ values: [volume] });
    }
  }, [volume]);

  const handleSliderChange = (values) => {
    setDeviceVolume({ values });
    setSonosDeviceVolume({
      variables: {
        deviceId: deviceId,
        volume: values[0],
      },
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: 22,
        width: '120px',
      }}
    >
      <div style={{ width: 100 }}>
        <Range
          step={2}
          min={0}
          max={100}
          values={deviceVolume.values}
          onChange={(values) => {
            handleSliderChange(values);
            setSliding(true);
          }}
          onFinalChange={() => setSliding(false)}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '4px',
                opacity: '.75',
                background: getTrackBackground({
                  values: deviceVolume.values,
                  colors: [
                    'rgba(255, 255, 255, 0.75)',
                    'rgba(255, 255, 255, 0.3)',
                  ],
                  min: 0,
                  max: 100,
                }),
                borderRadius: '44px',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '14px',
                width: '14px',
                backgroundColor: '#ffffff',
                borderRadius: '50%',
              }}
            />
          )}
        />
      </div>
      <span style={{ marginLeft: 8, color: '#C8C8C8' }}>
        {deviceVolume?.values[0] || 0}
      </span>
    </div>
  );
};

export default ProgressRangeFooter;

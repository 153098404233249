/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_DEVICE_VOLUME = gql`
	mutation UpdateAudioDeviceVolumeMutation(
		$deviceId: String!, 
		$volume: Int!
  	) {
    	updateAudioDeviceVolume(
    		deviceId: $deviceId, 
    		remoteVolume: $volume
		) {
      		id
    	}
	}
`;

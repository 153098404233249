/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { UPDATE_QSYS_ZONE_VOLUME } from '../mutations/update-qsys-zone-volume';

/**
 * Internal dependencies
 */

export default function useUpdateQsysZoneVolume() {
  const [updateQsysZoneVolume, { loading, data: device }] = useMutation(
    UPDATE_QSYS_ZONE_VOLUME
  );

  return {
    updateQsysZoneVolume,
    loading,
    device,
  };
}

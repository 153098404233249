/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import SearchSection from 'components/search-section/search-section';
import AllVibes from 'domain/music/blocks/all-vibes';
import AllGenres from 'domain/music/blocks/all-genres';
import AllIntensities from 'domain/music/blocks/all-intensities';

import AllSceneCategories from 'domain/music/blocks/all-scene-categories';
import capitalize from 'helpers/capitalize';

const MusicSearchCollection = ({
  match: {
    params: { id, collectionId, sonosDeviceId },
  },
}) => {
  const renderItems = () => {
    if (collectionId === 'vibe')
      return (
        <AllVibes
          deviceId={id}
          collectionId={collectionId}
          sonosDeviceId={sonosDeviceId}
        />
      );
    if (collectionId === 'scenes')
      return <AllSceneCategories deviceId={id} sonosDeviceId={sonosDeviceId} />;
    if (collectionId === 'genres')
      return <AllGenres deviceId={id} sonosDeviceId={sonosDeviceId} />;
    if (collectionId === 'intensities')
      return <AllIntensities deviceId={id} sonosDeviceId={sonosDeviceId} />;
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={capitalize(collectionId)}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="music-search"
    >
      <SearchSection deviceId={id} sonosDeviceId={sonosDeviceId}>
        {renderItems()}
      </SearchSection>
    </DashboardLayout>
  );
};

export default MusicSearchCollection;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_MUSIC_PROFILE } from '../mutations/update-music-profile';

export default function useUpdateMusicProfile() {
  const [updateMusicProfile, { loading, data: userData }] =
    useMutation(UPDATE_MUSIC_PROFILE);

  return {
    updateMusicProfile,
    loading,
    userData,
  };
}

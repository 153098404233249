/**
 * External dependencies
 */
import { createContext } from 'react';

const AuthContext = createContext({
	token: null,
	user: null,
	currentDevice: null,
	isLoggedIn: false,
	loginSuccess: () => {},
	logoutSuccess: () => {},
	setCurrentDevice: () => {},
	setLoginLoading: () => {},
	setSessionToken: () => {},
	setSessionProvider: () => {},
	isLoading: false,
});

export default AuthContext;

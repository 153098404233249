/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import CardLink from 'components/card-link/card-link';
import CardLinks from 'components/card-links/card-links';
import * as rdd from 'react-device-detect';

const Page404 = ({
  message = "Unfortunately the page you've requested doesn't exist or is currently unavailable.",
}) => {
  return (
    <section className="section-404">
      <div className="section-404__title">
        <h3>Error 404</h3>

        <h1>{message}</h1>
      </div>

      <div className="section-404__links">
        <h2>Try visiting one of the following:</h2>

        <CardLinks large={!rdd?.isMobileOnly}>
          <CardLink
            vertical={!rdd?.isMobileOnly}
            to="/support"
            title="Support"
            subtitle="Get in touch and we'll do our best to get your devices up and running."
          />

          <CardLink
            vertical={!rdd?.isMobileOnly}
            to="/"
            title="Devices"
            subtitle="See all the devices you have."
            color="#0BCE68"
          />
        </CardLinks>
      </div>
    </section>
  );
};

export default Page404;

/**
 * External dependencies
 */
import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import generateClassesFromString from 'helpers/generate-classes-from-string';

const Button = ({
  as = 'button',
  to,
  children,
  icon,
  modifier,
  size,
  block = false,
  className,
  ...props
}) => {
  const buttonClass = 'btn';
  const HTMLElement = to ? Link : as;
  const linkProps = to ? { to: to } : {};

  const buttonModifiers = generateClassesFromString(buttonClass, modifier);
  const buttonSizes = generateClassesFromString(buttonClass, size);

  return (
    <HTMLElement
      className={cx(
        buttonClass,
        buttonModifiers,
        buttonSizes,
        {
          'btn--block': block,
        },
        className
      )}
      {...linkProps}
      {...props}
    >
      {children}
    </HTMLElement>
  );
};

export default Button;

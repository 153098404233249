/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { GET_SONG_REACTION } from 'domain/dashboard/queries/get-song-reaction';

export default function useGetSongReaction(songId, deviceId) {
  const { data, loading, error } = useQuery(GET_SONG_REACTION, {
    variables: {
      songId: songId || '',
      deviceId: deviceId || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const reaction = useMemo(() => {
    return data?.viewer.getSongReaction;
  }, [data]);

  return {
    reaction,
    loading,
    error,
  };
}

/**
 * External dependencies
 */
import React, { memo } from 'react';
import LoadingSvg from './loading-svg';

const LoadingProvider = ({
  children,
  loading,
  empty = false,
  style = {},
  emptyMessage = 'Nothing has been added yet.',
}) => {
  if (empty) {
    return <h4>{emptyMessage}</h4>;
  }

  return (
    <>
      {loading ? (
        <div className="loader" style={style}>
          <LoadingSvg />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default memo(LoadingProvider);

/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { NOTIFICATION } from '../queries/notification';

export default function useNotification() {
  const { data, loading, error } = useQuery(NOTIFICATION);

  return {
    data,
    loading,
    error,
  };
}

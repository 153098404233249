/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SEARCH_AUDIO_DEVICE = gql`
  query searchAudioDevice($text: String!) {
    viewer {
      searchAudioDevice(text: $text) {
        id
        name
        isOnline
        isPlaying
        currentSongId
        currentArtist
        macAddress
        makeAndModel
        ethernetMacAddress
        publicIpAddress
        currentArtwork
        currentAlbum
        lastSyncTime
        lastOnline
        latitude
        longitude
        clientId
        currentSongData {
          node {
            artworkUrl
            title
            artist
            album
          }
        }
        usersId {
          status {
            online
            lastLogin {
              date
            }
            curatorSignOut
          }
          roles
          rolesConsole {
            roleAccess
          }
          profile {
            name
          }
        }
        description
        currentPlaylistId
        currentSceneId
        remotePlaylistMode
        allSchedule {
          name
          startDate
          playingPlaylists {
            name
            startTime
            endTime
          }
        }
      }
    }
  }
`;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { TOGGLE_MANUALLY_PAUSED } from '../mutations/toggle-manually-paused';

export default function useToggleManuallyPaused() {
  const [toggleManuallyPaused, { loading }] = useMutation(
    TOGGLE_MANUALLY_PAUSED
  );

  return { toggleManuallyPaused, loading };
}

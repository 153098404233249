/**
 * External dependencies
 */
import {
  ApolloClient,
  createHttpLink,
  split,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
// import jwt_decode from 'jwt-decode';

const apolloClient = (token) => {
  const hasToken = token && token !== '';

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_API_URL,
  });

  let wsLink;
  if (hasToken) {
    let activeSocket, timedOut;
    wsLink = new GraphQLWsLink(
      createClient({
        url: process.env.REACT_APP_GRAPHQL_WSS_URL,
        connectionParams: {
          authorization: `Bearer ${token}`,
        },
        keepAlive: 60_000,
        on: {
          connected: (socket) => (activeSocket = socket),
          ping: (received) => {
            if (!received)
              timedOut = setTimeout(() => {
                if (activeSocket.readyState === WebSocket.OPEN)
                  activeSocket.close(4408, 'Request Timeout');
              }, 5_000);
          },
          pong: (received) => {
            if (received) clearTimeout(timedOut);
          },
        },
      })
    );
  }

  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
      connectionParams: {
        authorization: token || null,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  // Automatically switching between WebSocket link or HTTP Link based on our query type
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink || new ApolloLink((operation, forward) => forward(operation)), // Use wsLink if defined, else use a passthrough link
    authLink.concat(httpLink)
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          viewer: relayStylePagination(),
        },
      },
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, splitLink]),
    cache,
  });
};

export default apolloClient;

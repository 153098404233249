/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLinks from 'components/card-links/card-links';
import CardLink from 'components/card-link/card-link';
import Field from 'components/field/field';
import SearchSvg from '../../../assets/svgComponents/SearchSvg';
import LoadingProvider from '../../../components/loading-provider/loading-provider';
import Page404 from 'views/404/404';

import CurrentlyPlayingCard from '../../../components/current-playlist-card/currently-playing-card';
import CurrentlyPlayingCardMobile from '../../../components/current-playlist-card/currently-playing-card-mobile';
import SearchSection from '../../../components/search-section/search-section';
import useAllPlaylists from '../hooks/use-all-playlists';
import useAllScenes from '../hooks/use-all-scenes';
import _ from 'lodash';

import useSingleDevice from '../../devices/hooks/use-single-device';
import useDeviceSubscription from '../../devices/hooks/use-device-subscription';

import * as rdd from 'react-device-detect';
import useSonosDevice from '../../sonos/hooks/use-sonos-device';
import useAuth from '../../auth/hooks/use-auth';

const MusicPage = ({
  match: {
    params: { id, sonosDeviceId },
  },
}) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState();
  const [featuredPlayables, setFeaturedPlayables] = useState([]);

  const { currentDevice, setCurrentDevice, setCurrentClient, user } = useAuth();

  const isSonosDevice = !!sonosDeviceId;

  useDeviceSubscription({
    deviceId: id,
    userToken: localStorage.getItem('sessionToken'),
    skip: isSonosDevice,
  });

  const { device, loading } = isSonosDevice
    ? useSonosDevice(user?.id)
    : useSingleDevice(id);

  const { allFeaturedPlaylists, loading: loadingPlaylists } = useAllPlaylists();
  const { allFeaturedScenes, loading: loadingScenes } = useAllScenes();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    history.push(
      isSonosDevice
        ? `/sonos/${sonosDeviceId}/search/${searchValue}`
        : `/${id}/search/${searchValue}`
    );
  };

  useEffect(() => {
    if (device && device.id !== currentDevice) {
      setCurrentDevice(device.id);
      setCurrentClient(device.clientId);
    }
  }, [device?.id]);

  useEffect(() => {
    if (!loadingScenes && !loadingPlaylists) {
      const featuredConcat = [...allFeaturedScenes, ...allFeaturedPlaylists];
      setFeaturedPlayables(_.sampleSize(featuredConcat, 4));
    }
  }, [loadingScenes, loadingPlaylists]);

  const onNavigate = (e) => {
    e.stopPropagation();
    history.push(
      isSonosDevice
        ? `/sonos/${sonosDeviceId}/search/all/featured`
        : `/${id}/search/all/featured`
    );
  };

  if (!loading && !device) {
    return <Page404 />;
  }

  return (
    <LoadingProvider loading={loadingScenes || loadingPlaylists || !device}>
      <DashboardLayout
        isOnMusicRoute
        title="Music"
        backgroundImage={`url(${
          require('assets/svg-icons/background-OR.svg').default
        })`}
        modifier="music"
      >
        <div className="music-search">
          {rdd?.isMobileOnly && (
            <form
              action="?"
              onSubmit={handleSubmit}
              className="page__head"
              style={{
                display: 'inherit',
                paddingTop: 0,
              }}
            >
              <Field className="music__button" type="submit" value="Search" />
              <Field
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="music-field"
                type="search"
                icon={<SearchSvg />}
                style={{ borderColor: '#393B5A' }}
              />
            </form>
          )}

          {device?.remotePlaylistMode &&
            device?.isOnline &&
            (!rdd?.isMobileOnly && !rdd?.isTablet ? (
              <SearchSection
                title={'Playing off schedule'}
                customStyle={{ justifyContent: 'center' }}
              >
                <CurrentlyPlayingCard device={device} />
              </SearchSection>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: rdd?.isMobileOnly
                    ? '13px 13px 68px 13px'
                    : '13px 0px 68px 0px',
                }}
              >
                <h1 style={{ fontSize: 16, marginBottom: 20 }}>
                  Playing off schedule
                </h1>
                <CurrentlyPlayingCardMobile device={device} />
              </div>
            ))}

          {!rdd?.isMobileOnly && featuredPlayables.length > 0 && (
            <SearchSection
              title={'Featured Playlists and Scenes'}
              collectionId="scenes"
              hasViewAll={true}
              to={
                isSonosDevice
                  ? `/sonos/${sonosDeviceId}/search/all/featured`
                  : `/${id}/search/all/featured`
              }
            >
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gridGap: 20,
                  marginBottom: 20,
                }}
              >
                {featuredPlayables?.map((item) => {
                  let to =
                    item.type === 'playlist'
                      ? `/search/playlists/${item?.id}`
                      : `/search/all/scene/${item?.id}`;
                  if (sonosDeviceId) {
                    to = `/sonos/${sonosDeviceId}${to}`;
                  } else {
                    to = `/${id}${to}`;
                  }
                  return (
                    <CardLink
                      key={item?.id}
                      triggerPlayNow={{
                        playlistId: item?.id,
                        devicePlayType: item?.type?.toUpperCase(),
                      }}
                      to={to}
                      title={item?.name}
                      cover={item?.cover}
                      // style={{ height: 230 }}
                      hasButton
                    />
                  );
                })}
              </div>
            </SearchSection>
          )}

          {rdd?.isMobileOnly && featuredPlayables.length > 0 && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  padding: '0px 13px',
                }}
              >
                <h1 style={{ fontSize: 16 }}>Featured Playlists and Scenes</h1>
                <h1
                  style={{ fontSize: 16, color: '#3BABA1' }}
                  onClick={onNavigate}
                >
                  View all
                </h1>
              </div>

              <div
                style={{
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                }}
              >
                {featuredPlayables?.map((item, index) => {
                  const to =
                    item.type === 'playlist'
                      ? isSonosDevice
                        ? `/sonos/${sonosDeviceId}/search/playlists/${item.id}`
                        : `/${id}/search/playlists/${item.id}`
                      : isSonosDevice
                      ? `/${id}/search/all/scene/${item.id}`
                      : `/sonos/${sonosDeviceId}/search/all/scene/${item.id}`;
                  return (
                    <CardLink
                      key={item?.id}
                      triggerPlayNow={{
                        playlistId: item?.id,
                        devicePlayType: item?.type?.toUpperCase(),
                      }}
                      to={to}
                      title={item?.name}
                      cover={item?.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: 13,
                        padding: '20px 20px 16%',
                        marginRight:
                          index === featuredPlayables?.length - 1 ? 13 : 7,
                      }}
                      hasButton
                    />
                  );
                })}
              </div>
            </div>
          )}

          <h4
            style={{
              fontSize: 16,
              marginBottom: rdd?.isMobileOnly ? 7 : 20,
              marginLeft: rdd?.isMobileOnly ? 13 : 0,
              marginTop: rdd?.isMobileOnly ? 68 : 40,
            }}
          >
            Discover Playlists and Scenes
          </h4>

          <CardLinks large>
            <CardLink
              to={
                isSonosDevice
                  ? `/sonos/${sonosDeviceId}/search/all/scenes`
                  : `/${id}/search/all/scenes`
              }
              title="Scenes"
              description="Expertly curated Scenes for every business, from restaurants, shops, and hotels, to offices, schools, and fitness centers."
              color="#C361E9"
              graphic={require('assets/images/card-image-1.png').default}
              graphicAlign="right bottom"
              customStyle={{ mixBlendMode: 'overlay' }}
            />

            <CardLink
              to={
                isSonosDevice
                  ? `/sonos/${sonosDeviceId}/search/all/genres`
                  : `/${id}/search/all/genres`
              }
              title="Genres"
              description="Discover new and classic tracks sorted by the genre. Rock out, get jazzy, or add a splash of Pop whenever the mood strikes."
              color="#670BCE"
              graphic={require('assets/images/card-image-2.png').default}
              graphicAlign="center bottom"
            />

            <CardLink
              to={
                isSonosDevice
                  ? `/sonos/${sonosDeviceId}/search/all/intensities`
                  : `/${id}/search/all/intensities`
              }
              title="Intensities"
              description="Set the perfect pace ranging from easy and smooth to upbeat and eclectic."
              color="#0BCEC9"
              graphic={require('assets/images/card-image-3.png').default}
              graphicAlign="right top"
            />

            <CardLink
              to={
                isSonosDevice
                  ? `/sonos/${sonosDeviceId}/search/all/vibe`
                  : `/${id}/search/all/vibe`
              }
              title="Vibes"
              description="Find the vibe that resonates with your crowd. Pick things and get energetic or mellow out with a wash of calm tunes."
              color="#6183E9"
              graphic={require('assets/images/card-image-4.png').default}
              graphicAlign="center bottom"
            />
          </CardLinks>
        </div>
      </DashboardLayout>
    </LoadingProvider>
  );
};

export default MusicPage;

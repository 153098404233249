/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { CHANGE_DEFAULT_DEVICEID } from '../mutations/change-default-deviceId';

export default function useToggleFavouriteDevice() {
  const [changeDefaultDeviceId, { loading }] = useMutation(
    CHANGE_DEFAULT_DEVICEID
  );

  return { changeDefaultDeviceId, loading };
}

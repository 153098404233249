/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SUBSCRIBE_SONOS_DEVICE = gql`
  subscription SonosDeviceSubscription {
    SonosDeviceSubscription {
      mutation
      node {
        id
        deviceId
        groupId
        userIds
        songId
        currentUser
        currentAlbum
        currentTitle
        currentArtist
        currentArtwork
        currentMixId
        currentMixName
        currentMixType
        reason
        volume
      }
    }
  }
`;

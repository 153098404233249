import React from 'react';

function LoadingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 42.6 42.6"
      version="1.1"
      xmlSpace="preserve"
      className="c-loader"
    >
      <path
        className="c-loader__bg"
        d="M42.6,21.3c0,11.8-9.5,21.3-21.3,21.3C9.5,42.6,0,33,0,21.3S9.5,0,21.3,0C33,0,42.6,9.5,42.6,21.3"
      />
      <path
        className="c-loader__bar"
        d="M11.8,31.4c0-1.4,1-2.4,2.5-2.4h14.1c1.4,0,2.5,1,2.5,2.4c0,1.4-1,2.4-2.5,2.4H14.3 C12.9,33.8,11.8,32.8,11.8,31.4"
      />
      <path
        className="c-loader__dot"
        d="M23.5,24.7c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4S23.5,23.4,23.5,24.7"
      />
      <path
        className="c-loader__letter"
        d="M30.2,23.2L23.3,9c-0.4-0.9-1.2-1.4-2.1-1.4c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c-0.8,0-1.6,0.5-2.1,1.4 L12,23.2c-0.6,1.3-0.2,2.7,1,3.3c1.2,0.6,2.6,0.1,3.2-1.1l4.8-9.9l4.8,9.9c0.6,1.3,2,1.8,3.2,1.1C30.4,25.8,30.9,24.4,30.2,23.2"
      />
    </svg>
  );
}

export default LoadingSvg;

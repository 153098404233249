import React, { useEffect } from 'react';
import useNotification from './hooks/use-notification';
import Marquee from 'react-fast-marquee';
import * as rdd from 'react-device-detect';

const NotificationLayout = ({ children }) => {
  const { data, loading, error } = useNotification();

  const notification = data?.viewer?.notification;

  const notificationColor =
    notification &&
    (notification.level === 'info'
      ? '#ECDC32'
      : notification.level === 'warning'
      ? '#ff6600'
      : ' #FF0040');

  useEffect(() => {
    if (notification) {
      window.localStorage.setItem('notification', 1);
    } else {
      window.localStorage.setItem('notification', 0);
    }
  }, [notification]);

  return (
    <>
      {notification && (
        <div
          style={{
            backgroundColor: notificationColor,
            width: '100%',
            zIndex: 99999,
            color: '#000',
            padding: rdd?.isMobileOnly ? '8px 0px' : '8px 15px',
          }}
        >
          <Marquee
            gradient={false}
            speed={rdd?.isMobileOnly ? 35 : 0}
            play={true}
          >
            <h3 style={{ marginRight: 15 }}>{notification.title}</h3>

            <a href={notification.link} target="_blank">
              {notification.message}
            </a>
          </Marquee>
        </div>
      )}
      {children}
    </>
  );
};

export default NotificationLayout;

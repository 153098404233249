/**
 * External dependencies
 */
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import crypto from 'crypto';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';
import useLogin from 'domain/auth/hooks/use-login';
import emailPattern from 'helpers/email-pattern';
import useAuth from 'domain/auth/hooks/use-auth';

import { toast } from 'react-toastify';

function useQuery() {
  const URLSearchParams = window.URLSearchParams;
  return new URLSearchParams(useLocation().search);
}

const FormLogin = ({ showPasswordReset, showRegister }) => {
  let query = useQuery();
  const [loginEmail, { data, loading }] = useLogin('email');
  const { loginSuccess } = useAuth();

  const redirectUrl = query.get('redirectUrl');
  const linkCode = query.get('linkCode');
  const jwtToken = query.get('jwtToken');

  const customId = 'prevent-duplicate';

  const notify = () =>
    toast.dark('Invalid credentials!', {
      position: 'bottom-center',
      autoClose: 10000,
      hideProgressBar: true,
      toastId: customId,
      bodyStyle: { color: '#FF4000' },
    });

  const handleSubmit = async (dt) => {
    loginEmail({
      variables: {
        serviceType: query.get('loginType'),
        emailData: {
          email: dt.email,
          password: crypto
            .createHash('sha256')
            .update(dt.password)
            .digest('hex'),
        },
        jwtToken: (redirectUrl && linkCode && jwtToken) || '',
      },
    }).then(({ data }) => {
      if (!data?.signinUser?.token && !data?.signinUser?.user) {
        notify();
      }
    });
  };

  useEffect(() => {
    if (data?.signinUser && data?.signinUser?.token && data?.signinUser?.user) {
      loginSuccess({
        token: data.signinUser.token,
        user: data.signinUser.user,
        provider: 'email',
        sonosAuthObj: data?.signinUser?.sonosAuthObj,
        redirectUrl,
        linkCode,
      });
      toast.dismiss();
    }
  }, [data, loginSuccess]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onForgotPasswordClick = () => {
    showPasswordReset();
  };

  return (
    <div className="form form--login">
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(handleSubmit)}
          onError={() => console.log('hehe')}
        >
          <div className="form__body">
            <div className="form__row">
              <FieldValidated
                type="email"
                name="email"
                placeholder="name@domain.com"
                validations={{
                  required: 'Field is required',
                  pattern: {
                    value: emailPattern,
                    message: 'E-Mail address is not valid',
                  },
                }}
                autocomplete="username"
              />
            </div>

            <div className="form__row">
              <FieldValidated
                type="password"
                name="password"
                placeholder="Enter password"
                validations={{
                  required: 'Field is required.',
                }}
                autocomplete="current-password"
              />
            </div>
          </div>

          <div
            className="form__actions"
            style={rdd?.isMobileOnly ? { display: 'block' } : {}}
          >
            <Button type="submit" modifier="primary">
              {loading ? (
                <div className="loader">
                  <span></span>

                  <span></span>

                  <span></span>
                </div>
              ) : (
                'Login'
              )}
            </Button>

            <Button
              modifier="as-link"
              type="button"
              onClick={() => onForgotPasswordClick()}
              style={rdd?.isMobileOnly ? { padding: 8, marginLeft: 8 } : {}}
            >
              Forgot password
            </Button>
            <Button
              modifier="as-link"
              type="button"
              onClick={() => showRegister()}
              style={{ padding: 8 }}
            >
              Sign up
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormLogin;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */

import { ACTIVATE_QSYS_SERVICE } from '../mutations/activate-qsys-service';

export default function useActivateQsysService() {
  const [activateQsysService, { loading }] = useMutation(ACTIVATE_QSYS_SERVICE);

  return { activateQsysService, loading };
}

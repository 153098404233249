/**
 * External dependencies
 */
import React, { useState } from 'react';
import _ from 'lodash';

import Button from '../../components/button/button';
import LoadingProvider from '../../components/loading-provider/loading-provider';
import { ReactComponent as IcoCurator } from '../../assets/svg-icons/Curator-dot.svg';
import getRandomColor from '../../helpers/get-random-color';
import PlayNowModal from '../../components/play-now-modal/play-now-modal';

const Playlist = ({
  subtitle,
  name,
  title,
  description,
  children,
  cover,
  devicePlayType,
  playlistId,
  showPreviewButton,
  handlePreview,
  sonosDeviceId,
}) => {
  const [randomColor] = useState(getRandomColor(_.random(14)));

  //   show loader when click play then redirect to home
  return (
    <LoadingProvider loading={!children}>
      <div className="playlist">
        <div
          className="playlist__card"
          style={{ backgroundColor: randomColor }}
        >
          <aside className="playlist__card-aside">
            {cover && <img src={cover} alt={name} />}
            <IcoCurator
              style={{ position: 'absolute', height: 50, bottom: 20 }}
            />
            {subtitle && <h5>{subtitle}</h5>}
            {name && <h2>{name}</h2>}
          </aside>

          <div className="playlist__card-content">
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
            <div style={{ position: 'absolute', bottom: 30 }}>
              {playlistId && (
                <PlayNowModal
                  playlistId={playlistId}
                  devicePlayType={devicePlayType}
                  sonosDeviceId={sonosDeviceId}
                >
                  <Button modifier="primary" style={{ position: 'initial' }}>
                    Play Now
                  </Button>
                </PlayNowModal>
              )}
              {playlistId && showPreviewButton && (
                <Button
                  onClick={handlePreview}
                  modifier="secondary"
                  style={{ position: 'initial', marginLeft: 10 }}
                >
                  Preview
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="playlist__body">{children}</div>
      </div>
    </LoadingProvider>
  );
};

export default Playlist;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { FORGOT_PASSWORD } from 'domain/auth/mutations/forgot-password';

export default function useForgotPassword() {

	const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

	return { forgotPassword, loading };
}

/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Button from '../button/button';
import Modal from '../modal/modal';

const NoDevicesModal = ({ visible, onClose, addDevice }) => {
  const handleAddDevice = () => {
    addDevice();

    onClose();
  };

  return (
    <Modal visible={visible} onClose={() => onClose()}>
      <div className="modal-window__container" style={{ paddingBottom: 16 }}>
        <div className="modal-window__imgcontainer">
          <img
            className="modal-window__graphics2"
            src={require('../../assets/images/modals/CC-exp.png').default}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className="modal-window__title">Welcome to Curator</p>
          <p className="modal-window__subtitle">
            You currently don't have any devices attached to your account.
          </p>
          <Button
            modifier="white2"
            onClick={handleAddDevice}
            style={{ marginRight: 20, padding: 15 }}
          >
            Add a device
          </Button>
          <Button
            modifier="as-link"
            onClick={() => onClose()}
            style={{ color: '#fff', fontWeight: 'bold' }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NoDevicesModal;

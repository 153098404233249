import React, { useContext, useEffect } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import Tag from './category/tag';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';

// const categories = ['Small', 'Crazy', 'Boring', 'Skinny', 'Wide', 'Other'];

const CategorySpecific = ({ setSelectedStack, stack, categories }) => {
  const {
    selectedSpecificCategory,
    setSelectedSpecificCategory,
    selectedCategory,
    otherSpecificCategoryValue,
    setOtherSpecificCategoryValue,
    businessName,
    setSubCategories,
  } = useContext(BoardingContext);

  const findCategory = categories.find(
    (item) => item.name === selectedCategory
  );
  const subCategories = [...(findCategory?.subCategories || [])];
  subCategories.push('Other');

  useEffect(() => {
    setSubCategories(subCategories);
  }, [selectedCategory]);

  useEffect(() => {
    if (otherSpecificCategoryValue?.length) {
      setSelectedSpecificCategory('Other');
    }
  }, [otherSpecificCategoryValue]);

  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      disabled={
        !selectedSpecificCategory ||
        (selectedSpecificCategory === 'Other' &&
          otherSpecificCategoryValue.length < 2)
      }
      progress={{ firstBar: '66', secondBar: '0', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{businessName}</p>
        <p className="modal-boarding__title">More specifically?</p>
        <Tag
          title={selectedCategory}
          isSelected={true}
          setSelectedCategory={() => {}}
          style={{ width: 'fit-content', marginBottom: 25 }}
        />

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {subCategories.map((item) => (
            <Tag
              key={item}
              title={item}
              isSelected={selectedSpecificCategory === item}
              handleOnClick={() => {
                setSelectedSpecificCategory(item);
                if (item !== 'Other') {
                  setOtherSpecificCategoryValue('');
                }
              }}
            />
          ))}
        </div>
        {selectedSpecificCategory === 'Other' && (
          <InputBoarding
            onChange={(e) => setOtherSpecificCategoryValue(e.target.value)}
            value={otherSpecificCategoryValue}
            placeholder="Your business type"
            style={{ marginTop: 10 }}
          />
        )}
      </div>
    </BoardingWrapper>
  );
};

export default CategorySpecific;

import React from 'react';

const Card = ({ children, color, height }) => {
  return (
    <div
      className="player"
      style={{
        backgroundColor: color,
        height,
      }}
    >
      {children}
    </div>
  );
};

export default Card;

import React from 'react';
import cx from 'classnames';
import getRandomArtwork from '../../../helpers/get-random-artwork';
import { ReactComponent as IcoPlay } from 'assets/svg-icons/ico-play.svg';
import { ReactComponent as IcoPlayFilled } from 'assets/svg-icons/ico-play-filled.svg';
import { ReactComponent as IcoPause } from 'assets/svg-icons/ico-pause.svg';

export function SongItem({ song, onClick, id, isPlaying }) {
  return (
    <div className={cx('playlist__song')}>
      <div className="playlist__song-image">
        <div onClick={onClick}>
          <span className="playlist__image-overlay" />
          <img
            src={
              song.artworkUrl
                ? song.artworkUrl.replace('artwork', 'thumbnails')
                : getRandomArtwork()
            }
            alt={song.title}
            style={{
              width: 94,
              height: 94,
              background: '#4D5082',
            }}
          />
          {isPlaying ? (
            <div className="playlist__pause-icon-container">
              <IcoPause className="playlist__pause-icon" />
            </div>
          ) : (
            <div className="playlist__play-icon-container">
              <IcoPlay className="playlist__play-icon" />
              <IcoPlayFilled className="playlist__play-icon-filled" />
              <p className="playlist__preview">Preview</p>
            </div>
          )}
          {isPlaying && (
            <svg height="94" width="94" className="playlist__circle-svg">
              <circle
                cx="47"
                cy="47"
                r="30"
                stroke="#3BABA1"
                strokeWidth="5"
                fill="#333"
                fillOpacity="0%"
                strokeLinecap="round"
              />
            </svg>
          )}
        </div>
      </div>
      <div className="playlist__song-content" onClick={onClick}>
        <h4>{song.title}</h4>
        <h5>{song.artist}</h5>
        <h5>{song.album}</h5>
      </div>
    </div>
  );
}

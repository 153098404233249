/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_PLAYLISTS = gql`
  query allPlaylistsQuery(
    $allPlaylistsFirst: Int
    $allPlaylistsAfter: String
    $allPlaylistsFilter: PlaylistFilter
    $allPlaylistsDeviceId: String
  ) {
    viewer {
      allPlaylists(
        first: $allPlaylistsFirst
        after: $allPlaylistsAfter
        filter: $allPlaylistsFilter
        deviceId: $allPlaylistsDeviceId
      ) {
        featuredIds
        edges {
          cursor
          node {
            id
            name
            cover
            description
            genre
            tempo
            featuredArtists
          }
        }
      }
    }
  }
`;

import React, { useState } from 'react';
import * as rdd from 'react-device-detect';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../../../music/hooks/use-all-scenes';
import SearchSection from 'components/search-section/search-section';
import Card from '../../../music/components/card';
import _ from 'lodash';
import getRandomColor from '../../../../helpers/get-random-color';
import capitalize from '../../../../helpers/capitalize';

import ResultItem from 'components/result-item/result-item';
import flattenArrays from '../../../../utils/flattenArrays';
import useAllCategories from '../../../sonos/hooks/use-all-categories';

const AllSceneCategories = ({ id }) => {
  const [categoryExpanded, setCategoryExpanded] = useState([]);
  const { allScenes } = useAllScenes();
  const { allCategories } = useAllCategories();

  const categories = [...(allCategories || [])]
    ?.filter((item) => !['VIBE', 'CUSTOM'].includes(item?.name))
    ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
    ?.filter((item) => item.subCategories.length > 0);

  let sceneCategories = [];

  allScenes.forEach((item) => {
    sceneCategories = sceneCategories.concat(item?.newCategory);
  });

  const handleViewAll = (category) => {
    setCategoryExpanded([...new Set([...categoryExpanded, category])]);
  };

  return (
    <LoadingProvider loading={!allScenes || !allCategories}>
      {rdd?.isMobileOnly && (
        <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
          Categories
        </h4>
      )}
      <div
        style={
          rdd?.isMobileOnly
            ? {
                width: '100vw',
                marginBottom: 135,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: 13,
                padding: '0px 13px',
              }
            : {}
        }
      >
        {categories?.map((item, index) => {
          const category = item?.name;
          const subCategoriesPermissions = [
            ...(item?.subCategories || []),
          ].sort();
          const filteredScenes = allScenes?.filter((scene) => {
            return (
              scene.newCategory.findIndex(
                (item2) =>
                  item2.category === category &&
                  _.intersection(subCategoriesPermissions, item2.subCategories)
                    .length > 0
              ) >= 0
            );
          });

          const subCategoriesArray = flattenArrays(
            filteredScenes?.map((scene) => scene.newCategory)
          );

          const subCategoriesFromScenes =
            _.uniq(
              flattenArrays(
                subCategoriesArray
                  ?.filter((item) => item.category === category)
                  .map((item) => item.subCategories)
              )
            ) || [];

          const subCategories = _.intersection(
            subCategoriesPermissions,
            subCategoriesFromScenes
          );

          if (filteredScenes.length === 0) {
            return null;
          }

          return rdd?.isMobileOnly ? (
            <Card
              key={category}
              index={index}
              title={category}
              to={`/qsys/${id}/search/all/scenes/${category.toLowerCase()}`}
            />
          ) : (
            // DESKTOP VERSION BELOW
            // DESKTOP VERSION
            // DESKTOP VERSION
            // DESKTOP VERSION

            <SearchSection
              title={capitalize(category.toLowerCase())}
              deviceId={id}
              collectionId="scenes"
              hasViewAll={!categoryExpanded.includes(category)}
              to={`/qsys/${id}/search/all/scenes/${category.toLowerCase()}`}
              key={category}
              textStyle={{ fontSize: 22 }}
              count={subCategories.length}
              isInSearchSection
              onClick={() => handleViewAll(category)}
            >
              <div
                style={{
                  maxWidth: 980,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridGap: 20,
                }}
              >
                {subCategories
                  .slice(
                    0,
                    categoryExpanded.includes(category)
                      ? subCategories.length
                      : 6
                  )
                  .map((item) => {
                    return (
                      <ResultItem
                        dotColor={getRandomColor(_.random(14))}
                        key={item}
                        to={`/qsys/${id}/search/all/scenes/${category.toLowerCase()}/${item.toLowerCase()}`}
                        title={capitalize(item.toLowerCase())}
                      />
                    );
                  })}
              </div>
            </SearchSection>
          );
        })}
      </div>
    </LoadingProvider>
  );
};

export default AllSceneCategories;

import * as React from 'react';

function Share({ onClick }) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="svg-stroke"
      style={{ marginRight: 32 }}
      onClick={onClick}
    >
      <g strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
        <path d="M20 9h-9a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-9a2 2 0 00-2-2z" />
        <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
      </g>
    </svg>
  );
}

export default Share;

/**
 * External dependencies
 */
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

/**
 * Internal dependencies
 */
import useAuth from 'domain/auth/hooks/use-auth';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

const SonosRoute = ({ component: Component, ...props }) => {
  const { isLoggedIn } = useAuth();
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        if (user && user.serviceType && !user.serviceType.includes('SONOS')) {
          return <Redirect to="/" />;
        }
        return <Component {...routeProps} />;
      }}
    />
  );
};

export default SonosRoute;

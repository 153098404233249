/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SONOS_QUERY = gql`
  query sonosDeviceQuery($userId: String) {
    viewer {
      sonosDevice(userId: $userId) {
        id
        deviceId
        groupId
        currentUser
        currentAlbum
        currentTitle
        currentArtist
        currentArtwork
        reason
        volume
        currentMixId
        currentMixName
        currentMixType
        viewableCategories {
          name
          subCategories
          sortOrder
        }
      }
    }
  }
`;

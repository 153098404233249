import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';

const Business = ({ setSelectedStack, stack }) => {
  const { businessName, setBusinessName } = useContext(BoardingContext);

  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      disabled={businessName.length < 2}
      progress={{ firstBar: '33', secondBar: '0', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">Let’s get to know you</p>
        <p className="modal-boarding__title">Your business</p>
        <InputBoarding
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          placeholder="What’s your business name?"
        />
      </div>
    </BoardingWrapper>
  );
};

export default Business;

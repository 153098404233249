/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { HUBSPOT } from '../query/hubspot-data';

export default function useHubSpotData() {
  const { data, loading, error } = useQuery(HUBSPOT);

  const hubspotData = data && JSON.parse(data.viewer.getHubSpotData);
  return {
    hubspotData,
    loading,
    error,
  };
}

/**
 * External dependencies
 */
import React from 'react';
import _ from 'lodash';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import ResultItem from 'components/result-item/result-item';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllPlaylists from 'domain/music/hooks/use-all-playlists';
import getRandomColor from '../../../../helpers/get-random-color';
import Card from '../../../music/components/card';

const AllGenres = ({ id }) => {
  const { allPlaylists, loading: loadingPlaylists } = useAllPlaylists();
  const allMixes = allPlaylists;

  const allGenres = _.uniq(
    _.flattenDeep(
      allMixes?.map((playlist) => (playlist?.genre ? [...playlist.genre] : []))
    ).map((item) => item.toUpperCase())
  );

  return (
    <LoadingProvider loading={loadingPlaylists}>
      {rdd?.isMobileOnly ? (
        <div
          style={{
            width: '100vw',
            marginBottom: 135,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: 13,
            padding: '0px 13px',
          }}
        >
          {!loadingPlaylists &&
            allGenres?.sort().map((genre, index) => {
              return (
                <Card
                  index={index}
                  key={genre}
                  title={genre}
                  to={`/qsys/${id}/search/all/genres/${genre}`}
                />
              );
            })}
        </div>
      ) : (
        <div className="list-results-small">
          {!loadingPlaylists &&
            allGenres?.length > 0 &&
            allGenres
              .sort()
              .map((item) => (
                <ResultItem
                  color={getRandomColor(_.random(14))}
                  key={item}
                  to={`/qsys/${id}/search/all/genres/${item}`}
                  title={item}
                  hasImage
                />
              ))}
        </div>
      )}
    </LoadingProvider>
  );
};

export default AllGenres;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_REMOTE_PLAYLIST_MODE } from '../mutations/update-remote-playlist-mode';

export default function useUpdateDeviceRemotePlaylistMode() {
  const [updateDeviceRemotePlaylistMode, { loading, data }] = useMutation(
    UPDATE_REMOTE_PLAYLIST_MODE,
    {
      onError: (e) =>
        console.log('Could not update device remote playlist mode: ', e),
    }
  );

  return { updateDeviceRemotePlaylistMode, loading, data };
}

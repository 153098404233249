/**
 * Internal dependencies
 */

import { useQuery } from '@apollo/client';

import { useMemo } from 'react';
import { GET_SONG_REACTIONS_BY_DEVICEID } from '../query/get-song-reactions-by-deviceid';

export default function useGetSongReactionsByDeviceId(deviceId, songId) {
  const { data, loading, error, refetch } = useQuery(
    GET_SONG_REACTIONS_BY_DEVICEID,
    {
      variables: {
        deviceId,
        songId,
      },
    }
  );
  const getSongReactionsByDeviceId = useMemo(
    () => [...(data?.viewer?.getSongReactionsByDeviceId || [])],
    [data]
  );

  return {
    getSongReactionsByDeviceId,
    loading,
    error,
    refetch,
  };
}

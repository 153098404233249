/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SONOS_AUTH_TOKEN } from 'domain/auth/mutations/signin-user';

export default function useSonosAuthToken() {
  const [getSonosAuthToken, { error }] = useMutation(SONOS_AUTH_TOKEN);

  return { getSonosAuthToken, error };
}

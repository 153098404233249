/**
 * External dependencies
 */
import React from 'react';

const EmojiField = ({
  id,
  label,
  icon,
  type,
  onChange,
  color,
  onMouseOver,
  onClick,
  isChecked = false,
}) => {
  return (
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      className={`emoji-field ${isChecked ? 'is-checked' : ''}`}
      style={{ backgroundColor: color, width: 370 }}
    >
      <label htmlFor={id}>
        <img src={icon} alt="" />

        <span>{label}</span>
      </label>

      <input
        type="radio"
        name={id}
        id={id}
        value={type}
        onChange={(e) => onChange(e.target.value)}
        checked={isChecked}
      />
    </div>
  );
};

export default EmojiField;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const USER_ARTWORK = gql`
  query allArtworkQuery {
    viewer {
      userArtwork
    }
  }
`;

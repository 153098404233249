import React from 'react';

/**
 * Internal dependencies
 */
import cx from 'classnames';
import Button from 'components/button/button';

const Article = ({
  single = false,
  title,
  image,
  color,
  children,
  to,
  ...props
}) => {
  return (
    <article
      className={cx('article', { 'article--single': single })}
      style={{ backgroundColor: color }}
    >
      <Button to={to} className="article__link" />

      {image && (
        <div className="article__image">
          <img src={image} alt={title} />
        </div>
      )}

      <div className="article__content">
        <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>

        <p dangerouslySetInnerHTML={{ __html: children }}></p>
      </div>
    </article>
  );
};

export default Article;

/**
 * External dependencies
 */
import React, { useState } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Schedule from 'components/schedule/schedule';
import useSchedule from '../hooks/use-schedule';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import * as rdd from 'react-device-detect';

import SearchSection from '../../../components/search-section/search-section';
import CurrentlyPlayingCard from '../../../components/current-playlist-card/currently-playing-card';
import useUpdateDeviceRemotePlaylistMode from '../../dashboard/hooks/use-update-remote-playlist-mode';
import OffScheduleModal from '../../../components/modal-status/modal-off-schedule';
import CurrentlyPlayingCardMobile from '../../../components/current-playlist-card/currently-playing-card-mobile';
import ScheduleMobile from '../../../components/schedule/schedule-mobile';

const SchedulePage = ({
  match: {
    params: { id },
  },
}) => {
  const { schedule, loading } = useSchedule(id);
  const { device } = useSingleDevice(id);
  const [isModalVisible, setIsModalVisible] = useState(true);

  const { updateDeviceRemotePlaylistMode } =
    useUpdateDeviceRemotePlaylistMode();

  const resetSchedule = async (e) => {
    e && e.stopPropagation();
    await updateDeviceRemotePlaylistMode({
      variables: {
        deviceId: device.id,
      },
    });
  };

  const handleOnCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <DashboardLayout
      subtitle={device?.name ? `${device?.name}'s Music` : ''}
      title="Schedule"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="schedule"
    >
      <LoadingProvider loading={loading}>
        <OffScheduleModal
          visible={isModalVisible && device?.remotePlaylistMode}
          onClose={handleOnCloseModal}
          playingNow={device?.currentPlaylist?.name}
          resetSchedule={resetSchedule}
        />
        {device?.remotePlaylistMode &&
          device?.isOnline &&
          (!rdd?.isMobileOnly ? (
            <SearchSection title={'Playing off schedule'}>
              <CurrentlyPlayingCard device={device} />
            </SearchSection>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                margin: '13px 13px 68px 13px',
              }}
            >
              <h1 style={{ fontSize: 16, marginBottom: 20 }}>
                Playing off schedule
              </h1>
              <CurrentlyPlayingCardMobile device={device} />
            </div>
          ))}
        {!rdd?.isMobileOnly ? (
          <Schedule device={device} scheduleData={schedule} />
        ) : (
          <ScheduleMobile device={device} scheduleData={schedule} />
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SchedulePage;

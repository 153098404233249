/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SUBSCRIBE_DEVICES = gql`
  subscription AudioDeviceSubscription(
    $token: String!
    $updatedId: ID
    $updatedIds: [ID]
    $updatedFields: [String]
  ) {
    AudioDeviceSubscription(
      token: $token
      updatedId: $updatedId
      updatedIds: $updatedIds
      updatedFields: $updatedFields
    ) {
      mutation
      node {
        id
        name
        description
        lastSyncTime
        isPlaying
        isOnline
        currentSongId
        scheduleIds
        remoteVolume
        manuallyPaused
        skipDisabled
        remotePlaylistMode
      }
    }
  }
`;

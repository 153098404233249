/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_DEVICE_VOLUME } from 'domain/dashboard/mutations/update-device-volume';

export default function useUpdateDeviceVolume() {

	const [updateDeviceVolume, {loading}] = useMutation(UPDATE_DEVICE_VOLUME, {
		onError: (e) => console.log('Could not update device volume: ', e)
	});

	return { updateDeviceVolume, loading };
}

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { GET_DISPLAY_CODE } from '../mutations/get-display-code';

export default function useGetDisplayCode() {
  const [getDisplayCode, { loading, data: gotDisplayCode }] = useMutation(
    GET_DISPLAY_CODE
  );

  return { getDisplayCode, loading, gotDisplayCode };
}

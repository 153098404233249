/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Gravatar from 'react-gravatar';

import getRandomColor from '../../helpers/get-random-color';
import _ from 'lodash';

/**
 * Internal dependencies
 */
import LoadingProvider from 'components/loading-provider/loading-provider';

const UsersList = ({ usersArray, loading }) => {
  const [users, setUsersArray] = useState(usersArray);

  useEffect(() => {
    setUsersArray(usersArray);
  }, [usersArray, users]);

  return (
    <div className="users-list">
      <h4>All users</h4>

      <LoadingProvider loading={loading}>
        <div className="users-list__body">
          {users &&
            users?.length &&
            users?.map((user) => (
              <div
                key={user?.id}
                className={cx('user', { 'is-online': user?.status.online })}
              >
                <div
                  className="user__image"
                  style={{ backgroundColor: getRandomColor(_.random(14)) }}
                >
                  <Gravatar
                    email={user ? user?.emails[0].address : null}
                    size={100}
                    rating="pg"
                    default="blank"
                    className="users-list__gravatar"
                  />

                  <h6> {user ? user?.profile?.name?.split('')[0] : ''} </h6>
                </div>

                <div className="user__content">
                  <h2>{user?.profile?.name}</h2>

                  {/* <h5>
                    {user && user?.roles[0] === 'admin'
                      ? 'Administrator Level'
                      : 'User Level'}
                  </h5> */}

                  <h5>{user?.status?.lastOnline}</h5>
                </div>
              </div>
            ))}
        </div>
      </LoadingProvider>
    </div>
  );
};

export default UsersList;

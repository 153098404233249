/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SEARCH_PLAYLISTS = gql`
  query searchPlaylists($text: String!, $clientId: String, $deviceId: String) {
    viewer {
      searchPlaylists(text: $text, clientId: $clientId, deviceId: $deviceId) {
        __typename
        ... on Scene {
          id
          name
          image
          description
          newCategory {
            category
            subCategories
          }
        }
        ... on Playlist {
          id
          name
          cover
          description
        }
      }
    }
  }
`;

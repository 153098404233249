/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const REQUEST_ACCESS = gql`
  mutation RequestAccessMutation($email: String, $text: String) {
    requestAccess(email: $email, text: $text)
  }
`;

/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import useGetSongReaction from 'domain/dashboard/hooks/use-get-reaction';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import EmojiField from 'components/emoji-field/emoji-field';
import useUpdateSongReaction from 'domain/dashboard/hooks/use-update-song-reaction';

import { ALL_AUDIO_DEVICES } from '../../domain/devices/queries/all-audio-devices';
import useAuth from '../../domain/auth/hooks/use-auth';

const FormTrackFeedback = ({ device, zone, onSubmit }) => {
  const { currentDevice } = useAuth();
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [textareaActive, setTextareaActive] = useState(false);
  const [feedbackInput, setFeedbackInput] = useState('');
  const { updateSongReaction } = useUpdateSongReaction();

  const { reaction, loading, error } = useGetSongReaction(
    device?.currentSong?.id || zone?.songId,
    device?.id || currentDevice
  );

  const [emojisList, setEmojisList] = useState([
    {
      id: 'type-party',
      value: 1,
      label: 'More like this please!',
      icon: require('assets/images/emoji-party.png').default,
      color: '#0BCEC9',
      showCommentBox: false,
    },

    {
      id: 'type-wow',
      value: 2,
      label: 'New discovery!',
      icon: require('assets/images/emoji-wow.png').default,
      color: '#7F61E9',
      showCommentBox: false,
    },

    {
      id: 'type-hearts',
      value: 3,
      label: 'Personal fav!',
      icon: require('assets/images/emoji-hearts.png').default,
      color: '#670BCE',
      showCommentBox: false,
    },

    {
      id: 'type-ok',
      value: 4,
      label: 'Perfect fit!',
      icon: require('assets/images/emoji-ok.png').default,
      color: '#E96187',
      showCommentBox: false,
    },

    {
      id: 'type-frowning',
      value: 5,
      label: 'Not feeling this',
      icon: require('assets/images/emoji-frowning.png').default,
      color: '#E961CB',
      showCommentBox: false,
    },

    {
      id: 'type-bore',
      value: 6,
      label: 'Yawn',
      icon: require('assets/images/emoji-bore.png').default,
      color: '#3BABA1',
      showCommentBox: false,
    },

    {
      id: 'type-explicit',
      value: 7,
      label: 'Explicit lyrics',
      icon: require('assets/images/emoji-explicit.png').default,
      color: '#C361E9',
      showCommentBox: false,
    },

    {
      id: 'type-eugh',
      value: 8,
      label: "Didn't I just hear this?",
      icon: require('assets/images/emoji-eugh.png').default,
      color: '#670BCE',
      showCommentBox: false,
    },
  ]);

  useEffect(() => {
    if (reaction) {
      if (reaction.feedback) {
        setTextareaActive(true);
        setFeedbackInput(reaction.feedback);
      }
      if (reaction.songReaction) {
        setSelectedEmoji(reaction.songReaction);
      }
    }
  }, [reaction, loading]);

  useEffect(() => {
    if (reaction && reaction.songReaction === parseInt(selectedEmoji)) {
      setFeedbackInput(reaction.feedback);
      if (reaction.feedback) {
        let tempEmojisList = [
          ...emojisList.map((item) => {
            return { ...item, showCommentBox: false };
          }),
        ];
        const findIndex = emojisList.findIndex(
          (item, index) => index + 1 === parseInt(selectedEmoji)
        );

        tempEmojisList[findIndex].showCommentBox = true;

        setEmojisList(tempEmojisList);
      }
    } else {
      setFeedbackInput('');
    }
  }, [selectedEmoji]);

  const handleSubmit = (
    e,
    selEmoji = selectedEmoji,
    removeFeedback = false
  ) => {
    e.preventDefault();
    updateSongReaction({
      variables: {
        songId: device?.currentSong?.id || zone?.songId,
        deviceId: device?.id || currentDevice,
        songReaction: parseInt(selEmoji),
        feedback: !removeFeedback ? feedbackInput : '',
        serviceType: zone ? 'qsys' : 'curator',
        zoneId: zone?.zoneId,
      },
      refetchQueries: [
        ALL_AUDIO_DEVICES, // DocumentNode object parsed with gql
        'allAudioDevicesFavouritesQuery', // Query name
      ],
    });

    onSubmit();
  };

  const handleTextareaShow = (e) => {
    e.preventDefault();

    setTextareaActive(!textareaActive);
  };

  const handleSubmitFeedback = (e) => {};

  const setEmoji = (val) => {
    setSelectedEmoji(val);
    setTextareaActive(true);
  };

  const [emojiHovered, setEmojiHovered] = useState(null);

  const onMouseOver = (e, item) => {
    setEmojiHovered(item);
  };

  const handleShowCommentBox = () => {
    let tempEmojisList = [
      ...emojisList.map((item) => {
        return { ...item, showCommentBox: false };
      }),
    ];
    const findIndex = emojisList.findIndex((item) => item.id === emojiHovered);

    setSelectedEmoji(findIndex + 1);
    tempEmojisList[findIndex].showCommentBox = true;

    setEmojisList(tempEmojisList);
  };

  return (
    <div className="form form--track-feedback">
      <form onSubmit={handleSubmit}>
        <div className={`form__body ${selectedEmoji ? 'has-selected' : ''}`}>
          {emojisList.map((emoji, index) => (
            <div
              onMouseLeave={() => setEmojiHovered(null)}
              className="form__row"
              key={emoji.id}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <EmojiField
                  onClick={(e) => handleSubmit(e, index + 1, true)}
                  onMouseOver={(e) => onMouseOver(e, emoji.id)}
                  label={emoji.label}
                  id={emoji.id}
                  type={emoji.value}
                  onChange={(val) => setEmoji(val)}
                  isChecked={parseFloat(selectedEmoji) === emoji.value}
                  icon={emoji.icon}
                  color={emoji.color}
                />

                {emojiHovered === emoji.id && (
                  <span
                    style={{
                      color: '#3BABA1',
                      cursor: 'pointer',
                      marginLeft: 20,
                    }}
                    onClick={handleShowCommentBox}
                  >
                    Leave a comment
                  </span>
                )}
              </div>

              {emoji.showCommentBox && (
                <div style={{ maxWidth: 370, marginTop: 20 }}>
                  <textarea
                    name="feedback-message"
                    id="feedback-message"
                    placeholder="Tell us what you think"
                    onChange={(e) => setFeedbackInput(e.target.value)}
                    value={feedbackInput}
                  ></textarea>

                  <Button
                    style={{ width: '40%' }}
                    type="submit"
                    modifier="primary"
                    onClick={handleSubmitFeedback}
                  >
                    Send
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* {!textareaActive && (
          <Button modifier="as-link" onClick={handleTextareaShow}>
            Tell us what you think
          </Button>
        )} */}
      </form>
    </div>
  );
};

export default FormTrackFeedback;

/**
 * External dependencies
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

/**
 * Internal dependencies
 */

import SonosTrialScreen from '../sonos-trial-screen';
import SonosInstallScreen from '../sonos-install-screen';
import SonosDashboard from '../sonos-dashboard';

import useAuth from 'domain/auth/hooks/use-auth';
import SonosMusicRoutes from './sonos-music-routes';

const SonosRoutes = () => {
  const { user } = useAuth();

  return (
    <Switch>
      {user && !user.profile?.sonosPermit && !user?.stripe?.sonosTrial && (
        <Route exact path="/sonos/trial" component={SonosTrialScreen} />
      )}
      <Route exact path="/sonos/dashboard" component={SonosDashboard} />

      <Route exact path="/sonos/install" component={SonosInstallScreen} />

      <Route path="/sonos/:id" component={SonosMusicRoutes} />

      {user && (
        <Route path="/sonos">
          {user?.profile?.sonosPermit ? (
            <Redirect to="/sonos/dashboard" />
          ) : user?.stripe?.sonosTrial ? (
            <Redirect to="/sonos/dashboard" />
          ) : (
            <Redirect to="/sonos/trial" />
          )}
        </Route>
      )}
    </Switch>
  );
};

export default SonosRoutes;

/**
 * Internal dependencies
 */

import { useQuery } from '@apollo/client';

import { useMemo } from 'react';
import { ALL_QSYS_DEVICES } from '../query/all-qsys-devices';

export default function useAllQsysDevices() {
  const { data, loading, error, refetch } = useQuery(ALL_QSYS_DEVICES);
  const allQsysDevices = useMemo(
    () => [...(data?.viewer?.allQsysDevices || [])],
    [data]
  );

  return {
    allQsysDevices,
    loading,
    error,
    refetch,
  };
}

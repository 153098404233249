/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SUBSCRIBE_USERS = gql`
  subscription UserSubscription($token: String!, $updatedId: ID) {
    UserSubscription(token: $token, updatedId: $updatedId) {
      mutation
      node {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        defaultDeviceId
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        emails {
          address
        }
        serviceType
        defaultServiceType
        companyName
        qsys {
          pin
          noOfZones
        }
        sonos {
          authProcessStatusDone
        }
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
          qsysSubscriptionPlan {
            id
          }
        }
      }
    }
  }
`;

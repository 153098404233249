import React, { useRef, useLayoutEffect, useState, useContext } from 'react';

import Button from '../../components/button/button';
import { ReactComponent as IcoBack } from '../../assets/svg-icons/ico-back-boarding.svg';
import ProgressBar from './components/progress-bar';

import useUpdateMusicProfile from './hooks/use-update-music-profile';
import { BoardingContext } from './context/boardingContext';

const BoardingWrapper = ({
  stack,
  setSelectedStack,
  disabled,
  progress,
  children,
}) => {
  const { config } = useContext(BoardingContext);
  const wrapperRef = useRef();
  const [iconBackground, setIconBackground] = useState('transparent');

  const { updateMusicProfile } = useUpdateMusicProfile();

  const handleContinue = () => {
    updateMusicProfile({
      variables: {
        companyName: config.businessName,
        musicProfileProvider: {
          category: config.selectedCategory,
          subCategory:
            config.otherSpecificCategoryValue?.length > 0
              ? config.otherSpecificCategoryValue
              : config.selectedSpecificCategory,
          genres: config.genreValues,
          avoidGenres: config.avoidGenreValues,
          artists: Object.values(config.artistValues),
          vibe: config.vibeValues,
          explicit: config.isExplicit,
        },
        socialProfileProvider: {
          instagram: config.socialValues?.Twitter,
          linkedIn: config.socialValues?.LinkedIn,
          facebook: config.socialValues?.Facebook,
          spotify: config?.socialValues['Spotify / Apple Music / Tidal'],
        },
      },
    });
    setSelectedStack(
      (selectedStack) => stack[stack.indexOf(selectedStack) + 1]
    );
  };
  const handleBack = () => {
    setSelectedStack(
      (selectedStack) => stack[stack.indexOf(selectedStack) - 1]
    );
  };

  useLayoutEffect(() => {
    wrapperRef?.current?.addEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });

    wrapperRef?.current?.removeEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });
  }, []);

  return (
    <div className="modal-window__boarding">
      {/*  */}
      <div
        style={{
          position: 'absolute',
          top: -20,
          width: '100%',
          display: 'flex',
        }}
      >
        <ProgressBar width="111px" progress={progress.firstBar} />
        <ProgressBar
          margin="0px 9px"
          width="112px"
          progress={progress.secondBar}
        />
        <ProgressBar width="72px" progress={progress.thirdBar} />
      </div>
      {/*  */}
      <div
        style={{
          top: 10,
          left: 10,
          cursor: 'pointer',
          width: 60,
          height: 60,
          borderRadius: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={handleBack}
      >
        <IcoBack />
      </div>
      <div
        style={{ height: 'calc(100% - 125px)', overflow: 'auto' }}
        ref={wrapperRef}
      >
        {children}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          position: 'absolute',
          bottom: 13,
        }}
      >
        <Button
          modifier="as-link"
          onClick={handleBack}
          style={{
            color: '#3BABA1',
            marginRight: 20,
          }}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          modifier="primary"
          onClick={handleContinue}
          style={{
            padding: '12px 0px',
            width: 110,
            borderRadius: 13,
            height: 45,

            border: disabled ? 'none' : '',
            color: disabled ? '#FFFFFF' : '',
            backgroundColor: disabled ? '#3BABA180' : '',
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default BoardingWrapper;

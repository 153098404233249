/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { UPDATE_QSYS_ZONES } from '../mutations/update-qsys-zones';

/**
 * Internal dependencies
 */

export default function useUpdateQsysZones() {
  const [updateQsysZones, { loading, data: device }] =
    useMutation(UPDATE_QSYS_ZONES);

  return {
    updateQsysZones,
    loading,
    device,
  };
}

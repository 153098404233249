import React, { useEffect, useState } from 'react';
import Button from 'components/button/button';

import DashboardLayout from '../../layouts/dashboard-layout';

import { Toggle } from 'react-powerplug';
import getRandomColor from 'helpers/get-random-color';

import useAuth from 'domain/auth/hooks/use-auth';
import useQsysDeviceSubscription from './hooks/use-qsys-device-subscription';
import QsysModal from './qsys-modal';

const QsysZonesScreen = ({ qsysDevices }) => {
  const { user } = useAuth();
  const [zones, setZones] = useState(qsysDevices[0].zones || []);
  const device = qsysDevices[0];

  const { qsysDevicesSubscription, qsysDevicesSubscriptionLoading } =
    useQsysDeviceSubscription();

  useEffect(() => {
    if (!qsysDevicesSubscriptionLoading) {
      setZones(
        qsysDevicesSubscription?.QsysDeviceSubscription?.node?.zones || []
      );
    }
  }, [qsysDevicesSubscription]);

  return (
    <DashboardLayout
      subtitle="Curator"
      title={'Q-SYS Music zones'}
      modifier="devices"
      showSwitcher
    >
      <div
        style={{
          height: 80,
          backgroundColor: '#393B5A',
          borderRadius: 3,
          padding: 20,
          gap: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p style={{ fontWeight: 'bold' }}>
          PIN:{' '}
          {!user
            ? 'Loading'
            : user?.qsys?.pin.toString().slice(0, 3) +
              '-' +
              user?.qsys?.pin.toString().slice(3, 6)}
        </p>
        <p>Use this to activate your Q-SYS plugin.</p>
      </div>
      <div className="devices">
        <div className="devices__head" style={{ marginBottom: 20 }}>
          <div className="subtitle">
            <h4 style={{ fontSize: 16 }}>Active zones: {zones.length} </h4>
            <Toggle>
              {({ on, toggle }) => (
                <>
                  <Button
                    modifier="as-link"
                    onClick={toggle}
                    style={{ fontSize: 16 }}
                  >
                    Edit zones
                  </Button>
                  <QsysModal
                    on={on}
                    toggle={toggle}
                    zones={zones}
                    deviceId={device.id}
                    noOfZones={device.noOfZones}
                  />
                </>
              )}
            </Toggle>
          </div>
        </div>
        <div className="devices__body">
          {zones?.map((zone, index) => {
            return (
              <div
                key={zone?.zoneId}
                className="device"
                style={{
                  backgroundColor:
                    zone?.reason === 'play'
                      ? getRandomColor(index % 15)
                      : 'grey',
                }}
              >
                <Button
                  to={{
                    pathname: `/qsys/${zone?.zoneId}`,
                    // state: { id: zone.zoneId },
                  }}
                  //   onClick={handleSetCurrentDevice}
                  className="device__link"
                />

                <div className="device__head">
                  <h3>{zone?.zoneName}</h3>

                  {/* <p>{device.description}</p> */}
                </div>

                <div className="device__body">
                  <h4>{zone?.currentTitle}</h4>

                  <h5>{zone?.currentArtist}</h5>
                </div>

                {/* <button
                  onClick={handleAddToFavourites}
                  className="device__heart"
                >
                  <IcoHeart />
                </button> */}
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          marginTop: 90,
          paddingBottom: 50,
        }}
      >
        <p style={{ fontWeight: 'bold' }}>Need help?</p>
        <p>
          Visit{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://support.activaire.com/q-sys"
          >
            https://support.activaire.com/q-sys
          </a>
        </p>
      </div>
    </DashboardLayout>
  );
};

export default QsysZonesScreen;

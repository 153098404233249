/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';

const FooterAuth = () => {
  return (
    <footer className="footer">
      <nav className="footer__links">
        <ul>
          <li>
            <a
              href="https://www.activaire.com/legal-disclaimer/"
              target="_blank"
            >
              Legal
            </a>
          </li>

          <li>
            <a href="https://www.activaire.com/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </li>

          <li>
            <a href="https://www.activaire.com/privacy-policy/" target="_blank">
              Cookies
            </a>
          </li>
        </ul>
      </nav>

      <small className="footer__copyright">
        &copy; {new Date().getFullYear()} Activaire
      </small>
    </footer>
  );
};

export default FooterAuth;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CREATE_SONOS_CLOUD_TOKEN = gql`
  mutation createSonosCloudToken($code: String!) {
    createSonosCloudToken(code: $code)
  }
`;

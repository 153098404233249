import React from 'react';

const Progress_bar = ({ progress, width, margin }) => {
  const Parentdiv = {
    height: 5,
    width: width,
    backgroundColor: '#56245D',
    borderRadius: 13,
    margin: margin,
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#D154C5',
    borderRadius: 40,
    textAlign: 'right',
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
};

export default Progress_bar;

/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_GENRES_FOR_SONOS } from 'domain/music/queries/all-genres';
import convertToKebabCase from 'helpers/convert-to-kebap-case';

export default function useAllGenresForSonos(options) {
  const { data, loading, error } = useQuery(ALL_GENRES_FOR_SONOS);

  const allGenresForSonos = useMemo(
    () =>
      !error && [
        ...(data?.viewer?.allGenresForSonos?.map((item) => item?.name) || []),
      ],
    [data]
  );

  return {
    allGenresForSonos,
    loading,
    error,
  };
}

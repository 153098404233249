/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SINGLE_SCENE } from '../queries/single-scene';

export default function useSingleScene(
  sceneId,
  songSkip = 0,
  songLimit = 30,
  deviceId
) {
  const { data, loading, error, fetchMore } = useQuery(SINGLE_SCENE, {
    variables: {
      id: sceneId,
      songSkip,
      songLimit,
      deviceId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const scene = useMemo(
    () =>
      !error &&
      data?.viewer?.allScenes?.edges.map((scene) => ({
        id: scene?.node?.id,
        name: scene.node?.name,
        cover: scene.node?.image,
        description: scene.node?.description,
        category: scene.node?.category,
        subCategory: scene.node?.subCategory,
        songs: scene.node?.songs,
        featuredArtists: scene.node?.featuredArtists,
        newCategory: scene.node?.newCategory,
      })),
    [data, error]
  );

  return {
    scene: scene && scene[0],
    loading,
    error,
    fetchMore,
  };
}

import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import Tag from './category/tag';

const Category = ({ setSelectedStack, stack, categories }) => {
  const { businessName, setSelectedCategory, selectedCategory } =
    useContext(BoardingContext);
  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      disabled={!selectedCategory}
      progress={{ firstBar: '66', secondBar: '0', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{businessName}</p>
        <p className="modal-boarding__title">What do you do?</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {categories.map((item) => (
            <Tag
              key={item.id}
              title={item.name}
              isSelected={selectedCategory === item.name}
              handleOnClick={() => setSelectedCategory(item.name)}
            />
          ))}
        </div>
      </div>
    </BoardingWrapper>
  );
};

export default Category;

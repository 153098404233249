/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const GET_SONG_REACTIONS_BY_DEVICEID = gql`
  query getSongReactionsByDeviceIdQuery($deviceId: ID!, $songId: ID!) {
    viewer {
      getSongReactionsByDeviceId(deviceId: $deviceId, songId: $songId) {
        songReaction
        userName
      }
    }
  }
`;

import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import LoadingProvider from '../../components/loading-provider/loading-provider';

function OktaCallback() {
  return (
    <>
      <LoadingProvider loading />
      <LoginCallback />
    </>
  );
}

export default OktaCallback;

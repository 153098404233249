/**
 * External dependencies
 */
import React, { memo, useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';

/**
 * Internal dependencies
 */
import useLogin from 'domain/auth/hooks/use-login';
import useAuth from 'domain/auth/hooks/use-auth';
import Button from 'components/button/button';

import { ReactComponent as IcoGoogle } from 'assets/svg-icons/ico-google.svg';

import { toast } from 'react-toastify';

const useQuery = () => {
  return new window.URLSearchParams(useLocation().search);
};

const ButtonGoogleLogin = () => {
  let query = useQuery();
  const [loaded, setLoaded] = useState(true);
  const [loginGoogle, { loading, data }] = useLogin('google');
  const {
    loginSuccess,
    setLoginLoading,
    newUserRegisteredHubspotEmail,
    setSonosFlow,
  } = useAuth();

  const redirectUrl = query.get('redirectUrl');
  const linkCode = query.get('linkCode');
  const jwtToken = query.get('jwtToken');
  // check if trakdesk_cid cookie is available
  const trackdeskCid = document.cookie
    .split('; ')
    .find((row) => row.startsWith('trakdesk_cid='))
    ?.split('=')[1];

  const notify = () =>
    toast.dark('Your account was successfully created', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      bodyStyle: { color: '#fff' },
      style: { backgroundColor: '#670BCE' },
    });

  const handleButtonClick = (onClick = () => {}) => {
    setLoginLoading(true);

    onClick();
  };

  useEffect(() => {
    setLoginLoading(loading);
  }, [loading, setLoginLoading]);

  const handleLoginGoogleSuccess = (data) => {
    const googleCredential = data?.access_token;

    loginGoogle({
      variables: {
        googleCredential,
        serviceType: query.get('loginType'),
        jwtToken: (redirectUrl && linkCode && jwtToken) || '',
        menuId: query.get('exId'),
        ...(trackdeskCid && { trackdeskCid }),
      },
    }).then((res) => {
      const isNewUser = res.data.handleGoogle.newUser;
      const householdId =
        res.data.handleGoogle?.sonosAuthObj?.householdId || null;

      if (isNewUser) {
        const servicetype = [];

        if (query.get('loginType') === 'sonos') {
          servicetype.push('Sonos');
          setSonosFlow(true);
        } else {
          servicetype.push('Sonos');
          servicetype.push('Curator');
        }
        newUserRegisteredHubspotEmail({
          firstname: name,
          email: res.data.handleGoogle.user.email,
          company: '',
          servicetype,
          loginmethod: 'google',
          householdId,
        });
        notify();
      }
    });
  };

  const handleLoginGoogleError = (error) => {
    console.log('ERROR', error);
  };

  const onGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => handleLoginGoogleSuccess(codeResponse),
    onError: (error) => handleLoginGoogleError(error),
    scope: 'email profile',
  });

  useEffect(() => {
    if (data && loaded) {
      setLoaded(false);
      loginSuccess({
        token: data.handleGoogle.token,
        user: data.handleGoogle.user,
        provider: 'google',
        sonosAuthObj: data?.handleGoogle?.sonosAuthObj,
        redirectUrl,
        linkCode,
      });
    }
  }, [data, loginSuccess]);

  return (
    <Button modifier="icon" onClick={() => onGoogleLogin()}>
      <IcoGoogle />

      {loading ? 'Loading...' : 'Google'}
    </Button>
  );
};

export default memo(ButtonGoogleLogin);

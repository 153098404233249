/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CHECK_STRIPE_CUSTOMER = gql`
  mutation checkStripeCustomer($userId: String) {
    checkStripeCustomer(userId: $userId)
  }
`;

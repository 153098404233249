/**
 * External dependencies
 */
import React, { Suspense, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import AuthLayout from 'layouts/auth';
import FormLogin from 'domain/auth/blocks/form-login/form-login';
import FormReset from 'blocks/forms/form-reset';
import FormRecover from '../../../blocks/forms/form-recover';
import FormRegister from 'domain/auth/blocks/form-register/form-register';
import LoginSocials from 'views/auth/login/loginSocials';
import Button from 'components/button/button';
import TermsModal from 'domain/auth/blocks/terms/terms';
import { ReactComponent as LogoColored } from 'assets/svg-icons/logo-colored.svg';

const Graphic = React.lazy(() =>
  import('../../../assets/svg-icons/login-graphic')
);
const RegistrationGraphic = React.lazy(() =>
  import('../../../components/logo/RegistrationGraphic')
);
const RegistrationGraphic2 = React.lazy(() =>
  import('../../../components/logo/RegistrationGraphic2')
);
const RegistrationGraphic3 = React.lazy(() =>
  import('../../../components/logo/RegistrationGraphic3')
);
const RegistrationGraphic4 = React.lazy(() =>
  import('../../../components/logo/RegistrationGraphic4')
);

function useQuery() {
  return new window.URLSearchParams(useLocation().search);
}

const LoginPage = () => {
  let query = useQuery();
  const history = useHistory();
  const [showForgot, setShowForgot] = useState(false);
  const [termsModalVisibility, setTermsModalVisibility] = useState(false);
  const [showRegisterPage, setShowRegisterPage] = useState(
    query.get('register') === 'true'
  );

  const [showResetPage, setShowResetPage] = useState(!!query.get('resetToken'));

  const loginType = query.get('loginType');

  const goBackCallback = () => {
    setShowRegisterPage(false);
    setShowForgot(false);
    setShowRegisterPage(false);
    setShowResetPage(false);
  };

  const onPressSignUp = () => {
    setShowRegisterPage(true);
  };

  const updateURLSearchParams = (value) => {
    query.set('loginType', value);
    query.set('register', 'true');
    history.push(window.location.pathname + '?' + query.toString());
  };

  const closeCallback = () => {
    setTermsModalVisibility(false);
  };

  return (
    <div className="page page--login">
      <div
        className="page__background"
        style={
          (loginType === 'sonos' || loginType === 'qsys') && rdd?.isMobileOnly
            ? { background: 'black' }
            : {}
        }
      >
        <div className="page__background-image"></div>
      </div>

      <AuthLayout
        onPressSignUp={onPressSignUp}
        hideSignup={showRegisterPage}
        isSonos={loginType === 'sonos'}
      >
        {rdd?.isMobileOnly ? (
          <div className="mobile-auth">
            <div>
              {showRegisterPage ? (
                <div className="section-auth__graphic2">
                  {loginType === 'sonos' ? (
                    <Suspense fallback={<div></div>}>
                      <RegistrationGraphic3
                        height="350px"
                        viewBox="100 40 450 440"
                      />
                    </Suspense>
                  ) : loginType === 'qsys' ? (
                    <Suspense fallback={<div></div>}>
                      <RegistrationGraphic4
                        width={'100%'}
                        height={300}
                        viewBox="50 40 380 420"
                      />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div></div>}>
                      <RegistrationGraphic />
                    </Suspense>
                  )}
                </div>
              ) : (
                <img
                  src={require('assets/svg-icons/login-graphic.svg').default}
                  alt="login"
                />
              )}
              <div
                style={{
                  padding: '0px 13px 13px 13px',
                  marginTop: showRegisterPage ? -50 : -20,
                }}
              >
                <p style={{ fontSize: 12 }}>Hi there 👋</p>
                <h2 style={{ fontSize: 27 }}>
                  {showRegisterPage
                    ? loginType === 'sonos'
                      ? 'Try Activaire Curator for Sonos for 14 days in your business today.'
                      : loginType === 'qsys'
                      ? 'Try Activaire Curator for Q-SYS for 7 days in your business today.'
                      : 'Just a few more steps to the playground...'
                    : 'Log in, get started'}
                </h2>
                {showRegisterPage && (
                  <h4>
                    {loginType === 'sonos' || loginType === 'qsys'
                      ? 'Looking for Curator registration page?'
                      : 'Looking for Curator for Sonos?'}
                    <Button
                      style={{ padding: 4 }}
                      modifier="as-link"
                      onClick={() =>
                        loginType === 'sonos' || loginType === 'qsys'
                          ? updateURLSearchParams('curator')
                          : updateURLSearchParams('sonos')
                      }
                    >
                      <h4>Click here</h4>
                    </Button>
                  </h4>
                )}
              </div>
            </div>

            <div className="login">
              {!showRegisterPage && !showForgot && !showResetPage && (
                <FormLogin
                  showPasswordReset={() => setShowForgot(true)}
                  showRegister={() => setShowRegisterPage(true)}
                />
              )}
              {showRegisterPage && !showForgot && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <FormRegister
                      setShowRegisterPage={setShowRegisterPage}
                      setShowReset={setShowForgot}
                      goBackCallback={goBackCallback}
                    />
                  </div>
                </div>
              )}

              {showForgot && (
                <FormReset
                  hidePasswordReset={() => setShowForgot(false)}
                  goBackCallback={() => setShowForgot(false)}
                />
              )}

              {showResetPage && (
                <FormRecover
                  hidePasswordReset={() => setShowResetPage(false)}
                  goBackCallback={goBackCallback}
                />
              )}

              {showRegisterPage &&
              (loginType === 'sonos' || loginType === 'qsys') ? (
                <div style={{ marginTop: 19 }}>
                  <p className="section-auth__terms">
                    {`By clicking the “Get Started” button above, you
                        agree to the Activaire Curator for ${
                          loginType === 'sonos' ? 'Sonos' : 'Q-SYS'
                        }`}
                    <Button
                      style={{
                        padding: '0px 3px',
                        marginTop: 0,
                        textDecoration: 'underline',
                      }}
                      modifier="as-link"
                      onClick={() => setTermsModalVisibility(true)}
                    >
                      terms and conditions.
                    </Button>
                    You also agree to receive marketing and support emails from
                    Activaire.
                  </p>
                </div>
              ) : null}

              {!showForgot && !showResetPage && <LoginSocials />}

              <div className="logo-container">
                <h4
                  style={{
                    color: '#666666',
                    marginBottom: 3,
                  }}
                >
                  Powered by
                </h4>
                <LogoColored />
              </div>
            </div>
          </div>
        ) : (
          <div className="section-auth">
            <aside>
              <h5>Hi there 👋</h5>

              <h2>
                {showRegisterPage
                  ? loginType === 'sonos'
                    ? 'Try Activaire Curator for Sonos for 14 days in your business today.'
                    : loginType === 'qsys'
                    ? 'Try Activaire Curator for Q-SYS for 7 days in your business today.'
                    : 'Just a few more steps to the playground...'
                  : 'Log in, get started'}
              </h2>
              {showRegisterPage && (
                <h4>
                  {loginType === 'sonos' || loginType === 'qsys'
                    ? 'Looking for Curator registration page?'
                    : 'Looking for Curator for Sonos?'}
                  <Button
                    style={{ padding: 4 }}
                    modifier="as-link"
                    onClick={() =>
                      loginType === 'sonos' || loginType === 'qsys'
                        ? updateURLSearchParams('curator')
                        : updateURLSearchParams('sonos')
                    }
                  >
                    <h4>Click here</h4>
                  </Button>
                </h4>
              )}

              <div className="section-auth__graphic">
                {showRegisterPage ? (
                  loginType === 'sonos' ? (
                    <Suspense fallback={<div></div>}>
                      <RegistrationGraphic3 />
                    </Suspense>
                  ) : loginType === 'qsys' ? (
                    <Suspense fallback={<div></div>}>
                      <RegistrationGraphic4
                        width={560}
                        height={393}
                        viewBox="0 -30 380 420"
                      />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div></div>}>
                      <RegistrationGraphic2 />
                    </Suspense>
                  )
                ) : (
                  <Suspense fallback={<div></div>}>
                    <Graphic />
                  </Suspense>
                )}
              </div>
            </aside>

            <div className="section-auth__content">
              {showResetPage ? (
                <FormRecover
                  hidePasswordReset={() => setShowResetPage(false)}
                  goBackCallback={goBackCallback}
                />
              ) : showForgot ? (
                <>
                  <FormReset
                    hidePasswordReset={() => setShowForgot(false)}
                    goBackCallback={goBackCallback}
                  />
                </>
              ) : (
                <>
                  {!showRegisterPage ? (
                    <FormLogin
                      showPasswordReset={() => setShowForgot(true)}
                      showRegister={() => setShowRegisterPage(true)}
                    />
                  ) : (
                    <FormRegister
                      setShowRegisterPage={setShowRegisterPage}
                      setShowReset={setShowForgot}
                      goBackCallback={goBackCallback}
                    />
                  )}
                  {showRegisterPage &&
                  (loginType === 'sonos' || loginType === 'qsys') ? (
                    <div style={{ marginTop: 32 }}>
                      <p className="section-auth__terms">
                        {`By clicking the “Get Started” button above, you
                        agree to the Activaire Curator for ${
                          loginType === 'sonos' ? 'Sonos' : 'Q-SYS'
                        }`}
                        <Button
                          style={{
                            padding: '0px 3px',
                            textDecoration: 'underline',
                          }}
                          modifier="as-link"
                          onClick={() => setTermsModalVisibility(true)}
                        >
                          terms and conditions.
                        </Button>
                        You also agree to receive marketing and support emails
                        from Activaire.
                      </p>
                    </div>
                  ) : null}
                  <LoginSocials />
                </>
              )}
            </div>
          </div>
        )}
      </AuthLayout>
      <TermsModal
        visible={termsModalVisibility}
        closeCallback={closeCallback}
        serviceType={loginType === 'qsys' ? 'qsys' : 'sonos'}
      />
    </div>
  );
};

export default LoginPage;

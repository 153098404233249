import React, { useEffect, useContext, useState } from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../../music/hooks/use-all-scenes';
import useAllPlaylists from '../../music/hooks/use-all-playlists';
import CardLink from 'components/card-link/card-link';

import ResultItem from '../../../components/result-item/result-item';
import stc from 'string-to-color';

const FeaturedPage = ({
  match: {
    params: { id },
  },
}) => {
  const [featuredPlayables, setFeaturedPlayables] = useState([]);
  const { allFeaturedScenes, loading: loadingScenes } = useAllScenes();
  const { allFeaturedPlaylists, loading: loadingPlaylists } = useAllPlaylists();

  useEffect(() => {
    if (!loadingScenes && !loadingPlaylists) {
      const featuredConcat = [...allFeaturedScenes, ...allFeaturedPlaylists];

      setFeaturedPlayables(featuredConcat);
    }
  }, [loadingScenes, loadingPlaylists]);

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title="Featured Playlists and Scenes"
      modifier="music-search"
    >
      <LoadingProvider loading={loadingScenes || loadingPlaylists}>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 16,
            ...(rdd?.isMobileOnly && { marginLeft: 20 }),
          }}
        />
        {rdd?.isMobileOnly ? (
          <div className="list-results-mobile">
            {featuredPlayables?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/qsys/${id}/search/playlists/${item.id}`
                  : `/qsys/${id}/search/all/scene/${item.id}`;
              return (
                <ResultItem
                  color={stc(item.id)}
                  image={item?.cover}
                  key={item.id}
                  to={to}
                  title={item?.name}
                  description={item?.description}
                  hasImage
                  isMobile
                />
              );
            })}
          </div>
        ) : (
          <div
            className="list-results"
            style={{
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            {featuredPlayables?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/qsys/${id}/search/playlists/${item.id}`
                  : `/qsys/${id}/search/all/scene/${item.id}`;
              return (
                <CardLink
                  key={item.id}
                  to={to}
                  title={item.name}
                  cover={item.cover}
                  triggerPlayNow={{
                    playlistId: item.id,
                    devicePlayType: item?.type?.toUpperCase(),
                  }}
                  hasButton
                />
              );
            })}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default FeaturedPage;

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { NOTIFY_SUPPORT } from '../mutations/notifySupport';

export default function useNotifySupport() {
  const [notifySupport, { loading }] = useMutation(NOTIFY_SUPPORT);

  return { notifySupport, loading };
}

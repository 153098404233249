import _ from 'lodash';

const colors = _.shuffle([
  '#3B46AB',
  '#3B7DAB',
  // '#670BCE',
  '#0B72CE',
  '#E96187',
  '#E961CB',
  '#C361E9',
  '#7F61E9',
  '#6187E9',
  '#FFBF00',
  '#FF4000',
  '#FF0040',
  '#0B11CE',
  '#3BAB69',
  '#0BCEC9',
  '#0BCE69',
]);

export default function getRandomColor(num) {
  return colors[num];
}

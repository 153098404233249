/**
 * External dependencies
 */
import React, { useCallback, useState } from 'react';
import useLogin from '../../domain/auth/hooks/use-login';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import useAuth from '../../domain/auth/hooks/use-auth';
import { notify } from '../notify-toast';
import { Security } from '@okta/okta-react';
import { useHistory, useLocation } from 'react-router-dom';

const useQuery = () => new window.URLSearchParams(useLocation().search);

const OktaWrapper = ({ children, oktaAuth }) => {
  const history = useHistory();
  let query = useQuery();
  const { loginSuccess, newUserRegisteredHubspotEmail } = useAuth();
  const [loginOkta] = useLogin('okta');
  const [oktaClientId] = useState(
    query.get('clientId') || localStorage.getItem('oktaClientId')
  );
  const [iss] = useState(
    query.get('iss') || localStorage.getItem('oktaDomain')
  );

  oktaClientId && localStorage.setItem('oktaClientId', oktaClientId);
  iss && localStorage.setItem('oktaDomain', iss);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      if (oktaClientId && iss && oktaAuth) {
        const oktaToken = _oktaAuth.getAccessToken();
        loginOkta({
          variables: {
            accessToken: oktaToken,
          },
        }).then((res) => {
          const isNewUser = res.data.handleOkta?.newUser;
          const user = res.data?.handleOkta?.user;
          const token = res.data?.handleOkta?.token;
          const householdId = null;

          if (isNewUser) {
            newUserRegisteredHubspotEmail({
              firstname: user?.profile?.name,
              email: user?.emails[0]?.address,
              company: '',
              servicetype: 'Curator',
              loginmethod: 'okta',
              householdId,
            });
            notify();
          }
          loginSuccess({
            token,
            user,
            provider: 'okta',
          });
          history.replace(
            toRelativeUrl(originalUri || '/', window.location.origin)
          );
        });
      }
    },
    [OktaAuth]
  );

  return oktaAuth ? (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  ) : (
    <> {children} </>
  );
};

export default OktaWrapper;

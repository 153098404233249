/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';

/** 
 * Internal dependencies
 */

import { ReactComponent as LogoSVG } from 'assets/svg-icons/logo.svg';

const Logo = () =>  {
	return (
		<Link to="/">
			<LogoSVG />

			<span className="hidden">Curator</span>
		</Link>
	)
}

export default Logo;

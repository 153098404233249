/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Playlist from 'blocks/playlist/playlist';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import Page404 from 'views/404/404';
import getRandomArtwork from 'helpers/get-random-artwork';
import useAudioBacklog from '../hooks/use-audio-backlog';

import getRandomColor from '../../../helpers/get-random-color';
import _ from 'lodash';

import { Howl } from 'howler';
import { SongItem } from './song-item';
import * as rdd from 'react-device-detect';
import PlaylistMobile from '../../../blocks/playlist/playlist-mobile';

const CuratedMixPage = ({
  match: {
    params: { id },
  },
}) => {
  const { device, loading } = useSingleDevice(id);
  const { audioBacklog, loading: backlogLoading } = useAudioBacklog(id);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(-1);

  const [defaultArtwork, setDefaultArtwork] = useState('');

  useEffect(() => {
    setDefaultArtwork(getRandomArtwork());
  }, [device?.id]);

  useEffect(() => {
    if (currentIndex > 0) {
      playPreview(currentIndex);
    }
  }, [currentIndex]);

  if (!device && !loading && !backlogLoading) {
    return <Page404 />;
  }

  const allSongs = audioBacklog?.songLogs?.map((item) => item.song) || [];

  let sound = window.Howler._howls[0] || null;

  const stopSound = () => {
    sound?.stop();
    sound?.unload();
    window.Howler.unload();
    sound = null;
  };

  const playPreview = (index) => {
    const song = allSongs[index];

    if (sound !== null && song?.preview?.url !== sound._src) {
      stopSound();
    }

    if (sound === null || !sound.playing()) {
      startNewHowl(index);
    }

    if (sound !== null && sound.playing()) {
      setPlayingIndex(-1);
      stopSound();
    }
  };

  const startNewHowl = (index) => {
    stopSound();
    const song = allSongs[index];
    sound = new Howl({
      src: [song?.preview?.url || ''],
      onplay: () => {
        setPlayingIndex(index);
      },
      onend: () => {
        stopSound();
        if (index < allSongs.length - 1) {
          startNewHowl(index + 1);
        } else {
          setCurrentIndex(index + 1);
        }
      },
      html5: true,
    });
    sound?.play();
  };

  const description = !backlogLoading
    ? device?.currentPlaylist?.id
      ? device?.currentPlaylist?.isPlaylist
        ? `Here are the last ten songs played.`
        : `Here are the last ten songs played.`
      : 'Here are the last ten songs played.'
    : null;
  const title = !backlogLoading
    ? device?.currentPlaylist?.id && device?.remotePlaylistMode
      ? device?.currentPlaylist?.isPlaylist && device?.remotePlaylistMode
        ? `You are listening to Playlist: ${device?.currentPlaylist?.name}.`
        : `You are listening to Scene: ${device?.currentPlaylist?.name}.`
      : 'You are listening to a Curated Mix comprised of several playlists.'
    : null;

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={!loading ? device?.name : ''}
      title={'Currently Playing '}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="current-playlist"
    >
      {rdd?.isMobileOnly ? (
        <PlaylistMobile
          description={description}
          title={title}
          cover={device?.currentPlaylist?.cover || defaultArtwork}
        >
          {allSongs
            .filter((song) => song?.id)
            .map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
        </PlaylistMobile>
      ) : (
        <Playlist
          subtitle={!backlogLoading ? '' : null}
          title={title}
          description={description}
          color={getRandomColor(_.random(14))}
          cover={device?.currentPlaylist?.cover || defaultArtwork}
        >
          {allSongs.map((song, index) => (
            <SongItem
              key={song.id}
              isPlaying={playingIndex === index}
              song={song}
              onClick={() => playPreview(index)}
              id={index}
            />
          ))}
        </Playlist>
      )}
    </DashboardLayout>
  );
};

export default CuratedMixPage;

export default function getFormattedDate(date) {
	// Handle weekday strings
	let weekday = new Array(7);
	weekday[0] = "Sun";
	weekday[1] = "Mon";
	weekday[2] = "Tue";
	weekday[3] = "Wed";
	weekday[4] = "Thu";
	weekday[5] = "Fri";
	weekday[6] = "Sat";

	// Handle month strings
	let monthStrings = new Array(7);
	monthStrings[0] = "January";
	monthStrings[1] = "February";
	monthStrings[2] = "March";
	monthStrings[3] = "April";
	monthStrings[4] = "May";
	monthStrings[5] = "June";
	monthStrings[6] = "July";
	monthStrings[7] = "August";
	monthStrings[8] = "September";
	monthStrings[9] = "October";
	monthStrings[10] = "November";
	monthStrings[11] = "December";

	//Handle 24Hour to 12Hour
	const dt = new Date(date);
	let hours = dt.getHours() ; // gives the value in 24 hours format
	const AmOrPm = hours >= 12 ? 'PM' : 'AM';
	hours = (hours % 12) || 12;
	const minutes = dt.getMinutes();
	const finalTime = hours + ':' + minutes + ' ' + AmOrPm; 

	const day = weekday[date.getDay()];
	const dayOfMonth = date.getDate();
	const month = monthStrings[date.getMonth()];

	return `${day} ${dayOfMonth}th ${month} at ${finalTime}`;
}

/**
 * External dependencies
 */
import { useSubscription } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SUBSCRIBE_DEVICES } from 'domain/devices/subscriptions/subscribe-devices';

export default function useDevicesSubscription(options) {
  const { data, loading } = useSubscription(SUBSCRIBE_DEVICES, {
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    variables: {
      token: options?.userToken || '',
      updatedIds: options?.updatedIds || null,
      onSubscriptionData: (data) => console.log('new data', data),
      updatedFields: [
        'isPlaying',
        'isOnline',
        'clientId',
        'name',
        // 'deviceStatus',
        'description',
        'currentSongId',
      ],
    },
  });

  return {
    devicesSubscription: data,
    loading,
  };
}

/**
 * External dependencies
 */
import React, { memo, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useLocation } from 'react-router-dom';

/**
 * Internal dependencies
 */
import useLogin from 'domain/auth/hooks/use-login';
import useAuth from 'domain/auth/hooks/use-auth';
import Button from 'components/button/button';
import { toast } from 'react-toastify';
import { ReactComponent as IcoFacebook } from 'assets/svg-icons/ico-facebook.svg';

function useQuery() {
  const URLSearchParams = window.URLSearchParams;
  return new URLSearchParams(useLocation().search);
}

const ButtonFacebookLogin = () => {
  let query = useQuery();
  const [loaded, setLoaded] = useState(true);
  const [loginFacebook, { loading, data }] = useLogin('facebook');
  const {
    loginSuccess,
    setLoginLoading,
    newUserRegisteredHubspotEmail,
    setSonosFlow,
  } = useAuth();

  const redirectUrl = query.get('redirectUrl');
  const linkCode = query.get('linkCode');
  const jwtToken = query.get('jwtToken');
  // check if trakdesk_cid cookie is available
  const trackdeskCid = document.cookie
    .split('; ')
    .find((row) => row.startsWith('trakdesk_cid='))
    ?.split('=')[1];

  const handleButtonClick = (onClick = () => {}) => {
    setLoginLoading(true);

    onClick();
  };

  const notify = () =>
    toast.dark('Your account was successfully created', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      bodyStyle: { color: '#fff' },
      style: { backgroundColor: '#670BCE' },
    });

  useEffect(() => {
    setLoginLoading(loading);
  }, [loading, setLoginLoading]);

  const handleFacebookLoginSuccess = (data) => {
    const facebookData = {
      accessToken: data?.accessToken,
      //applicationID: process.env.REACT_APP_FACEBOOK_API_KEY,
      expirationTime: data?.expiresIn,
      userID: data?.userID,
    };

    loginFacebook({
      variables: {
        facebookData,
        serviceType: query.get('loginType'),
        jwtToken: (redirectUrl && linkCode && jwtToken) || '',
        menuId: query.get('exId'),
        ...(trackdeskCid && { trackdeskCid }),
      },
    }).then((res) => {
      const isNewUser = res.data.handleFacebook?.newUser;
      const user = res.data.handleFacebook?.user;
      const householdId =
        res.data.handleFacebook?.sonosAuthObj?.householdId || null;

      if (isNewUser) {
        const servicetype = [];

        if (query.get('loginType') === 'sonos') {
          servicetype.push('Sonos');
          setSonosFlow(true);
        } else {
          servicetype.push('Sonos');
          servicetype.push('Curator');
        }
        newUserRegisteredHubspotEmail({
          firstname: user?.profile?.name,
          email: user?.emails[0]?.address,
          company: '',
          servicetype,
          loginmethod: 'facebook',
          householdId,
        });
        notify();
      }
    });
  };

  useEffect(() => {
    if (data && loaded) {
      setLoaded(false);
      loginSuccess({
        token: data?.handleFacebook?.token,
        user: data?.handleFacebook?.user,
        provider: 'facebook',
        sonosAuthObj: data?.handleFacebook?.sonosAuthObj,
        redirectUrl,
        linkCode,
      });
    }
  }, [data, loginSuccess]);

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_API_KEY}
      callback={handleFacebookLoginSuccess}
      isMobile={false}
      render={(renderProps) => (
        <Button
          modifier="icon"
          onClick={() => handleButtonClick(renderProps.onClick)}
          disabled={renderProps.disabled}
        >
          <IcoFacebook />

          {loading ? 'Loading...' : 'Facebook'}
        </Button>
      )}
    />
  );
};

export default memo(ButtonFacebookLogin);

import React from 'react';

import { ReactComponent as Eq } from '../../assets/svg-icons/eq.svg';
import { ReactComponent as OnSchedule } from '../../assets/svg-icons/onschedule.svg';

import Button from '../button/button';
import { useHistory } from 'react-router-dom';
import * as rdd from 'react-device-detect';

const CurrentlyPlayingCardSonos = ({ device }) => {
  const history = useHistory();

  return (
    <div
      style={{
        height: 250,
        backgroundColor: device?.remotePlaylistMode ? '#3BABA1' : '#E96187',
        borderRadius: 13,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: rdd?.isMobileOnly ? 13 : 0,
        marginRight: rdd?.isMobileOnly ? 13 : 0,
      }}
      //   onClick={onClickCard}
    >
      <div
        style={{
          flex: 1,
          padding: 25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Eq />
        <h3 style={{ fontSize: 16, marginTop: -30 }}>
          Current {device?.currentMixType}:
        </h3>
        <h1 style={{ fontSize: 23, marginTop: -30 }}>
          {device?.currentMixName}
        </h1>
        <Button
          modifier="white2"
          style={{ width: 210 }}
          onClick={() => history.push(`/sonos/${device?.id}`)}
        >
          Choose Music
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!rdd?.isMobileOnly && <OnSchedule />}
      </div>
    </div>
  );
};

export default CurrentlyPlayingCardSonos;

/**
 * External dependencies
 */
import React, { memo } from 'react';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import useAuth from 'domain/auth/hooks/use-auth';

import { ReactComponent as IcoLogout } from 'assets/svg-icons/ico-logout.svg';

const LogoutButton = () => {
  const { logoutSuccess } = useAuth();

  return (
    <Button as="button" type="button" onClick={logoutSuccess}>
      <IcoLogout />
      <span className="dashboard__sidebar_text">Log out</span>
    </Button>
  );
};

export default memo(LogoutButton);

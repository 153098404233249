/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import SearchSection from 'components/search-section/search-section';
import AllVibes from './vibes/all-vibes';

import AllIntensities from './intensities/all-intensities';
import AllGenres from './genres/all-genres';
import AllSceneCategories from './scenes/all-scene-categories';
import capitalize from 'helpers/capitalize';

const MusicSearchCollection = ({
  match: {
    params: { id, collectionId },
  },
}) => {
  const renderItems = () => {
    if (collectionId === 'vibe')
      return <AllVibes deviceId={id} collectionId={collectionId} />;
    if (collectionId === 'scenes') return <AllSceneCategories id={id} />;
    if (collectionId === 'genres') return <AllGenres id={id} />;
    if (collectionId === 'intensities') return <AllIntensities id={id} />;
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={capitalize(collectionId)}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="music-search"
    >
      <SearchSection deviceId={id}>{renderItems()}</SearchSection>
    </DashboardLayout>
  );
};

export default MusicSearchCollection;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SIGNIN_USER = gql`
  mutation signinUserMutation(
    $serviceType: String
    $emailData: AUTH_PROVIDER_EMAIL
    $jwtToken: String
  ) {
    signinUser(
      serviceType: $serviceType
      emailData: $emailData
      jwtToken: $jwtToken
    ) {
      token
      sonosAuthObj {
        householdId
        authToken
        nickname
        tokenExpiration
        message
      }
      user {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        favouriteDevices
        featuredPlayables
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        musicProfile {
          category
          subCategory
          genres
          avoidGenres
          artists
          vibe
          explicit
        }
        socialProfile {
          twitter
          linkedIn
          facebook
          spotify
        }
        emails {
          address
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        serviceType
        defaultServiceType
        companyName
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
          qsysSubscriptionPlan {
            id
          }
        }
        audioClient {
          noVolumeBar
          noPlayPause
        }
        qsys {
          pin
        }
      }
    }
  }
`;

export const SIGNIN_USER_GOOGLE = gql`
  mutation handleGoogleMutation(
    $googleCredential: String!
    $serviceType: String
    $jwtToken: String
    $menuId: String
    $trackdeskCid: String
  ) {
    handleGoogle(
      googleCredential: $googleCredential
      serviceType: $serviceType
      jwtToken: $jwtToken
      menuId: $menuId
      trackdeskCid: $trackdeskCid
    ) {
      token
      sonosAuthObj {
        householdId
        authToken
        nickname
        tokenExpiration
        message
      }
      user {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        featuredPlayables
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        musicProfile {
          category
          subCategory
          genres
          avoidGenres
          artists
          vibe
          explicit
        }
        socialProfile {
          twitter
          linkedIn
          facebook
          spotify
        }
        emails {
          address
        }
        serviceType
        defaultServiceType
        companyName
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
        }
        audioClient {
          noVolumeBar
          noPlayPause
        }
        qsys {
          pin
          noOfZones
        }
      }
      newUser
    }
  }
`;

export const SIGNIN_USER_FACEBOOK = gql`
  mutation handleFacebookMutation(
    $facebookData: FacebookData!
    $serviceType: String
    $jwtToken: String
    $menuId: String
    $trackdeskCid: String
  ) {
    handleFacebook(
      facebookData: $facebookData
      serviceType: $serviceType
      jwtToken: $jwtToken
      menuId: $menuId
      trackdeskCid: $trackdeskCid
    ) {
      token
      sonosAuthObj {
        householdId
        authToken
        nickname
        tokenExpiration
        message
      }
      user {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        featuredPlayables
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        musicProfile {
          category
          subCategory
          genres
          avoidGenres
          artists
          vibe
          explicit
        }
        socialProfile {
          twitter
          linkedIn
          facebook
          spotify
        }
        emails {
          address
        }
        serviceType
        defaultServiceType
        companyName
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
          qsysSubscriptionPlan {
            id
          }
        }
        audioClient {
          noVolumeBar
          noPlayPause
        }
        qsys {
          pin
          noOfZones
        }
      }
      newUser
    }
  }
`;

export const SIGNIN_USER_APPLE = gql`
  mutation handleAppleMutation(
    $appleToken: String!
    $serviceType: String
    $jwtToken: String
    $menuId: String
    $trackdeskCid: String
  ) {
    handleApple(
      token: $appleToken
      serviceType: $serviceType
      jwtToken: $jwtToken
      menuId: $menuId
      trackdeskCid: $trackdeskCid
    ) {
      token
      sonosAuthObj {
        householdId
        authToken
        nickname
        tokenExpiration
        message
      }
      user {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        featuredPlayables
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        musicProfile {
          category
          subCategory
          genres
          avoidGenres
          artists
          vibe
          explicit
        }
        socialProfile {
          twitter
          linkedIn
          facebook
          spotify
        }
        emails {
          address
        }

        serviceType
        defaultServiceType
        companyName
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
          qsysSubscriptionPlan {
            id
          }
        }
        audioClient {
          noVolumeBar
          noPlayPause
        }
        qsys {
          pin
          noOfZones
        }
      }
      newUser
    }
  }
`;

export const SIGNIN_USER_OKTA = gql`
  mutation handleOktaMutation($accessToken: String!) {
    handleOkta(accessToken: $accessToken) {
      token
      sonosAuthObj {
        householdId
        authToken
        nickname
        tokenExpiration
        message
      }
      user {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        featuredPlayables
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        emails {
          address
        }

        serviceType
        defaultServiceType
        companyName
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
          qsysSubscriptionPlan {
            id
          }
        }
        audioClient {
          noVolumeBar
          noPlayPause
        }
      }
      newUser
    }
  }
`;

export const SONOS_AUTH_TOKEN = gql`
  mutation getSonosAuthToken($jwtToken: String!) {
    getSonosAuthToken(jwtToken: $jwtToken) {
      sonosAuthObj {
        householdId
        authToken
        nickname
        tokenExpiration
        message
      }
    }
  }
`;

import React, { useState, useEffect, useContext } from 'react';
import { toast, Slide } from 'react-toastify';
import * as rdd from 'react-device-detect';
import Marquee from 'react-fast-marquee';
import { useRouteMatch, useHistory } from 'react-router-dom';

import useSingleDevice from 'domain/devices/hooks/use-single-device';
import useDeviceSubscription from 'domain/devices/hooks/use-device-subscription';
import useUpdateDeviceNextSong from '../domain/dashboard/hooks/use-update-device-next-song';

import ProgressRangeFooter from 'components/volume-slider/volume-slider-footer';

import { ReactComponent as IcoDevicesColor } from 'assets/svg-icons/ico-devices-color.svg';
import useToggleManuallyPaused from '../domain/devices/hooks/use-toggle-manually-paused';
import getRandomArtwork from '../helpers/get-random-artwork';

import PauseSvg from 'assets/svgComponents/PauseSvg';
import DisplaySvg from 'assets/svgComponents/DisplaySvg';
import SkipSvg from 'assets/svgComponents/SkipSvg';
import XSVG from 'assets/svgComponents/XSVG';
import VolumeSvg from 'assets/svgComponents/VolumeSvg';
import PlaySvg from 'assets/svgComponents/PlaySvg';
import CopySvg from 'assets/svgComponents/CopySvg';
import ShareSvg from 'assets/svgComponents/ShareSvg';
import useAuth from 'domain/auth/hooks/use-auth';

import useGetDisplayCode from 'domain/devices/hooks/use-get-display-code';
import AuthContext from '../domain/auth/contexts/auth-context/auth-context';

const customId = 'prevent-duplicate';

const NowPlayingLayout = ({ children }) => {
  const {
    path,
    params: { id },
  } = useRouteMatch();
  const { user } = useContext(AuthContext);
  const { currentDevice } = useAuth();
  const { getDisplayCode, gotDisplayCode } = useGetDisplayCode();
  const history = useHistory();
  const { device, loading, refetch } = useSingleDevice(id);
  const { updateDeviceNextSong } = useUpdateDeviceNextSong();
  const { toggleManuallyPaused } = useToggleManuallyPaused();
  const [randomArtwork, setRandomArtwork] = useState();
  const [displayUrl, setDisplayUrl] = useState('...');
  const [copied, setCopied] = useState(false);

  const [displayMode, setDisplayMode] = useState(false);

  const volumeBar =
    user?.audioClient?.noVolumeBar && user?.rolesConsole?.roleAudio === 5;
  const playPauseButton =
    user?.audioClient?.noPlayPause && user?.rolesConsole?.roleAudio === 5;

  useEffect(() => {
    setRandomArtwork(getRandomArtwork());
  }, []);

  useEffect(() => {
    if (gotDisplayCode?.getDisplayCode) {
      setDisplayUrl(gotDisplayCode.getDisplayCode);
    }
  }, [gotDisplayCode]);

  const { deviceData } = useDeviceSubscription({
    deviceId: id,
    userToken: localStorage.getItem('sessionToken'),
  });

  useEffect(() => {
    if (
      deviceData &&
      device &&
      deviceData.AudioDeviceSubscription.node.currentSongId !==
        device?.currentSong?.id
    ) {
      // call refetch if new subscription data comes in
      refetch();
    }
  }, [deviceData, refetch]);

  const isPlaylistPlaying = device?.currentPlaylist?.isPlaylist;
  const isScenePlaying = device?.currentPlaylist?.isScene;
  const remotePlaylistMode = device?.remotePlaylistMode;

  const currentlyPlaying =
    (isPlaylistPlaying || isScenePlaying) && remotePlaylistMode
      ? `${isPlaylistPlaying ? 'Playlist' : 'Scene'}: ${
          device?.currentPlaylist?.name || ''
        }`
      : 'Curated Mix';

  const notifySkipLimit = () =>
    toast.warning('Sorry, you’re allowed only 3 skips per hour.', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      toastId: customId,
      style: { backgroundColor: '#E96187' },
      bodyStyle: { fontSize: 16, color: '#fff' },
      transition: Slide,
    });

  const notifySkipSong = () =>
    toast.info('Song skipped', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      toastId: customId,
      style: { backgroundColor: '#670BCE' },
      bodyStyle: { fontSize: 16, color: '#fff' },
      transition: Slide,
    });

  const handleNextClick = () => {
    if (device?.skipDisabled) {
      notifySkipLimit();
      return;
    }
    if (id) {
      updateDeviceNextSong({
        variables: {
          deviceId: id,
        },
      }).then(() => {
        notifySkipSong();
      });
    }
  };

  const handlePlayPause = () => {
    toggleManuallyPaused({
      variables: { deviceId: id, manuallyPaused: !device?.manuallyPaused },
    });
  };

  const copyToClipboard = (input) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(input).then(
        () => {
          console.log('Copied to clipboard successfully.');
        },
        (err) => {
          console.log('Failed to copy the text to clipboard.', err);
        }
      );
    } else if (window.clipboardData) {
      window.clipboardData.setData('Text', input);
    }
    setCopied(true);
  };

  const handleDisplayMode = () => {
    setDisplayMode(true);
    getDisplayCode({ variables: { deviceId: currentDevice } });
  };

  if (!loading && !device) {
    return <>{children}</>;
  }

  const renderSwitch = (fetchingData, isNotOnline) => {
    if (fetchingData) {
      {
        return 'Loading...';
      }
    }
    if (isNotOnline) {
      return 'Offline';
    } else {
      return 'Paused';
    }
  };

  const manuallyPaused =
    device?.isOnline && device?.isPlaying && !device?.manuallyPaused;

  return (
    <>
      {id &&
        id !== 'sonos' &&
        (path !== '/:id/dashboard' ||
          history.location.pathname.includes('schedule') ||
          history.location.pathname.includes('information')) &&
        (!rdd?.isMobileOnly ? (
          <div
            className="now-playing"
            style={{
              backgroundColor:
                path.includes('highlights') ||
                (path.includes('dashboard') &&
                  !history.location.pathname.includes('schedule') &&
                  !history.location.pathname.includes('information'))
                  ? '#000'
                  : '#393b5a',
            }}
          >
            {!displayMode && (
              <img
                alt=""
                src={
                  manuallyPaused
                    ? device?.currentSong?.cover || randomArtwork
                    : randomArtwork
                }
              />
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              {displayMode ? (
                <div className="now-playing__metadata">
                  <p style={{ marginBottom: 5 }}>
                    Open your browser to https://display.activaire.com and enter
                    your pairing code
                  </p>
                  <strong style={{ fontSize: 20 }}>
                    {displayUrl?.toUpperCase()}
                  </strong>
                </div>
              ) : (
                <div
                  className="now-playing__metadata"
                  onClick={() => history.push(`/${id}/dashboard`)}
                >
                  {manuallyPaused ? (
                    <>
                      <h3>{device?.currentSong.title}</h3>
                      <p>{currentlyPlaying}</p>
                    </>
                  ) : (
                    <h3>{renderSwitch(loading, !device?.isOnline)}</h3>
                  )}
                  <p
                    style={{
                      display: 'flex',
                      color: '#3BABA1',
                      marginTop: 10,
                    }}
                  >
                    <IcoDevicesColor
                      style={{ height: 16, width: 16, marginRight: 5 }}
                    />
                    {device?.name}
                  </p>
                </div>
              )}

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {displayMode ? (
                  <>
                    {copied && (
                      <p
                        style={{
                          marginRight: 32,
                        }}
                      >
                        Copied!
                      </p>
                    )}
                    <CopySvg
                      onClick={() =>
                        copyToClipboard(
                          `https://display.activaire.com/${displayUrl}`
                        )
                      }
                    />
                    <ShareSvg
                      onClick={() =>
                        window
                          .open(
                            `https://display.activaire.com/${displayUrl}`,
                            '_blank'
                          )
                          .focus()
                      }
                    />
                  </>
                ) : (
                  <>
                    {!volumeBar && (
                      <>
                        <VolumeSvg />

                        <ProgressRangeFooter
                          deviceId={id}
                          volume={device?.remoteVolume}
                        />
                      </>
                    )}

                    {!playPauseButton &&
                      (device?.manuallyPaused ? (
                        <PlaySvg onClick={handlePlayPause} />
                      ) : (
                        <PauseSvg onClick={handlePlayPause} />
                      ))}

                    <SkipSvg onClick={handleNextClick} />
                  </>
                )}

                {displayMode ? (
                  <XSVG onClick={() => setDisplayMode(false)} />
                ) : (
                  <DisplaySvg onClick={() => handleDisplayMode()} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() => history.push(`/${id}/dashboard`)}
            style={{
              position: 'fixed',
              bottom: 70,
              backgroundColor: '#393B5A',
              zIndex: 9999,
              height: 46,
              margin: 10,
              right: 0,
              left: 0,
              borderRadius: 3,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              alt=""
              src={
                manuallyPaused
                  ? device?.currentSong?.cover || randomArtwork
                  : randomArtwork
              }
              style={{ height: 30, width: 30, borderRadius: 3, marginLeft: 7 }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 8,
                width: '80%',
              }}
            >
              {manuallyPaused ? (
                <Marquee gradient={false} speed={35}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <h3
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {device?.currentSong.title}
                    </h3>
                    &nbsp; • &nbsp;
                    <p
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {currentlyPlaying}
                    </p>
                  </div>
                </Marquee>
              ) : (
                <h3 style={{ fontSize: 10 }}>
                  {renderSwitch(loading, !device?.isOnline)}
                </h3>
              )}
              <p
                style={{
                  display: 'flex',
                  color: '#3BABA1',
                  alignItems: 'center',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                <IcoDevicesColor
                  style={{ height: 10, width: 8, marginRight: 5 }}
                />
                {device?.name}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20%',
              }}
            >
              {device?.manuallyPaused ? (
                <PlaySvg onClick={handlePlayPause} style={{ margin: '0px' }} />
              ) : (
                <PauseSvg onClick={handlePlayPause} style={{ margin: '0px' }} />
              )}
            </div>
          </div>
        ))}
      {children}
    </>
  );
};

export default NowPlayingLayout;

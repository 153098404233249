/**
 * External dependencies
 */
import React from 'react';
import HeaderAuth from 'blocks/header/header-auth';
import FooterAuth from 'blocks/footer/footer-auth';

const AuthLayout = ({ children, onPressSignUp, hideSignup, isSonos }) => {
  return (
    <>
      <HeaderAuth
        onPressSignUp={onPressSignUp}
        hideSignup={hideSignup}
        isSonos={isSonos}
      />

      <main className="main">
        <div>{children}</div>
      </main>
      <FooterAuth />
    </>
  );
};

export default AuthLayout;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_SCHEDULE = gql`
  query allScheduleQuery(
    $allScheduleFirst: Int
    $allScheduleAfter: String
    $allScheduleFilter: ScheduleFilter
    $allScheduleOrder: ScheduleOrderBy
    $deviceId: ID
  ) {
    viewer {
      allSchedule(
        first: $allScheduleFirst
        after: $allScheduleAfter
        filter: $allScheduleFilter
        orderBy: $allScheduleOrder
        deviceId: $deviceId
      ) {
        edges {
          cursor
          node {
            id
            name
            startDate
            endDate
            size
            injectable
            playingPlaylists {
              playlistId
              description
              name
              days
              startTime
              endTime
              startTimeSeconds
            }
          }
        }
      }
    }
  }
`;

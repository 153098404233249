export default `ACTIVAIRE CURATOR for Q-Sys 
Terms & Conditions of Use

Welcome to Activaire Curator!
Activaire Curator is a music service for business and commercial applications only and is not intended for personal use.

Before registering or otherwise using Activaire Curator ("the Service" or "The Activaire Curator Service"), you must read, understand, and agree to these "Terms & Conditions of Use" and any other applicable terms named here (collectively, the "Agreement"). This Agreement is between you ("You" or "User") and Activaire, LLC. (hereinafter referred to as "Activaire", "we", "Us" or "our"). 

Terms of Use

Authorization 
By registering for the Service, it is understood that you are 18 years of age or older, that you have the right and authority to accept this Agreement legally, and that you or your company, if applicable, are bound to these conditions. If you are unwilling or unable to accept these terms, you must refrain from purchasing, downloading, registering, or otherwise using the Service. If you violate any portion of this Agreement, we reserve the right to cancel your Account or block access to your Account without notice.

2. The Service and Content
The Activaire Service is a subscription service that provides you with access to musical works (defined below as "Content") for passive listening in a business and commercial setting (a "Site" or "Site of Use"). "Content" means the master sound recordings and the underlying musical compositions, which are strictly delivered for use as background music in business and commercial settings only.  

Activaire will maintain licenses for the Content in connection with your use of the Service for its intended use only as incidental background music at a Site fully and only in the regions of the United States, Canada, Australia, and New Zealand, pursuant hereto:

3. Use and Licensing
Activaire will authorize your use of the Service for its intended use as incidental background music at a Site only, pursuant hereto:
i. You expressly understand and agree that all Content is owned and controlled by third parties. Nothing in this Agreement grants you any ownership rights in or to any portion of the Content.  
ii. You shall not copy, reproduce, distribute, amend, modify, record, or download any of the Content, or use any of the Content, except for background music purposes as expressly permitted hereunder:
iii. If you hold an event or charge a fee to enter the Site of Use registered in connection with your Account, at your sole cost and expense, you shall obtain all licenses and pay all royalties legally required in connection with the performance of Content in connection therewith. 
iv. In the event that additional licensing is determined to be necessary for the use of the Service, you will be notified of any additional requirements, and the continued use of the Service is at your own risk.

Activaire will maintain licenses for the Content in connection with your use of the Service for its intended use only as incidental background music at a Site fully and only in the regions of the United States, Canada, Australia, and New Zealand. For all other regions, you may be required by law to maintain additional licensing for Performance, and must register the Site with one or more Performance Rights Organizations in your region.

4. Account Registration
To use the Service, you need to register an Activaire Curator Account (an "Account") with a unique username and password ("Access Credentials") and submit a valid credit card for billing according to the terms in this Agreement. It is your responsibility to guarantee the confidentiality and security of your Access Credentials. By registering, you agree that you are fully responsible for all activities carried out through your Account and that all communications received through your Account come from you.

5. Site Registration
You are entitled to one Site of Use per Account. To receive full licensing coverage, you must provide the address and the name of the business where the Content from the Account will be audible, as the Site of Use when you register an Account. This registration ensures that your place of business is reported to and licensed by Activaire with the regional collections agencies responsible for background music service royalties. If your Site of Use changes, you must update the address of the Site of Use in your account settings. Failure to do so may result in incomplete licensing coverage, at no fault to Activaire.

6. Service Start & Activation Renewal
This Agreement is effective upon your acceptance and will remain so unless ended in accordance with this Agreement hereunder. The Service will be activated and available for use when the email and billing details associated with your Account have been reviewed, verified, and approved. You will then receive a notification of account activation, and you have a free 7-day Trial period (the "Trial"). After seven (7) days, the Trial will automatically roll over into the billable Service (the "Service Start Date"). The service will remain active  for One (1) year and will renew automatically, unless terminated in accordance with this Agreement. You can cancel the Trial at any time before the Service Start Date; Trial termination for your Account will be effective immediately. 

7. Billing and Pricing
Billing becomes effective on the Service Start Date and will continue to be in effect unless your Service is terminated in accordance with this Agreement. Monthly billed subscriptions will be billed automatically on the same day as the Service Start Date every month. Annually billed subscriptions will be billed automatically on the same day as the Service Start Date every year. 
Activaire charges the monthly fee, per active stream. You may add billable streams at any point during the term. Pricing, rates, and subscription type descriptions can be found at: https://www.activaire.com/pricing 

8. Renewal & Cancellation
All service plans are set to renew automatically. If you don't want your plan to renew, you can turn off Auto-Renew in your Account Settings. If you turn off the Auto-Renew, your plan will still be available until the end of the current term. 
All active streams under an account will renew with the same date, regardless of the start date
When a plan auto-renews, the active pricing for your plan tier will automatically apply. If you cancel your subscription, you will be subject to current pricing changes if you wish to re-activate the Service for your Account.

9.Termination
This Agreement shall remain in effect until the Service is terminated either by you or by Activaire. You may terminate the Service subscription by turning off Auto-Renew in your account settings, or you can send an email support@activaire.com with the Account Credentials and the registered address for the Site of Use. If you terminate your Account, the Service will still be available until the end of the term for the Service tier that you sign up for, after which your Service will no longer be available, and your Account will no longer be active.
This Agreement will terminate immediately, without prior notice from Activaire, in the event that you fail to comply with any provision of this Agreement or become insolvent or have a new administrator appointed over your accounts.
Service termination will not limit any of Activaire's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.

10. Updates to the App and the Content
Activaire may, from time to time, provide content updates, enhancements, or improvements to the features/ functionality of the Activaire Curator app, which may include patches, bug fixes, updates, upgrades, and other modifications ("Updates"). Updates may modify or delete certain Content, features, and or functionalities of the app. You agree that Activaire has no obligation to (i) provide any Updates or (ii) continue to provide or enable any particular features and or functionalities of the app to you. You further agree that all Updates will be (i) deemed to constitute an integral part of the app and (ii) subject to the terms and conditions of this Agreement.

11. Modifications to Our App
Activaire reserves the right to modify, suspend or discontinue, temporarily or permanently, the Activaire Curator app or any service to which it connects, with or without notice and without liability to you.

13. Third-Party Services
We may display, include or make available third-party Content (including data, information, applications, and other products services) or provide links to third-party websites or services ("Third- Party Services"). You acknowledge and agree that Activaire shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Activaire does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.

14. Copyright Infringement Notice
If you are a copyright owner or such owner's agent and believe any material on our app constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including your address, telephone number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners; and (e)the statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.

15. Indemnification
You agree to indemnify and hold Activaire and its parents, subsidiaries, affiliates, officers, employees, agents, partners, and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the app; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.

16. Agreement to Arbitrate
This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Curator by Activaire's INTELLECTUAL PROPERTY RIGHTS. The term "dispute" means any dispute, action, or other controversies between you and Activaire concerning the Services or this Agreement, whether in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. "Dispute" will be given the broadest possible meaning allowable under law.

17. Notice of Dispute
In the event of a dispute, you or Activaire must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to: sales@activaire.com Activaire will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and Activaire will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or Activaire may commence arbitration.

18. Binding Arbitration
If you and Activaire don't resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration as described in this section. You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the party's rights or property pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.

19. No Warranties
The app is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Activaire, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the app, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Curator by Activaire provides no warranty or undertaking, and makes no representation of any kind that the app will meet your requirements, achieve any intended results, be compatible or work with any other software, apps, systems or services, operate without interruption, meet any performance or reliability standards or be error-free or that any errors or defects can or will be corrected.
Without limiting the foregoing, neither Activaire Curator nor any Activaire Curator's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the app, or the information, Content, and materials or products included thereon; (ii) that the app will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or Content provided through the app; or (iv) that the app, its servers, the Content, or emails sent from or on behalf of Curator by Activaire are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components. Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.

20. Limitation of Liability
Notwithstanding any damages that you might incur, the entire liability of Activaire Curator and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the app.
To the maximum extent permitted by applicable law, in no event shall Activaire Curator or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the app, third-party software and/or third-party hardware used with the app, or otherwise in connection with any provision of this Agreement), even if Curator by Activaire or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.

21. Severability
If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.
This Agreement, together with the Privacy Policy and any other legal notices published by Activaire on the Service, shall constitute the entire Agreement between you and Activaire concerning the Service. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and Activaire's failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. 
YOU AND Activaire AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.

21. Waiver
Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not affect a party's ability to exercise such right or require such performance at any time thereafter, nor shall be the waiver of a breach constitute waiver of any subsequent breach.
No failure to exercise and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.

22. Amendments to this Agreement
Activaire reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our app after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Activaire Curator.

23. Entire Agreement
The Agreement constitutes the entire Agreement between you and Activaire regarding your use of the Activaire Curator app and supersedes all prior and contemporaneous written or oral agreements between you and Activaire. You may be subject to additional terms and conditions that apply when you use or purchase other of Activaire's services, which Activaire will provide to you at the time of such use or purchase.

24. Updates to Our Terms
We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your Account.

25. Intellectual Property
The app and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned by Activaire, its licensors, or other providers of such material and are protected by the United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded, or distributed in any way, in whole or in part, without the express prior written permission of Activaire, unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the material is prohibited.

26. Submissions and Privacy
In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data, or proposals, including ideas for new or improved products, services, features, technologies, or promotions, you expressly agree that such submissions will automatically be treated as non-confidential and non-proprietary and will become the sole property of Activaire without any compensation or credit to you whatsoever. Activaire and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.

27. Promotions
Activaire may, from time to time, include contests, promotions, sweepstakes, or other activities ("Promotions") that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible for reading all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules. Additional terms and conditions may apply to purchases of goods or services on or through the Services, which terms and conditions are made a part of this Agreement by this reference.

28. Typographical Errors
In the event a product and/or Service is listed at an incorrect price or with incorrect information due to typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or Service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or another payment account in the amount of the charge.

29. Miscellaneous
If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an authorized representative of Activaire Curator. Activaire will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. Activaire Curator operates and controls the Activaire CuratorService from its offices in the United States. The Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those persons who choose to access the Activaire Curator Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms & Conditions (which include and incorporate the Activaire Curator Privacy Policy) contain the entire understanding and supersedes all prior understandings between you and Activaire concerning its subject matter and cannot be changed or modified by you. The section headings used in this Agreement are for convenience only and will not be given any legal import.

30. Disclaimer
Activaire is not responsible for any content, code or any other imprecision. Activaire does not provide warranties or guarantees. In no event shall Activaire be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or another sort, arising out of or in connection with the use of the Service or the contents of the Activaire Curator Service. Activaire reserves the right to make additions, deletions, or modifications to the contents of the Activaire Curator Service at any time without prior notice.
The Activaire Curator Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. Curator by Activaire is a distributor and not a publisher of the Content supplied by third parties; as such, Activaire exercises no editorial control over such Content and makes no warranty or representation as to the accuracy, reliability or currency of any information, Content, Service or merchandise provided through or accessible via the Activaire Curator Service. Without limiting the foregoing, Activaire specifically disclaims all warranties and representations in any content transmitted on or in connection with the Curator by Activaire Service or on sites that may appear as links on the Activaire Curator App, or in the products provided as a part of, or otherwise in connection with, the Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by Activaire or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, Activaire does not warrant that the Activaire Curator Service will be uninterrupted, uncorrupted, timely, or error-free.


Contact Us
Don't hesitate to contact us if you have any questions: support@activaire.com
`;

/**
 * External dependencies
 */
import { useSubscription } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SUBSCRIBE_USERS } from 'domain/music/subscriptions/subscribe-users';

export default function useUsersSubscription(options) {
  const { data, loading } = useSubscription(SUBSCRIBE_USERS, {
    variables: {
      token: options?.userToken || '',
      updatedId: options?.updatedId || '',
    },
    shouldResubscribe: true,
  });

  return {
    userSubscription: data,
    userSubscriptionLoading: loading,
  };
}

/**
 * External dependencies
 */
import React from 'react';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import MusicSearchBrowser from './music-search-browser';
import MusicSearchMobile from './music-search-mobile';

const MusicSearchPage = ({
  match: {
    params: { id, searchTerm },
  },
}) => {
  return (
    <>
      {rdd?.isMobileOnly ? (
        <MusicSearchMobile id={id} searchTerm={searchTerm} />
      ) : (
        <MusicSearchBrowser id={id} searchTerm={searchTerm} />
      )}
    </>
  );
};

export default MusicSearchPage;

/**
 * Internal dependencies
 */
import useAllAudioDevices from 'domain/devices/hooks/use-all-audio-devices';

export default function useSingleDevice(deviceId) {
  const { singleDevice, loading, error, refetch } = useAllAudioDevices({
    first: 1,
    filters: { _id_contains: deviceId },
    deviceId,
    singleDevice: true,
    // fetchPolicy: 'met',
    skip: !deviceId,
  });

  return {
    device: singleDevice,
    loading,
    error,
    refetch,
  };
}

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const NOTIFY_SUPPORT = gql`
  mutation notifySupportMutation(
    $type: String
    $email: String!
    $name: String
    $serviceType: [String]
  ) {
    notifySupport(
      type: $type
      email: $email
      name: $name
      serviceType: $serviceType
    )
  }
`;

/**
 * External dependencies
 */
import React, { memo } from 'react';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import useAuth from 'domain/auth/hooks/use-auth';

const LogoutButton = () => {
  const { logoutSuccess } = useAuth();

  return (
    <Button
      as="button"
      type="button"
      modifier="primary"
      onClick={logoutSuccess}
    >
      Log out
    </Button>
  );
};

export default memo(LogoutButton);

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { TRIGGER_QSYS_ACTION } from '../mutations/trigger-qsys-action';

/**
 * Internal dependencies
 */

export default function useTriggerQsysAction() {
  const [triggerQsysAction, { loading, data: device }] =
    useMutation(TRIGGER_QSYS_ACTION);

  return {
    triggerQsysAction,
    loading,
    device,
  };
}

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUserMutation(
    $name: String!
    $companyName: String!
    $serviceType: String
    $authProvider: AUTH_PROVIDER_EMAIL!
    $menuId: String
    $trackdeskCid: String
  ) {
    createUser(
      name: $name
      companyName: $companyName
      serviceType: $serviceType
      authProvider: $authProvider
      menuId: $menuId
      trackdeskCid: $trackdeskCid
    ) {
      node {
        id
      }
      newUser
    }
  }
`;

/**
 * External dependencies
 */
import React from 'react';
import cx from 'classnames';

const InfoCard = ({ image, reverse, color, styles, imgStyle, children }) => {
  return (
    <div
      className={cx('info-card', { 'info-card--reversed': reverse })}
      style={{
        backgroundColor: color,
        ...styles,
        position: 'relative',
      }}
    >
      <div
        className="letter"
        style={{
          backgroundImage: `url(${
            require('../../assets/svg-icons/curator-background.svg').default
          })`,
        }}
      ></div>
      <aside className="info-card__image">
        {image && <img src={image} alt="" style={imgStyle} />}
      </aside>

      <div className="info-card__content">{children}</div>
    </div>
  );
};

export default InfoCard;

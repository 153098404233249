/**
 * External dependencies
 */
import React from 'react';

const SystemDetailProfile = ({ icon, title, subtitle, info, iconShow }) => {
  return (
    <div className="system-detail-profile">
      {icon && (
        <div className="system-detail-profile__icon">{iconShow && icon}</div>
      )}

      <div className="system-detail-profile__content">
        <h4>{title}</h4>
        <h6>{subtitle}</h6>

        <h6 style={{ lineHeight: '18px' }}>{info}</h6>
      </div>
    </div>
  );
};

export default SystemDetailProfile;

/**
 * External dependencies
 */
import React, { useState } from 'react';
import stc from 'string-to-color';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import SearchSection from 'components/search-section/search-section';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useSearchPlaylists from '../hooks/use-search-playlists';
import capitalize from '../../../helpers/capitalize';

const MusicSearchPage = ({ id, sonosDeviceId, searchTerm }) => {
  const isSonosDevice = !!sonosDeviceId;
  const { searchedPlaylists, searchedScenes, loading } = useSearchPlaylists({
    text: searchTerm,
  });

  const [sections, setAllSections] = useState([
    {
      name: 'Scenes',
      expanded: false,
    },
    {
      name: 'Playlists',
      expanded: false,
    },
  ]);

  const handleViewAll = (section) => {
    let tempSections = [...sections];
    tempSections[section] = {
      ...tempSections[section],
      expanded: true,
    };
    setAllSections(tempSections);
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={`Search "${searchTerm}"`}
      modifier="music-search"
    >
      <LoadingProvider loading={loading}>
        {!searchedPlaylists?.length && !searchedScenes?.length && (
          <h2>Nothing found. Try something else</h2>
        )}
        {searchedPlaylists?.length > 0 && (
          <SearchSection
            title="Playlists"
            deviceId={id}
            collectionId="playlists"
            hasViewAll
            isInSearchSection={
              !sections[1].expanded && searchedPlaylists?.length > 8
            }
            onClick={() => handleViewAll(1)}
          >
            <div className="section-search-result__items">
              {!loading &&
                searchedPlaylists &&
                searchedPlaylists
                  .slice(0, sections[1].expanded ? searchedPlaylists.length : 8)
                  .map((playlist) => (
                    <CardLink
                      key={playlist.id}
                      triggerPlayNow={{
                        playlistId: playlist.id,
                        devicePlayType: 'PLAYLIST',
                      }}
                      to={
                        isSonosDevice
                          ? `/sonos/${sonosDeviceId}/search/playlists/${playlist.id}`
                          : `/${id}/search/playlists/${playlist.id}`
                      }
                      cover={playlist.cover}
                      title={playlist.name}
                      color={stc(playlist.name)}
                      // style={{ height: 230 }}
                      hasButton
                    />
                  ))}
            </div>
          </SearchSection>
        )}

        {searchedScenes?.length > 0 && (
          <SearchSection
            title="Scenes"
            deviceId={id}
            collectionId="scenes"
            hasViewAll
            isInSearchSection={
              !sections[0].expanded && searchedScenes?.length > 8
            }
            onClick={() => handleViewAll(0)}
          >
            <div className="section-search-result__items">
              {!loading &&
                searchedScenes
                  ?.slice(0, sections[0]?.expanded ? searchedScenes?.length : 8)
                  .map((scene) => (
                    <CardLink
                      key={scene?.id}
                      triggerPlayNow={{
                        playlistId: scene?.id,
                        devicePlayType: 'SCENE',
                      }}
                      to={
                        isSonosDevice
                          ? `/sonos/${sonosDeviceId}/search/all/scene/${scene?.id}`
                          : `/${id}/search/all/scene/${scene?.id}`
                      }
                      title={capitalize(scene?.name?.toLowerCase())}
                      cover={scene?.cover}
                      hasButton
                    />
                  ))}
            </div>
          </SearchSection>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default MusicSearchPage;

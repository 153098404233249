/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_DEVICE_NEXT_SONG = gql`
	mutation UpdateAudioDeviceNextSongMutation($deviceId: String!) {
		updateAudioDeviceNextSong(deviceId: $deviceId) {
    		id
		}
	}
`;

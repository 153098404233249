/**
 * External dependencies
 */
import React, { useState } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import Button from 'components/button/button';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useSearchPlaylists from 'domain/music/hooks/use-search-playlists';

const MusicSearchPage = ({ id, sonosDeviceId, searchTerm }) => {
  const [sections, setAllSections] = useState([
    {
      name: 'Playlists',
      expanded: false,
    },
    {
      name: 'Scenes',
      expanded: false,
    },
  ]);

  const isSonosDevice = !!sonosDeviceId;

  const { searchedPlaylists, searchedScenes, loading } = useSearchPlaylists({
    text: searchTerm,
  });

  const handleViewAll = (section) => {
    let tempSections = [...sections];
    tempSections[section] = {
      ...tempSections[section],
      expanded: true,
    };
    setAllSections(tempSections);
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={`‘${searchTerm}’`}
      modifier="music-search"
      style={{ marginTop: 10 }}
    >
      <LoadingProvider loading={loading}>
        {!searchedPlaylists?.length && !searchedScenes?.length && (
          <h2>Nothing found. Try something else</h2>
        )}

        {searchedPlaylists?.length > 0 && (
          <div
            style={{
              marginBottom: searchedScenes.length ? 30 : 135,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 20,
                padding: '0px 13px',
              }}
            >
              <h1 style={{ fontSize: 13 }}>Playlists</h1>
              <h1 style={{ fontSize: 13, fontWeight: 300, color: '#3BABA1' }}>
                {!sections[0].expanded && (
                  <Button onClick={() => handleViewAll(0)} modifier="as-link">
                    View all
                  </Button>
                )}
              </h1>
            </div>

            <div
              style={{
                display: 'flex',
                flex: 1,
                overflow: 'auto',
                flexDirection: sections[0].expanded ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              {searchedPlaylists
                ?.slice(0, sections[0].expanded ? searchedPlaylists.length : 4)
                .map((playlist, index) => {
                  return (
                    <CardLink
                      key={playlist?.id}
                      triggerPlayNow={{
                        playlistId: playlist?.id,
                        devicePlayType: 'PLAYLIST',
                      }}
                      to={
                        isSonosDevice
                          ? `/sonos/${sonosDeviceId}/search/playlists/${playlist?.id}`
                          : `/${id}/search/playlists/${playlist?.id}`
                      }
                      title={playlist?.name}
                      cover={playlist?.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: sections[0].expanded ? 0 : 13,
                        marginRight: !sections[0].expanded ? 7 : 0,
                        minWidth: 225,
                        marginBottom: 20,
                      }}
                      hasButton
                    />
                  );
                })}
            </div>
          </div>
        )}
        {searchedScenes?.length > 0 && (
          <div style={{ marginBottom: 135 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 20,
                padding: '0px 13px',
              }}
            >
              <h1 style={{ fontSize: 13 }}>Scenes</h1>
              <h1 style={{ fontSize: 13, fontWeight: 300, color: '#3BABA1' }}>
                {!sections[1].expanded && (
                  <Button onClick={() => handleViewAll(1)} modifier="as-link">
                    View all
                  </Button>
                )}
              </h1>
            </div>

            <div
              style={{
                display: 'flex',
                flex: 1,
                overflow: 'auto',
                flexDirection: sections[1].expanded ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              {searchedScenes
                ?.slice(0, sections[1].expanded ? searchedPlaylists.length : 4)
                .map((scene, index) => {
                  return (
                    <CardLink
                      key={scene?.id}
                      triggerPlayNow={{
                        playlistId: scene?.id,
                        devicePlayType: 'SCENE',
                      }}
                      to={
                        isSonosDevice
                          ? `/sonos/${isSonosDevice}/search/all/scene/${scene.id}`
                          : `/${id}/search/all/scene/${scene.id}`
                      }
                      title={scene?.name}
                      cover={scene?.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: sections[1].expanded ? 0 : 13,
                        marginRight: !sections[1].expanded ? 7 : 0,
                        minWidth: 225,
                        marginBottom: 20,
                      }}
                      hasButton
                    />
                  );
                })}
            </div>
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default MusicSearchPage;

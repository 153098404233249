import * as React from 'react';

const SvgComponent = ({ onClick }) => (
  <svg
    className="svg-stroke2"
    width={24}
    height={24}
    fill="rgba(255, 255, 255, 0.75)"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 32, strokeWidth: 0 }}
    onClick={onClick}
  >
    <title>{'tv'}</title>
    <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z" />
  </svg>
);

export default SvgComponent;

/**
 * External dependencies
 */
import React, { forwardRef, memo } from 'react';
import cx from 'classnames';

const Field = forwardRef(
  (
    {
      id,
      label,
      type = 'text',
      name,
      autocomplete,
      isTextarea,
      className,
      icon,
      isInvalid = false,
      errorMessage,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cx('field', className, {
          'is-invalid': isInvalid,
        })}
      >
        {label && (
          <label htmlFor={id} className="field__label">
            {label}
          </label>
        )}

        <div className="field__inner">
          {isTextarea ? (
            <textarea
              ref={ref}
              id={id}
              type={type}
              name={name}
              className="field__textarea"
              {...props}
            />
          ) : (
            <div style={{ position: 'relative' }}>
              {icon}
              <input
                ref={ref}
                id={id}
                type={type}
                name={name}
                autoComplete={autocomplete}
                className="field__input"
                {...props}
              />
            </div>
          )}
        </div>

        {isInvalid && (
          <span className="field__error-message">{errorMessage}</span>
        )}
      </div>
    );
  }
);

export default memo(Field);

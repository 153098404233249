import React from 'react';

function Search() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={{
        position: 'absolute',
        height: '100%',
        marginLeft: 15,
      }}
      className="icon-search"
    >
      <path
        stroke="#393B5A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
      ></path>
    </svg>
  );
}

export default Search;

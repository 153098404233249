/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { RESET_PASSWORD } from '../mutations/reset-password';

export default function useResetPassword() {
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  return { resetPassword, loading };
}

/**
 * External dependencies
 */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * Internal dependencies
 */

import DevicesPage from 'domain/devices/pages/devices';

const DevicesRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={DevicesPage} />
    </Switch>
  );
};

export default DevicesRoutes;

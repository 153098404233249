/**
 * External dependencies
 */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import crypto from 'crypto';
import * as rdd from 'react-device-detect';
import { toast } from 'react-toastify';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';
import emailPattern from 'helpers/email-pattern';
import useLogin from 'domain/auth/hooks/use-login';
import useVerifyDuplicateEmail from 'domain/auth/hooks/use-verify-duplicate-email';
import useNotifySupport from 'domain/auth/hooks/use-notify-support';
import useAuth from 'domain/auth/hooks/use-auth';
import useCreateUser from 'domain/auth/hooks/use-create-user';

function useQuery() {
  return new window.URLSearchParams(useLocation().search);
}

const FormRegister = ({
  setShowRegisterPage,
  setShowReset,
  goBackCallback,
}) => {
  const { createUser, loading } = useCreateUser();
  const { verifyDuplicateEmail } = useVerifyDuplicateEmail();
  const { notifySupport } = useNotifySupport();
  const [loginEmail] = useLogin('email');
  const { loginSuccess, newUserRegisteredHubspotEmail, setSonosFlow } =
    useAuth();
  let query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  const linkCode = query.get('linkCode');
  const jwtToken = query.get('jwtToken');
  const loginType = query.get('loginType');
  const menuId = query.get('exId');
  // check if trakdesk_cid cookie is available
  const trackdeskCid = document.cookie
    .split('; ')
    .find((row) => row.startsWith('trakdesk_cid='))
    ?.split('=')[1];

  const [buttonText, setButtonText] = useState(
    loginType === 'sonos'
      ? rdd?.isMobileOnly
        ? 'Start Trial'
        : 'Start Free Trial'
      : 'Get Started'
  );

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      companyName: '',
      name: '',
      email: '',
      password: '',
    },
  });

  const notifySuccess = () =>
    toast.dark('Your account was successfully created', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      bodyStyle: { color: '#fff' },
      style: { backgroundColor: '#670BCE' },
    });

  const notifyOtherError = () =>
    toast.dark(
      'Registration error occured, please contact our support team support@activaire.com',
      {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      }
    );

  const handleSubmit = (data) => {
    verifyDuplicateEmail({
      variables: {
        email: data.email,
      },
    }).then((resVerify) => {
      const isUniq = resVerify.data.verifyDuplicateEmailAddress;
      if (isUniq) {
        const servicetype = [];

        if (loginType === 'sonos') {
          servicetype.push('Sonos');
        } else {
          servicetype.push('Sonos');
          servicetype.push('Curator');
        }
        const createUserMethod = () =>
          createUser({
            variables: {
              name: data?.name,
              companyName: data.companyName,
              serviceType: loginType,
              menuId,
              authProvider: {
                email: data.email,
                password: crypto
                  .createHash('sha256')
                  .update(data.password)
                  .digest('hex'),
              },
              ...(trackdeskCid && { trackdeskCid }),
            },
          }).then((res) => {
            const isNewUserCreated = res.data.createUser?.newUser;

            if (isNewUserCreated) {
              loginEmail({
                variables: {
                  serviceType: loginType,
                  emailData: {
                    email: data.email,
                    password: crypto
                      .createHash('sha256')
                      .update(data.password)
                      .digest('hex'),
                  },
                  jwtToken: (redirectUrl && linkCode && jwtToken) || '',
                },
              })
                .then(({ data }) => {
                  if (
                    data?.signinUser &&
                    data?.signinUser?.token &&
                    data?.signinUser?.user
                  ) {
                    loginSuccess({
                      token: data.signinUser.token,
                      user: data.signinUser.user,
                      provider: 'email',
                      sonosAuthObj: data?.signinUser?.sonosAuthObj,
                      redirectUrl,
                      linkCode,
                    });
                    loginType === 'sonos' && setSonosFlow(true);
                  }
                })
                .catch(() => {
                  setShowRegisterPage(false);
                });
              notifySuccess();
              setShowReset(false);
            } else {
              methods.setError(
                'email',
                {
                  type: 'required',
                  message: 'This email is already registered',
                },
                { shouldFocus: true }
              );
            }
          });
        newUserRegisteredHubspotEmail({
          firstname: data?.name,
          email: data.email,
          company: data.companyName,
          servicetype,
          loginmethod: 'email',
        }).then((hubspotRes) => {
          if (hubspotRes.status === 200) {
            createUserMethod();
          } else if (hubspotRes === 'INVALID_EMAIL') {
            methods.setError(
              'email',
              {
                type: 'required',
                message: 'Your email address is invalid',
              },
              { shouldFocus: true }
            );
            if (buttonText === 'Continue Anyway') {
              createUserMethod();
              notifySupport({
                variables: {
                  email: data.email,
                  name: data?.name,
                  serviceType: servicetype,
                  type: 'INVALID_EMAIL',
                },
              });
            }
            setButtonText('Continue Anyway');
          } else if (hubspotRes === 'OTHER_ERROR') {
            notifyOtherError();
          }
        });
      } else {
        methods.setError(
          'email',
          { type: 'required', message: 'This email is already registered' },
          { shouldFocus: true }
        );
      }
    });
  };

  return (
    <div className="form form--login">
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="form__body">
            <div className="form__row">
              <FieldValidated
                type="text"
                name="companyName"
                placeholder="Company"
                validations={{
                  required: 'Field is required.',
                }}
              />
            </div>

            <div className="form__row">
              <FieldValidated
                type="text"
                name="name"
                placeholder="Name"
                validations={{
                  required: 'Field is required.',
                }}
              />
            </div>
            <div className="form__row">
              <FieldValidated
                type="email"
                name="email"
                placeholder="name@domain.com"
                validations={{
                  required: 'Field is required',
                  pattern: {
                    value: emailPattern,
                    message: 'E-Mail address is not valid',
                  },
                }}
                autocomplete="username"
              />
            </div>

            <div className="form__row">
              <FieldValidated
                type="password"
                name="password"
                placeholder="Enter password"
                validations={{
                  required: 'Field is required.',
                }}
                autocomplete="current-password"
              />
            </div>
          </div>

          <div className="form__actions2">
            <Button
              type="submit"
              modifier="primary"
              style={rdd?.isMobileOnly ? { width: 120, padding: 0 } : {}}
            >
              {loading ? (
                <div className="loader">
                  <span></span>

                  <span></span>

                  <span></span>
                </div>
              ) : (
                buttonText
              )}
            </Button>
            <Button
              style={{ padding: 20 }}
              modifier="as-link"
              onClick={goBackCallback}
            >
              Already have an account?
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormRegister;

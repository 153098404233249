import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'styles/style.scss';

/**
 * External dependencies
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Bugsnag from '@bugsnag/js';
/**
 * Internal dependencies
 */
import App from 'blocks/app/app';
import ApolloClientProvider from 'utils/apollo-client/apollo-client-provider';
import AuthProvider from 'domain/auth/contexts/auth-context/auth-provider';
import { Slide, ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import './utils/app-start/bugsnag';
import Page404 from './views/404/404';

const history = createBrowserHistory();
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={() => {
      localStorage.clear();
      return <Page404 />;
    }}
  >
    <GoogleOAuthProvider clientId="718100867150-7ljr2oq5bgvbrp848asiv5fmh7iqooj1.apps.googleusercontent.com">
      <AuthProvider>
        <ApolloClientProvider>
          <Router history={history}>
            <App />
            <ToastContainer closeOnClick={false} transition={Slide} />
          </Router>
        </ApolloClientProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Popup } from 'react-map-gl';
import { SONG_DATA_QUERY } from '../../domain/devices/queries/get-song-data';

const PopupComponent = ({ popupInfo, setPopupInfo }) => {
  const [songInfo, setSongInfo] = useState({});
  const [getSongData, { data: songData }] = useLazyQuery(SONG_DATA_QUERY);

  useEffect(() => {
    if (popupInfo.properties.currentSongId) {
      getSongData({
        variables: { songId: popupInfo?.properties?.currentSongId },
      });
    }
  }, [popupInfo.properties.currentSongId]);

  useEffect(() => {
    if (songData?.viewer?.getSongData) {
      setSongInfo(songData?.viewer?.getSongData);
    }
  }, [songData]);

  return (
    <Popup
      anchor="bottom"
      longitude={popupInfo.geometry.coordinates[0]}
      latitude={popupInfo.geometry.coordinates[1]}
      dynamicPosition={false}
      onClose={setPopupInfo}
    >
      <div
        style={{
          width: 250,
          height: 120,
          background: '#670BCE',
          borderRadius: 13,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '10px 10px 10px 25px',
        }}
      >
        <h3 style={{ marginBottom: 10 }}>{popupInfo.properties.deviceName}</h3>
        <p>{songInfo?.title}</p>
        <p>{songInfo?.artist}</p>
      </div>
    </Popup>
  );
};

export default PopupComponent;

/**
 * External dependencies
 */
import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_PLAYLISTS } from 'domain/music/queries/all-playlists';

import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

export default function useAllPlaylists(options) {
  const { currentDevice } = useContext(AuthContext);
  const { data, loading, error } = useQuery(ALL_PLAYLISTS, {
    variables: {
      allPlaylistsFirst: options?.first || 0,
      allPlaylistsAfter: options?.after || null,
      allPlaylistsFilter: options?.filters || null,
      allPlaylistsDeviceId: currentDevice || null,
    },
  });

  const featuredPlayableIds = useMemo(
    () => data?.viewer?.allPlaylists?.featuredIds || [],
    [data, error]
  );

  const allPlaylists = useMemo(
    () =>
      (!error &&
        data?.viewer?.allPlaylists?.edges.map((item) => ({
          id: item?.node?.id,
          type: 'playlist',
          name: item.node?.name,
          cover: item.node?.cover,
          genre: item.node?.genre,
          description: item.node?.description,
          tempo: item.node?.tempo,
          featuredArtists: item.node?.featuredArtists,
        }))) ||
      [],
    [data, error]
  );

  const allPlaylistsNoFeatured = useMemo(
    () =>
      allPlaylists?.filter((item) => !featuredPlayableIds.includes(item.id)),
    [allPlaylists]
  );

  const allFeaturedPlaylists = useMemo(
    () => allPlaylists?.filter((item) => featuredPlayableIds.includes(item.id)),
    [allPlaylists]
  );

  return {
    allPlaylists: allPlaylistsNoFeatured,
    allFeaturedPlaylists,
    loading,
    error,
  };
}

/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Button from '../button/button';
import Modal from '../modal/modal';

const OffScheduleModal = ({ visible, onClose, playingNow, resetSchedule }) => {
  const handleReturnToSchedule = () => {
    resetSchedule();
    onClose();
  };
  return (
    <Modal visible={visible} onClose={() => onClose()}>
      <div className="modal-window__container">
        <div className="modal-window__imgcontainer">
          <img
            className="modal-window__graphics2"
            src={require('assets/images/modals/Ellipse 1.png').default}
          />
          <img
            className="modal-window__graphics1"
            src={require('assets/images/modals/carry 1.png').default}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className="modal-window__title">You’re off schedule</p>
          <p className="modal-window__subtitle">
            You’ve strayed off your standard schedule. Playing now is{' '}
            {playingNow}
          </p>
          <Button
            modifier="white2"
            onClick={handleReturnToSchedule}
            style={{ marginRight: 20, marginBottom: 15, padding: 15 }}
          >
            Return to schedule
          </Button>
          <Button
            modifier="as-link"
            onClick={() => onClose()}
            style={{
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            Keep listening
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OffScheduleModal;

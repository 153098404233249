/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import SystemDetail from 'components/system-detail/system-detail';
import { ReactComponent as IcoIp } from 'assets/svg-icons/ico-ip.svg';
import { ReactComponent as IcoProcessor } from 'assets/svg-icons/ico-processor.svg';
import { ReactComponent as IcoLocation } from 'assets/svg-icons/ico-location.svg';
import useAllQsysDevices from '../hooks/use-all-qsys-devices';

const DeviceInfoPage = ({
  match: {
    params: { id },
  },
}) => {
  const { allQsysDevices, loading: loadingQsysDevices } = useAllQsysDevices();

  //   const { device, loading } = useSingleDevice(id);
  //   if (!loading && !device) {
  //     return <Page404 />;
  //   }

  //   const lastOnlineTime = new Date(device?.info?.lastOnline || null);
  //   const lastOnlineFormatted = getFormattedDate(lastOnlineTime);

  const ip = allQsysDevices[0].ipAddress;
  const systemVersion = allQsysDevices[0].systemVersion;
  const countryCode = allQsysDevices[0].countryCode;

  return (
    <DashboardLayout
      subtitle={''}
      title="Device Info"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="device-info"
    >
      <div className="system-details">
        <h4>System Details</h4>

        <div className="system-details__body">
          {/* <SystemDetail
            icon={<IcoTime />}
            title="Last Online"
            info={device?.info?.lastOnline ? lastOnlineFormatted : 'Unknown'}
          /> */}

          <SystemDetail
            icon={<IcoIp />}
            title="IP Address"
            info={ip || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="System version"
            info={systemVersion || 'Unspecified'}
          />

          {/* <SystemDetail
            icon={<IcoInternalIp />}
            title="Internal IP"
            info={device?.info?.internalIpAddress || 'Unspecified'}
          /> */}
          {/*
          <SystemDetail
            icon={<IcoMacAddress />}
            title="MAC Address"
            info={device?.info?.macAddress || 'Unspecified'}
          /> */}

          <SystemDetail
            icon={<IcoLocation />}
            title="Country code"
            info={countryCode}
          />

          {/* <SystemDetail
            icon={<IcoWlan />}
            title="WLAN MAC Address"
            info={device?.info?.ethernetMacAddress || 'Unspecified'}
          /> */}
        </div>
      </div>

      {/* <UsersList usersArray={device?.usersId || null} loading={!device} /> */}
    </DashboardLayout>
  );
};

export default DeviceInfoPage;

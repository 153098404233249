/**
 * External dependencies
 */
import React, { useState, useEffect, memo, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import cx from 'classnames';

/**
 * Internal dependencies
 */
import { ReactComponent as IcoHeart } from 'assets/svg-icons/ico-heart.svg';
import Button from 'components/button/button';
import useAuth from 'domain/auth/hooks/use-auth';
import useToggleFavouriteDevice from 'domain/devices/hooks/use-toggle-favourite-device';
import { SONG_DATA_QUERY } from 'domain/devices/queries/get-song-data';
import getRandomColor from '../../helpers/get-random-color';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import useChangeDefaultDeviceId from '../../domain/devices/hooks/use-change-default-deviceId';
import _ from 'lodash';

const Device = ({
  to,
  deviceId,
  deviceClientId,
  deviceName,
  deviceDescription,
  currentSongId,
  deviceIsOnline,
  deviceIsPlaying,
  favourite,
  color,
}) => {
  const [isFavourite, setFavourite] = useState(favourite);
  const [songInfo, setSongInfo] = useState({});
  const { setCurrentDevice, setCurrentClient } = useAuth();
  const { toggleDeviceFavourite } = useToggleFavouriteDevice();
  const { changeDefaultDeviceId } = useChangeDefaultDeviceId();
  const { user } = useContext(AuthContext);
  const [getSongData, { data: songData }] = useLazyQuery(SONG_DATA_QUERY);

  const [deviceColor, setDeviceColor] = useState();

  useEffect(() => {
    setDeviceColor(
      deviceIsOnline && deviceIsPlaying
        ? getRandomColor(_.random(14))
        : '#262735'
    );
  }, [deviceIsOnline, deviceIsPlaying]);

  const handleAddToFavourites = (e) => {
    setFavourite(!isFavourite);

    toggleDeviceFavourite({
      variables: {
        userId: user.id,
        deviceId: deviceId,
        toggle: !isFavourite,
      },
    });

    e.preventDefault();
  };

  useEffect(() => {
    if (currentSongId) {
      getSongData({ variables: { songId: currentSongId } });
    }
  }, [currentSongId]);

  useEffect(() => {
    if (songData?.viewer?.getSongData) {
      setSongInfo(songData?.viewer?.getSongData);
    }
  }, [songData]);
  const handleSetCurrentDevice = () => {
    // implement mutation here
    changeDefaultDeviceId({ variables: { id: deviceId } });
    setCurrentDevice(deviceId);
    setCurrentClient(deviceClientId);
  };

  return (
    <div
      className={cx('device', { 'device--favourite': isFavourite })}
      style={{
        backgroundColor: color,
      }}
    >
      <Button
        to={{
          pathname: to,
          state: { deviceId },
        }}
        onClick={handleSetCurrentDevice}
        className="device__link"
      />

      <div className="device__head">
        <h3>{deviceName}</h3>

        <p>{deviceDescription}</p>
      </div>

      <div className="device__body">
        <h4>{deviceIsOnline && deviceIsPlaying && songInfo?.title}</h4>

        <h5>{deviceIsOnline && deviceIsPlaying && songInfo?.artist}</h5>
      </div>

      <button onClick={handleAddToFavourites} className="device__heart">
        <IcoHeart />
      </button>
    </div>
  );
};

export default memo(Device);

/**
 * External dependencies
 */
import React, { useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';

import useRequestAccess from '../../domain/dashboard/hooks/use-request-access';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import { toast } from 'react-toastify';

const FormAddDevice = ({ toggle }) => {
  const { requestAccess, loading } = useRequestAccess();

  const { user } = useContext(AuthContext);

  const notify = () =>
    toast.success('Request submitted!', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      // toastId: customId,
      bodyStyle: { fontSize: 16, color: '#fff' },
      style: { backgroundColor: '#670BCE', color: '#fff', fontSize: 16 },
    });

  const handleSubmit = (data) => {
    requestAccess({
      variables: {
        email: user.emails[0].address,
        text: data.text,
      },
    }).then(() => {
      toggle();
      notify();
    });
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      text: '',
    },
  });

  return (
    <div className="form form--add-device">
      <FormProvider {...methods}>
        <div>
          <div className="form__body">
            <div className="form__row">
              <FieldValidated
                isTextarea
                name="text"
                validations={{
                  required: 'Field is required',
                  minLength: {
                    value: 5,
                    message: 'Minimum 5 characters required.',
                  },
                }}
                placeholder="Device tag number(s), location name(s), store number(s)..."
              />
            </div>
          </div>

          <div className="form__actions">
            <Button
              modifier="primary"
              onClick={methods.handleSubmit(handleSubmit)}
            >
              Add device
            </Button>
            <Button modifier="as-link" onClick={() => toggle()}>
              Cancel
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default FormAddDevice;

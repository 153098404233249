/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

const ResultItem = ({
  to,
  title,
  description,
  image,
  dotColor,
  hasImage = false,
  isMobile = false,
  color = '#FF4000',
}) => {
  const screenType = isMobile ? 'result-item-mobile' : 'result-item';
  return (
    <div className={cx('result-item', { 'result-item--flex': image })}>
      <Link to={to}>
        {hasImage && (
          <div
            className="result-item__image"
            style={{ backgroundColor: color }}
          >
            {image && (
              <img
                style={{ borderRadius: 3 }}
                src={image?.replace('artwork', 'thumbnails')}
                alt=""
              />
            )}
          </div>
        )}

        <div className="result-item__content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {dotColor && (
              <span
                className="dot"
                style={{ backgroundColor: dotColor }}
              ></span>
            )}
            <span className="result-item__title">{title}</span>
          </div>
          <span className="result-item__description">{description}</span>
        </div>
      </Link>
    </div>
  );
};

export default ResultItem;

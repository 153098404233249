/**
 * Internal dependencies
 */

import { useQuery } from '@apollo/client';

import { ALL_CATEGORIES } from '../query/all-categories';
import { useMemo } from 'react';

export default function useAllCategories() {
  const { data, loading, error } = useQuery(ALL_CATEGORIES);

  const allCategories = useMemo(
    () =>
      [...(data?.viewer?.allCategories || [])].sort(
        (a, b) => a?.sortOrder - b?.sortOrder
      ),
    [data]
  );

  return {
    allCategories,
    loading,
    error,
  };
}

/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_SCHEDULE } from 'domain/schedule/queries/all-schedule';

export default function useSchedule(deviceId) {
  const { data, loading, error } = useQuery(ALL_SCHEDULE, {
    variables: {
      deviceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const schedule = useMemo(
    () =>
      data &&
      !error &&
      data.viewer.allSchedule?.edges?.map((schedule) => ({
        id: schedule.node?.id,
        name: schedule.node?.name,
        startDate: schedule.node?.startDate,
        endDate: schedule.node?.endDate,
        size: schedule.node?.size,
        injectable: schedule.node?.injectable,
        playingPlaylists: schedule.node?.playingPlaylists,
      })),
    [data, error]
  );

  return {
    schedule,
    loading,
    error,
  };
}

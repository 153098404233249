/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { USER_ARTWORK } from '../query/get-artwork';

export default function useUserArtwork() {
  const { data: getUserArtwork, loading: loadingQuery } =
    useQuery(USER_ARTWORK);
  return {
    getUserArtwork,
    loadingQuery,
  };
}

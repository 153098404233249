/**
 * External dependencies
 */
import React, { memo, useEffect, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useLocation } from 'react-router-dom';

/**
 * Internal dependencies
 */
import useLogin from 'domain/auth/hooks/use-login';
import useAuth from 'domain/auth/hooks/use-auth';
import Button from 'components/button/button';
import { toast } from 'react-toastify';
import { ReactComponent as IcoApple } from 'assets/svg-icons/ico-apple.svg';

function useQuery() {
  const URLSearchParams = window.URLSearchParams;
  return new URLSearchParams(useLocation().search);
}

const ButtonAppleLogin = () => {
  let query = useQuery();
  const [loaded, setLoaded] = useState(true);
  const [loginApple, { loading, data }] = useLogin('apple');
  const {
    loginSuccess,
    setLoginLoading,
    newUserRegisteredHubspotEmail,
    setSonosFlow,
  } = useAuth();

  const redirectUrl = query.get('redirectUrl');
  const linkCode = query.get('linkCode');
  const jwtToken = query.get('jwtToken');
  // check if trakdesk_cid cookie is available
  const trackdeskCid = document.cookie
    .split('; ')
    .find((row) => row.startsWith('trakdesk_cid='))
    ?.split('=')[1];

  const notify = () =>
    toast.dark('Your account was successfully created', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      bodyStyle: { color: '#fff' },
      style: { backgroundColor: '#670BCE' },
    });

  const handleButtonClick = (onClick = () => {}) => {
    setLoginLoading(true);

    onClick();
  };

  useEffect(() => {
    setLoginLoading(loading);
  }, [loading, setLoginLoading]);

  const handleLoginAppleSuccess = (data) => {
    if (data?.error) {
      console.log('ERROR', data?.error?.error);
      toast.dark('Apple ID not found', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      });
    } else if (data?.authorization?.id_token) {
      const appleToken = data?.authorization?.id_token;

      loginApple({
        variables: {
          appleToken,
          serviceType: query.get('loginType'),
          jwtToken: (redirectUrl && linkCode && jwtToken) || '',
          menuId: query.get('exId'),
          ...(trackdeskCid && { trackdeskCid }),
        },
      }).then((res) => {
        const isNewUser = res.data.handleApple?.newUser;
        const user = res.data.handleApple?.user;
        const householdId =
          res.data.handleApple?.sonosAuthObj?.householdId || null;

        if (isNewUser) {
          const servicetype = [];

          if (query.get('loginType') === 'sonos') {
            servicetype.push('Sonos');
            setSonosFlow(true);
          } else {
            servicetype.push('Sonos');
            servicetype.push('Curator');
          }
          newUserRegisteredHubspotEmail({
            firstname: user?.profile?.name || '',
            email: user?.emails[0]?.address,
            company: '',
            servicetype,
            loginmethod: 'apple',
            householdId,
          });
          notify();
        }
      });
    }
  };

  useEffect(() => {
    if (data && loaded) {
      setLoaded(false);
      loginSuccess({
        token: data.handleApple.token,
        user: data.handleApple.user,
        provider: 'apple',
        sonosAuthObj: data?.handleApple?.sonosAuthObj,
        redirectUrl,
        linkCode,
      });
    }
  }, [data, loginSuccess]);
  return (
    <AppleLogin
      redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URL}
      usePopup={true}
      scope={'email name'}
      clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
      render={(renderProps) => (
        <Button
          modifier="icon"
          onClick={() => handleButtonClick(renderProps.onClick)}
          disabled={renderProps.disabled}
        >
          <IcoApple />

          {loading ? 'Loading...' : 'Apple'}
        </Button>
      )}
      callback={handleLoginAppleSuccess}
    />
  );
};

export default memo(ButtonAppleLogin);

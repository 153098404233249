/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SIGN_OUT_CLEAR } from '../mutations/signOutClear';

export default function useSignOutClear() {
  const [signOutClear, { loading }] = useMutation(SIGN_OUT_CLEAR);

  return { signOutClear, loading };
}

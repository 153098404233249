/**
 * External dependencies
 */
import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_SCENES } from 'domain/music/queries/all-scenes';

import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

export default function useAllScenes(options) {
  const { currentDevice } = useContext(AuthContext);
  const { data, loading, error } = useQuery(ALL_SCENES, {
    variables: {
      allScenesFirst: options?.first || 0,
      allScenesAfter: options?.after || null,
      allScenesFilter: options?.filters || null,
      allScenesDeviceId: currentDevice || null,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  const featuredPlayableIds = useMemo(
    () => data?.viewer?.allScenes?.featuredIds || [],
    [data, error]
  );

  const allScenes = useMemo(
    () =>
      (!error &&
        data?.viewer?.allScenes?.edges.map((item) => ({
          id: item.node?.id,
          type: 'scene',
          name: item.node?.name,
          cover: item.node?.image,
          description: item.node?.description,
          category: item.node?.category,
          subCategory: item.node?.subCategory,
          genre: item.node?.genres,
          newCategory: item.node?.newCategory,
          vibe: item.node?.vibe,
        }))) ||
      [],
    [data, error]
  );

  const allScenesNoFeatured = useMemo(
    () => allScenes?.filter((item) => !featuredPlayableIds.includes(item.id)),
    [allScenes]
  );

  const allFeaturedScenes = useMemo(
    () => allScenes?.filter((item) => featuredPlayableIds.includes(item.id)),
    [allScenes]
  );

  return {
    allScenes: allScenesNoFeatured,
    allFeaturedScenes,
    loading,
    error,
  };
}

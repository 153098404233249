/**
 * External dependencies
 */
import React, { useState } from 'react';

import LoadingProvider from 'components/loading-provider/loading-provider';

import { ReactComponent as IcoCurator } from 'assets/svg-icons/Curator-dot.svg';

import Button from '../../components/button/button';
import getRandomColor from 'helpers/get-random-color';
import _ from 'lodash';

const Playlist = ({
  subtitle,
  name,
  title,
  description,
  showPreviewButton,
  handlePreview,
  playlistId,
  children,
  cover,
  sonosDeviceId,
}) => {
  const [randomColor] = useState(getRandomColor(_.random(14)));
  const isSonosDevice = !!sonosDeviceId;

  //   show loader when click play then redirect to home
  return (
    <div>
      <LoadingProvider loading={!children} style={{ marginTop: 160 }}>
        <div className="playlist">
          <div
            className="playlist__card"
            style={{ backgroundColor: randomColor }}
          >
            <aside className="playlist__card-aside">
              {cover && <img src={cover} alt={name} />}
              <IcoCurator
                style={{
                  position: 'absolute',
                  height: 50,
                  bottom: 20,
                  left: 20,
                }}
              />
              {subtitle && <h5>{subtitle}</h5>}
              {name && <h2>{name}</h2>}

              {playlistId && showPreviewButton && (
                <Button
                  className={'playlist-play-now'}
                  onClick={handlePreview}
                  modifier="primary"
                  style={{ position: 'absolute', bottom: 10, right: 10 }}
                >
                  Preview
                </Button>
              )}
            </aside>
          </div>
          <div className="playlist__card-content">
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
          </div>

          <div className="playlist__body">{children}</div>
        </div>
      </LoadingProvider>
    </div>
  );
};

export default Playlist;

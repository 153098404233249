/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_AUDIO_DEVICES_IDS = gql`
  query allAudioDevicesFavouritesQuery($clientId: String) {
    viewer {
      allAudioDevicesFavourites(filter: { clientId_contains: $clientId }) {
        edges {
          node {
            id
            name
          }
        }
        count
      }
    }
  }
`;

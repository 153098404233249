import React from 'react';
import Marquee from 'react-fast-marquee';

const processedArray = (array = []) => {
  const returnedArray = [];
  for (let i = 0; i < array.length; i++) {
    if (i % 2 === 1) {
      returnedArray.push({
        up: array[i - 1],
        down: array[i],
      });
    }
  }
  if (returnedArray.length % 2 === 1) {
    returnedArray.pop();
  }
  return returnedArray;
};

const CarouselComponent = ({ artworkUrls }) => {
  const artworkArray = processedArray(artworkUrls);

  return (
    <div style={{ paddingTop: 20, overflow: 'hidden' }}>
      <Marquee
        gradient={false}
        speed={100}
        style={{ height: '100%', minHeight: 270 }}
      >
        {artworkArray.map((item, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: i % 2 === 0 ? 20 : 0,
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <img
              style={{
                backgroundColor: '#40BAB0',
                borderRadius: 13,
                height: 120,
                width: 120,
              }}
              src={item.up}
            />
            <img
              style={{
                backgroundColor: '#40BAB0',
                borderRadius: 13,
                height: 120,
                width: 120,
                marginTop: 10,
              }}
              src={item.down}
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default CarouselComponent;

/**
 * External dependencies
 */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * Internal dependencies
 */
import MusicPage from 'domain/music/pages/music';
import MusicSearchCollection from 'domain/music/pages/music-search-all';
import MusicSearchPage from 'domain/music/pages/music-search';
import CurrentPlaylistPage from 'domain/music/pages/current-playlist';
import CuratedMixPage from 'domain/music/pages/curated-mix';
import SingleGenrePage from 'domain/music/pages/single-genre';
import SingleScenePage from 'domain/music/pages/single-scene';
import SingleIntensityPage from 'domain/music/pages/single-intensity';
import SceneSubCategoryPage from 'domain/music/pages/subcategory-page';
import SceneSubCategories from 'domain/music/pages/scene-subcategories';
import FeaturedPage from 'domain/music/pages/featured-page';
import SingleVibePage from '../pages/single-vibe';

const MusicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/:id" component={MusicPage} />

      <Route exact path="/:id/curated-mix" component={CuratedMixPage} />

      <Route exact path="/:id/search/:searchTerm" component={MusicSearchPage} />

      <Route exact path="/:id/search/all/featured" component={FeaturedPage} />

      <Route
        exact
        path="/:id/search/all/:collectionId"
        component={MusicSearchCollection}
      />

      <Route
        exact
        path="/:id/search/:collectionId/:playlistId"
        component={CurrentPlaylistPage}
      />

      <Route
        exact
        path="/:id/search/all/genres/:genre"
        component={SingleGenrePage}
      />

      <Route
        exact
        path="/:id/search/all/vibe/:vibe"
        component={SingleVibePage}
      />

      <Route
        exact
        path="/:id/search/all/scenes/:category/:subcategory"
        component={SceneSubCategoryPage}
      />

      <Route
        exact
        path="/:id/search/all/scene/:sceneId"
        component={SingleScenePage}
      />

      <Route
        exact
        path="/:id/search/all/scenes/:category"
        component={SceneSubCategories}
      />

      <Route
        exact
        path="/:id/search/all/intensities/:intensity"
        component={SingleIntensityPage}
      />
    </Switch>
  );
};

export default MusicRoutes;

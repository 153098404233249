/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { VERIFY_EMAIL } from '../mutations/verifyDuplicateEmailAddress';

export default function useVerifyDuplicateEmail() {
  const [verifyDuplicateEmail, { loading }] = useMutation(VERIFY_EMAIL);

  return { verifyDuplicateEmail, loading };
}

/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */

import ButtonGoogleLogin from 'domain/auth/blocks/button-google-login/button-google-login';
import ButtonFacebookLogin from 'domain/auth/blocks/button-facebook-login/button-facebook-login';
import ButtonAppleLogin from 'domain/auth/blocks/button-apple-login/button-apple-login';

const LoginSocials = () => {
  return (
    <div className="section-auth__actions2">
      <h5>Or continue with:</h5>
      <div className="section-auth__actions">
        <ButtonGoogleLogin />
        <ButtonAppleLogin />
        <ButtonFacebookLogin />
      </div>
    </div>
  );
};

export default LoginSocials;

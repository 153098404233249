/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { CREATE_SONOS_CLOUD_TOKEN } from 'domain/sonos/mutations/create-sonos-cloud-token';

export default function useCreateSonosCloudToken() {
  const [createSonosCloudToken, { loading, data: token }] = useMutation(
    CREATE_SONOS_CLOUD_TOKEN
  );

  return { createSonosCloudToken, loading, token };
}

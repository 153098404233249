/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const VERIFY_EMAIL = gql`
  mutation verifyDuplicateEmailAddressMutation($email: String!) {
    verifyDuplicateEmailAddress(email: $email)
  }
`;

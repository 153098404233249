/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

/**
 * Internal dependencies
 */

import useUpdateDeviceVolume from 'domain/dashboard/hooks/use-update-device-volume';

const ProgressRangeFooter = ({ volume, deviceId }) => {
  const [deviceVolume, setDeviceVolume] = useState({ values: [volume] });
  const { updateDeviceVolume, loading } = useUpdateDeviceVolume();

  const [sliding, setSliding] = useState(false);

  useEffect(() => {
    if (!loading && !sliding) {
      setDeviceVolume({ values: [volume] });
    }
  }, [volume]);

  const handleSliderChange = (values) => {
    setDeviceVolume({ values });
    updateDeviceVolume({
      variables: {
        deviceId: deviceId,
        volume: values[0],
      },
    });
  };

  const minVolume = 0;
  const maxVolume = 15;
  const currentVolume = deviceVolume.values[0];
  const displayedVolume =
    Math.min(
      100,
      Math.max(0, ((currentVolume - minVolume) / (maxVolume - minVolume)) * 100)
    ) || 0;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        width: 120,
      }}
    >
      <div style={{ width: 100 }}>
        <Range
          step={1}
          min={minVolume}
          max={maxVolume}
          values={deviceVolume.values}
          onChange={(values) => {
            handleSliderChange(values);
            setSliding(true);
          }}
          onFinalChange={() => setSliding(false)}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '4px',
                opacity: '.75',
                background: getTrackBackground({
                  values: deviceVolume.values,
                  colors: [
                    'rgba(255, 255, 255, 0.75)',
                    'rgba(255, 255, 255, 0.3)',
                  ],
                  min: minVolume,
                  max: maxVolume,
                }),
                borderRadius: '44px',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '14px',
                width: '14px',
                backgroundColor: '#ffffff',
                borderRadius: '50%',
              }}
            />
          )}
        />
      </div>
      <span
        style={{
          marginLeft: 10,
          marginBottom: 1,
          color: '#A9A9A9',
          width: 20,
          fontSize: 10,
        }}
      >
        {displayedVolume.toFixed(0)}
      </span>
    </div>
  );
};

export default ProgressRangeFooter;

/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import Logo from 'components/logo/logo';
import LogoSonos from 'components/logo/logoSonos';

const HeaderAuth = ({ onPressSignUp, hideSignup, isSonos }) => {
  return (
    <header className="header">
      {isSonos && hideSignup ? <LogoSonos /> : <Logo />}
      {/* Added false below to hide signup button */}
      {!hideSignup && false && (
        <Button onClick={onPressSignUp} modifier="white">
          Sign Up
        </Button>
      )}
    </header>
  );
};

export default HeaderAuth;

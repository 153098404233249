/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation RefreshTokenMutation {
    refreshToken
  }
`;

/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

const useAuth = () => {
	return useContext(AuthContext);
};

export default useAuth;

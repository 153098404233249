/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const OPEN_STRIPE_CUSTOMER_PORTAL = gql`
  mutation openStripeCustomerPortal($route: String) {
    openStripeCustomerPortal(route: $route)
  }
`;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SUBSCRIBE_QSYS_DEVICE = gql`
  subscription QsysDeviceSubscription {
    QsysDeviceSubscription {
      mutation
      node {
        id
        noOfZones
        zones {
          zoneId
          zoneName
          songId
          currentAlbum
          currentTitle
          currentArtist
          currentArtwork
          reason
          songReaction
          mixType
          mixId
          mixName
          volume
        }
      }
    }
  }
`;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const GET_DISPLAY_CODE = gql`
  mutation getDisplayCode($deviceId: String!) {
    getDisplayCode(deviceId: $deviceId)
  }
`;

/**
 * Internal dependencies
 */
import getRandomInt from 'utils/getRandomInt';

export default function getRandomArtwork() {
	const imageNumber = getRandomInt(1, 23);

	return require(`assets/images/offline-artwork/offline${imageNumber}.png`).default;
}

/**
 * External dependencies
 */
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';
import useResetPassword from '../../domain/auth/hooks/use-reset-password';
import crypto from 'crypto';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function useQuery() {
  return new window.URLSearchParams(useLocation().search);
}

const FormRecover = ({ hidePasswordReset, goBackCallback }) => {
  const { resetPassword } = useResetPassword();
  let query = useQuery();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      password1: '',
      password2: '',
    },
  });

  const handleSubmit = (data) => {
    if (data.password1 === data.password2) {
      hidePasswordReset();
      resetPassword({
        variables: {
          recoveryToken: query.get('resetToken'),
          newPassword: crypto
            .createHash('sha256')
            .update(data.password1)
            .digest('hex'),
        },
      })
        .then(({ data }) => {
          data?.resetPassword
            ? toast.success('Password changed successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: true,
                bodyStyle: { color: '#fff' },
                style: { backgroundColor: '#670BCE' },
              })
            : toast.dark(
                'Something went wrong, please contact support@activaire.com',
                {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  bodyStyle: { color: '#fff' },
                  style: { backgroundColor: '#670BCE' },
                }
              );
        })
        .catch((err) => {
          console.log('Recover Password error:', err);
          toast.dark(
            'Something went wrong, please contact support@activaire.com',
            {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: true,
              bodyStyle: { color: '#fff' },
              style: { backgroundColor: '#670BCE' },
            }
          );
        });
    } else {
      methods.setError(
        'password1',
        {
          type: 'focus',
          message: 'Password fields do not match.',
        },
        { shouldFocus: true }
      );
    }
  };

  return (
    <div className="form form--reset">
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="form__body">
            <h4>Use this form to reset your password.</h4>

            <div className="form__row">
              <FieldValidated
                type="password"
                name="password1"
                placeholder="New password"
                validations={{
                  required: 'Field is required',
                  pattern: {
                    message: 'Passwords do not match',
                  },
                }}
              />
            </div>
            <div className="form__row">
              <FieldValidated
                type="password"
                name="password2"
                placeholder="Verify password"
                validations={{
                  required: 'Field is required',
                  pattern: {
                    message: 'Passwords do not match',
                  },
                }}
              />
            </div>
          </div>

          <div
            className="form__actions2"
            style={{ textAlign: 'left', marginTop: 32 }}
          >
            <Button type="submit" modifier="primary">
              Reset password
            </Button>

            <Button
              style={{ paddingLeft: 20 }}
              modifier="as-link"
              onClick={goBackCallback}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormRecover;

/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { AUDIO_BACKLOG } from 'domain/music/queries/audio-backlog';

export default function useAudioBacklog(deviceId) {
  const { data, loading, error } = useQuery(AUDIO_BACKLOG, {
    variables: {
      deviceId: deviceId || '',
    },
    fetchPolicy: 'network-only',
  });

  return {
    audioBacklog: data?.viewer?.getAudioDeviceBacklog[0],
    loading,
    error,
  };
}

/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';

/**
 * Internal Dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Article from 'components/article/article';
import LoadingProvider from 'components/loading-provider/loading-provider';

const fetchHighlights = async () => {
  const response = await fetch(process.env.REACT_APP_WORDPRESS_POSTS);
  return response.json();
};

const HighlightsPage = ({
  match: {
    params: { id },
  },
}) => {
  const [highlights, setHighlights] = useState();

  useEffect(() => {
    fetchHighlights()
      .then((res) => setHighlights(res))
      .catch((err) => {
        console.log('fetchHighlights error:', err);
      });
  }, []);

  return (
    <DashboardLayout
      subtitle="Curator"
      title="Highlights"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="highlights"
    >
      <LoadingProvider loading={!highlights}>
        {highlights && (
          <Article
            to={
              id
                ? `/${id}/highlights/${highlights[0]?.slug}`
                : `/highlights/${highlights[0]?.slug}`
            }
            key={highlights[0]?.id}
            single={true}
            title={highlights[0]?.title?.rendered}
            image={
              highlights[0]?._embedded['wp:featuredmedia'] &&
              highlights[0]._embedded['wp:featuredmedia'][0]?.media_details
                ?.sizes?.full?.source_url
            }
            color={highlights[0]?.acf?.background_color}
          >
            {highlights[0]?.excerpt?.rendered}
          </Article>
        )}

        <div className="articles">
          <h4>Don't miss</h4>

          <div className="articles__body">
            {highlights &&
              highlights.length &&
              highlights.slice(1).map((article) => (
                <Article
                  to={`/${id}/highlights/${article.slug}`}
                  key={article.id}
                  title={article.title.rendered}
                  image={
                    article._embedded['wp:featuredmedia'][0].media_details.sizes
                      ?.full?.source_url
                  }
                  color={article.acf.background_color}
                  className="articles__item"
                >
                  {article.excerpt.rendered}
                </Article>
              ))}
          </div>
        </div>
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default HighlightsPage;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const AUDIO_BACKLOG = gql`
	query AudioDeviceBacklog($deviceId: String!) {
    viewer {
      getAudioDeviceBacklog(deviceId: $deviceId) {
        id
        deviceId
        songLogs {
          timestamp
          song {
            id
            artist
            title
            album
            artworkUrl
            preview {
              url
            }
          }
        }
      }
    }
  }
`;

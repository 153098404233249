import React from 'react';

function Pause({ onClick, style, disabled }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={!disabled ? 'svg-stroke' : 'svg-stroke-disabled'}
      style={{ marginRight: 32, ...style }}
      onClick={!disabled ? onClick : undefined}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 4H6v16h4V4zM18 4h-4v16h4V4z"
      ></path>
    </svg>
  );
}

export default Pause;

/**
 * External dependencies
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

/**
 * Internal dependencies
 */
import useAuth from 'domain/auth/hooks/use-auth';
import NowPlayingLayout from '../../layouts/now-playing-layout';
import NotificationLayout from '../../layouts/notification-layout';

const AuthRoute = ({ component: Component, ...props }) => {
  const { isLoggedIn } = useAuth();
  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        return (
          <NotificationLayout>
            <NowPlayingLayout>
              <Component {...routeProps} />
            </NowPlayingLayout>
          </NotificationLayout>
        );
      }}
    />
  );
};

export default AuthRoute;

/**
 * External dependencies
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import NotificationLayout from '../../layouts/notification-layout';
/**
 * Internal dependencies
 */
import useAuth from 'domain/auth/hooks/use-auth';

const GuestRoute = ({ component: Component, ...props }) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (isLoggedIn) {
          return <Redirect to="/" />;
        }

        return (
          <NotificationLayout>
            <Component {...routeProps} />
          </NotificationLayout>
        );
      }}
    />
  );
};

export default GuestRoute;

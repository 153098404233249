/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { TOGGLE_FAVOURITE_DEVICE } from 'domain/devices/mutations/toggle-favourite-device';

export default function useToggleFavouriteDevice() {

	const [toggleDeviceFavourite, { loading }] = useMutation(TOGGLE_FAVOURITE_DEVICE);

	return { toggleDeviceFavourite, loading };
}

function isSonosAccessTokenAvailable() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name, value] = cookie.split('=');
    if (name.trim() === 'sonosAccessToken') {
      const [token, expiry] = value.split(',');
      if (token === 'active' && new Date(expiry) > new Date()) {
        return true;
      }
    }
  }
  return false;
}

export default isSonosAccessTokenAvailable;

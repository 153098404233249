/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const FORGOT_PASSWORD = gql`
	mutation ForgotPasswordMutation($forgotPasswordEmail: String!) {
		forgotPassword(email: $forgotPasswordEmail) {
    		id
  		}
	}
`;

import React, { useState } from 'react';
import Button from '../../../components/button/button';
import Carousel from './carousel';
import useUserArtwork from '../hooks/use-user-artwork';

const BoardingEnd = ({ setSelectedStack, stack, setIsWizardCompleted }) => {
  const [loading, setLoading] = useState(false);
  const { getUserArtwork, loadingQuery } = useUserArtwork();
  return (
    <div className="modal-window__container">
      <Carousel artworkUrls={getUserArtwork?.viewer?.userArtwork || []} />

      <div style={{ textAlign: 'center' }}>
        <p className="modal-window__title" style={{ margin: '32px 0px' }}>
          Curating good vibes.
        </p>
        {!loading && (
          <Button
            modifier="as-link"
            onClick={() =>
              setSelectedStack(
                (selectedStack) => stack[stack.indexOf(selectedStack) - 1]
              )
            }
            style={{ color: '#fff', fontWeight: 'bold', marginRight: 20 }}
          >
            Back
          </Button>
        )}
        <Button
          modifier="white2"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            setIsWizardCompleted(true);
          }}
          style={{
            padding: '12px 0px',
            width: 110,
            marginBottom: 35,
          }}
        >
          {loading ? 'Loading...' : 'Let’s go!'}
        </Button>
      </div>
    </div>
  );
};

export default BoardingEnd;

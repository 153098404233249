/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { PLAY_SONOS_MIX } from '../mutations/play-sonos-mix';

export default function usePlaySonosMix() {
  const [playSonosMix, { loading, data }] = useMutation(PLAY_SONOS_MIX, {
    onError: (e) => console.log('Could not update device playlist: ', e),
  });

  return { playSonosMix, loading, data };
}

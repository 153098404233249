/**
 * External dependencies
 */
import React, { useState, useEffect, useContext } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import SystemDetail from 'components/system-detail/system-detail';
import { ReactComponent as IcoDown } from 'assets/svg-icons/down.svg';
import { ReactComponent as IcoRight } from 'assets/svg-icons/right.svg';
import { ReactComponent as IcoUp } from 'assets/svg-icons/up.svg';
import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Field from 'components/field/field';
import useHubSpotData from '../../../views/support/hooks/use-hubspot-data';
import _ from 'lodash';

import { Toggle } from 'react-powerplug';
import ModalSupportStatus from 'components/modal-status/modal-support-status';

const fetchFaqs = async () => {
  const response = await fetch(
    'https://activaire.com/wp-json/wp/v2/pages/2904'
  );
  return response.json();
};

const SupportPage = ({
  match: {
    params: { id },
  },
}) => {
  const { device } = useSingleDevice(id);
  const { hubspotData, loading: apiLoading } = useHubSpotData();
  const { user } = useContext(AuthContext);

  const [fieldValue, setFieldValue] = useState('');

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetchFaqs()
      .then((res) => {
        const questions = res.acf.questions;

        const order = {
          'Getting Started': 1,
          'My Account': 2,
          default: Number.MAX_VALUE,
        };

        const categories = _.uniq(questions.map((item) => item.category)).sort(
          (a, b) =>
            (order[a] || order.default) - (order[b] || order.default) ||
            a > b ||
            -(a < b)
        );

        const arr = categories.map((category) => {
          const filteredQuestions = questions.filter(
            (item) => item.category === category
          );
          return {
            sectionName: category,
            items: filteredQuestions.map((item) => {
              return {
                question: item.question,
                answer: item.answer.replace(/(<([^>]+)>)/gi, ''),
                icon: false,
              };
            }),
          };
        });

        setFaqs(arr);
      })
      .catch((err) => {
        console.log('fetchFaqs error:', err);
      });
  }, []);

  const handleExpand = (faqIndex, index) => {
    let tempSections = [...faqs];
    tempSections[faqIndex].items[index] = {
      ...tempSections[faqIndex].items[index],
      icon: !tempSections[faqIndex].items[index].icon,
    };
    setFaqs(tempSections);
  };

  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState();

  useEffect(() => {
    if (hubspotData) {
      setOptions(
        hubspotData?.fieldGroups[4].fields[0].options.map((item) => item.value)
      );
    }
  }, [apiLoading]);

  useEffect(() => {
    if (id && !device?.isOnline) {
      setDefaultOption({ value: options[7], label: options[7] });
    }
  }, [device, options]);

  const submitForm = () => {
    const obj = {
      fields: [
        {
          name: 'firstname',
          value: user?.profile?.name,
        },
        {
          name: 'email',
          value: user?.emails[0]?.address,
        },
        {
          name: 'TICKET.hs_ticket_category',
          value: defaultOption?.value,
        },
        {
          name: 'TICKET.content',
          value: fieldValue,
        },
      ],
    };

    fetch(
      'https://api.hsforms.com/submissions/v3/integration/submit/2621188/0e5e9d50-2b6b-4781-b32a-164368b99265',
      {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      }
    )
      .then((res) => res.json())
      .then(() => setNotificationVisible(true))
      .catch((err) => {
        throw Error('Form submission failed!');
      });
  };

  const [notificationVisible, setNotificationVisible] = useState(false);

  return (
    <DashboardLayout
      title="Frequently asked"
      subtitle="Help"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="profile"
    >
      {faqs.map((faq, faqIndex) => {
        return (
          <div className="system-details" key={faq.sectionName}>
            <h4>{faq.sectionName}</h4>

            <div className="system-details__body">
              {faq.items.map((item, index) => {
                return (
                  <SystemDetail
                    info={item.icon && item.answer}
                    key={index}
                    icon={
                      item.icon ? (
                        <IcoDown
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleExpand(faqIndex, index)}
                        />
                      ) : (
                        <IcoRight
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleExpand(faqIndex, index)}
                        />
                      )
                    }
                    title={item.question}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
      <Toggle initial={id && id !== 'sonos' && !device?.isOnline}>
        {({ on, toggle }) => (
          <div className="system-details">
            <Button
              modifier="primary"
              onClick={toggle}
              style={{ marginBottom: 50 }}
            >
              Talk to us
            </Button>

            <Modal
              subtitle="Curator"
              title="Talk to us"
              visible={on}
              onClose={toggle}
            >
              <div
                style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 22,
                  }}
                >
                  <h1 style={{ fontSize: 26, marginRight: 28 }}>1</h1>
                  <div>
                    <h3 style={{ fontSize: 16 }}>Select an issue type</h3>
                    <p style={{ margin: 0 }}>Get the right support person</p>
                  </div>
                </div>

                <Dropdown
                  controlClassName="custom-dropdown"
                  arrowClassName="arrow-dropdown"
                  menuClassName="custom-dropdown-menu"
                  arrowClosed={
                    <IcoDown
                      style={{ cursor: 'pointer' }}
                      className="arrow-dropdown"
                    />
                  }
                  arrowOpen={
                    <IcoUp
                      style={{ cursor: 'pointer' }}
                      className="arrow-dropdown"
                    />
                  }
                  options={options}
                  onChange={(e) => setDefaultOption(e)}
                  value={defaultOption}
                  placeholder="Select an option"
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 22,
                  marginTop: 39,
                }}
              >
                <h1 style={{ fontSize: 26, marginRight: 28 }}>2</h1>
                <div>
                  <h3 style={{ fontSize: 16 }}>Detail the problem</h3>
                  <p style={{ margin: 0 }}>Please add any steps you’ve taken</p>
                </div>
              </div>
              <Field
                style={{ marginBottom: 51 }}
                isTextarea
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}
              />

              <div style={{ display: 'flex', width: '75%' }}>
                <Button
                  style={{ flex: 1 }}
                  modifier="primary"
                  onClick={() => {
                    submitForm();
                    toggle();
                  }}
                >
                  Send
                </Button>

                <Button
                  style={{ textAlign: 'center', flex: 1 }}
                  modifier="as-link"
                  onClick={() => {
                    toggle();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Modal>
          </div>
        )}
      </Toggle>
      <ModalSupportStatus
        notificationVisible={notificationVisible}
        setNotificationVisible={setNotificationVisible}
      />
    </DashboardLayout>
  );
};

export default SupportPage;

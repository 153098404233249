import React, { useEffect, useState } from 'react';
import Modal from 'components/modal/modal';
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';
import { toast } from 'react-toastify';

import { FormProvider, useForm } from 'react-hook-form';
import useUpdateQsysZones from './hooks/use-update-qsys-zones';

const QsysModal = ({ on, toggle, zones: initialZones, deviceId }) => {
  const [zones, setZones] = useState(initialZones);
  const { updateQsysZones } = useUpdateQsysZones();
  const methods = useForm({
    mode: 'all',
    defaultValues: zones.reduce((acc, zone) => {
      acc[zone?.zoneId] = zone?.zoneName;
      return acc;
    }, {}),
  });

  useEffect(() => {
    // Reset to initial values when the modal is closed
    if (!on) {
      setZones(initialZones);
    }
  }, [on, initialZones]);

  const handleSubmit = (data) => {
    const transformedData = Object.keys(data).map((key) => {
      const zoneId = Number(key);
      const zoneName = data[key]; // Get the zoneName from the value
      return { zoneId, zoneName };
    });

    updateQsysZones({
      variables: {
        deviceId,
        zones: transformedData,
      },
    })
      .then((res) => {
        // Assuming the response contains the updated zones
        const updatedZones = res.data.updateQsysZones.zones;

        // Update the local state
        setZones(updatedZones);

        // Reset the form values to the updated zones
        methods.reset(
          updatedZones.reduce((acc, zone) => {
            acc[zone?.zoneId] = zone?.zoneName;
            return acc;
          }, {})
        );
        toggle();
        toast.dark('Zone configuration updated successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          bodyStyle: { color: '#fff' },
          style: { backgroundColor: '#670BCE' },
        });
      })
      .catch((err) => {
        if (err.message === 'Too many zones') {
          toggle();
          toast.dark(
            'You have reached the maximum number of zones allowed. Please contact us at support@activaire.com',
            {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: true,
              bodyStyle: { color: '#fff' },
              style: { backgroundColor: '#FF4000' },
            }
          );
        }
      });
  };

  const addZone = () => {
    const newZoneId = -zones.length;
    const newZone = { zoneId: newZoneId, zoneName: '' };
    setZones([...zones, newZone]);
  };
  const removeZone = (zoneId) => {
    setZones(zones.filter((zone) => zone?.zoneId !== zoneId));
  };

  return (
    <Modal
      subtitle="Curator for Q-SYS"
      title="Zone management"
      visible={on}
      onClose={toggle}
    >
      <p>
        Activaire Curator for Q-SYS provides a default of 2 zones per QSC Core.
        The number of authorized zones can be modified from this panel. You can
        enable up to 16 zones, but if you need to manage additional zones,
        please contact us at support@activaire.com
      </p>

      <p>Add, remove or rename your zones here:</p>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(handleSubmit)}
          style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        >
          {zones.map((zone) => (
            <div key={zone?.zoneId} style={{ position: 'relative' }}>
              <FieldValidated
                type="text"
                name={zone?.zoneId}
                placeholder="Zone Name"
                validations={{
                  required: 'Field is required.',
                }}
              />
              <Button
                onClick={() => removeZone(zone?.zoneId)}
                modifier="as-link"
                type="button"
                style={{
                  fontSize: 16,
                  position: 'absolute',
                  bottom: '50%',
                  transform: 'translateY(50%)',
                  right: 20,
                }}
              >
                remove
              </Button>
            </div>
          ))}
          {zones.length <= 16 && (
            <Button
              onClick={addZone}
              modifier="as-link"
              type="button"
              style={{
                fontSize: 16,
                textAlign: 'center',
                marginBottom: 50,
              }}
            >
              + Add zone
            </Button>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button modifier="primary" type="submit">
              Save
            </Button>
            <Button
              modifier="as-link"
              onClick={toggle}
              style={{ padding: '0px 30px' }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>

      <p style={{ marginTop: 50 }}>
        <b>Note</b>: Each active zone will be billed; please check with your
        account representative for additional information.
      </p>
    </Modal>
  );
};

export default QsysModal;

/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SINGLE_PLAYLIST } from '../queries/single-playlist';

export default function useSinglePlaylist(
  playlistId,
  songSkip = 0,
  songLimit = 10,
  deviceId = null
) {
  const { data, loading, error, fetchMore } = useQuery(SINGLE_PLAYLIST, {
    variables: {
      id: playlistId,
      songSkip,
      songLimit,
      ...(deviceId && { deviceId }),
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const playlist = useMemo(
    () =>
      data &&
      !error &&
      data.viewer.allPlaylists.edges.map((playlist) => ({
        id: playlist?.node?.id,
        name: playlist.node?.name,
        cover: playlist.node?.cover,
        tracks: playlist.node?.tracks,
        description: playlist.node?.description,
        allSongs: playlist.node?.allSongs,
        featuredArtists: playlist.node?.featuredArtists,
      })),
    [data, error]
  );

  return {
    playlist: playlist && playlist[0],
    loading,
    error,
    fetchMore,
  };
}

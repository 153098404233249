/**
 * External dependencies
 */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * Internal dependencies
 */
import HighlightsPage from 'domain/highlight/pages/highlights';
import HighlightPage from 'domain/highlight/pages/highlight';

const HighlightRoutes = () => {
  return (
    <Switch>
      <Route exact path="/:id?/highlights" component={HighlightsPage} />

      <Route
        exact
        path="/:id?/highlights/:highlight"
        component={HighlightPage}
      />
    </Switch>
  );
};

export default HighlightRoutes;

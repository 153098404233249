/**
 * External dependencies
 */
import { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SEARCH_PLAYLISTS } from '../queries/search-playlists';
import AuthContext from '../../auth/contexts/auth-context/auth-context';

export default function useSearchPlaylists(options) {
  const { currentClient, currentDevice } = useContext(AuthContext);
  const { data, loading, error } = useQuery(SEARCH_PLAYLISTS, {
    variables: {
      text: options?.text || null,
      clientId: currentClient || options.currentClient,
      deviceId: currentDevice || options.currentDevice,
    },
  });

  const searchedPlaylists = useMemo(
    () =>
      (data &&
        !error &&
        data.viewer.searchPlaylists
          ?.filter((a) => a.__typename === 'Playlist')
          ?.map((playlist) => ({
            id: playlist?.id,
            name: playlist?.name,
            cover: playlist?.cover,
            description: playlist?.description,
          }))) ||
      [],
    [data, error]
  );

  const searchedScenes = useMemo(
    () =>
      (data &&
        !error &&
        data.viewer.searchPlaylists
          ?.filter((a) => a.__typename === 'Scene')
          ?.map((playlist) => {
            return {
              id: playlist?.id,
              name: playlist?.name,
              cover: playlist?.image,
              description: playlist?.description,
            };
          })) ||
      [],
    [data, error]
  );

  return {
    searchedPlaylists,
    searchedScenes,
    loading,
    error,
  };
}

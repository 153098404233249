import { gql } from '@apollo/client';

export const PLAY_SONOS_MIX = gql`
  mutation PlaySonosMixMutation(
    $deviceId: String!
    $mixId: String!
    $mixType: String!
  ) {
    playSonosMix(deviceId: $deviceId, mixId: $mixId, mixType: $mixType)
  }
`;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const USER = gql`
  query UserQuery {
    viewer {
      user {
        id
        profile {
          name
          clientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        featuredPlayables
        defaultDeviceId
        musicProfile {
          category
          subCategory
          genres
          avoidGenres
          artists
          vibe
          explicit
        }
        socialProfile {
          twitter
          linkedIn
          facebook
          spotify
        }
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
          curatorSignOut
        }
        emails {
          address
        }
        serviceType
        defaultServiceType
        companyName
        stripe {
          sonosTrial
          sonosSubscription
          subscriptionPlan
          qsysTrial
          qsysSubscription
          qsysSubscriptionPlan {
            id
          }
        }
        audioClient {
          noVolumeBar
          noPlayPause
        }
        sonos {
          authProcessStatusDone
        }
        qsys {
          pin
          noOfZones
        }
      }
    }
  }
`;

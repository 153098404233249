import rdd from 'react-device-detect';
import { toast } from 'react-toastify';

export const notify = (message, options = {}) => {
  const { redirect } = options;
  if (rdd?.isMobileOnly) {
    if (redirect) {
      window.location.href = 'sonos://something';
    }
    return toast.dark(
      'You will be redirected to the Sonos app to complete the setup.',
      {
        position: 'bottom-center',
        autoClose: 5000,
        closeOnClick: false,
        hideProgressBar: false,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      }
    );
  }
  return toast.dark(message, {
    position: 'bottom-center',
    autoClose: false,
    hideProgressBar: true,
    bodyStyle: { color: '#fff' },
    style: { backgroundColor: '#670BCE' },
  });
};

/**
 * External dependencies
 */
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import { useForm } from 'react-hook-form';

/**
 * Internal dependencies
 */

import useCheckStripeCustomer from 'domain/sonos/hooks/use-check-stripe-customer';
import Button from '../../components/button/button';
import InfoCard from '../../components/info-card/info-card';

import { ReactComponent as LogoSonos } from '../../assets/svg-icons/curator-sonos-logo.svg';
import { ReactComponent as Features } from '../../assets/svg-icons/Features.svg';
import { ReactComponent as Quote } from '../../assets/svg-icons/Quote.svg';
import { ReactComponent as LogoWall } from '../../assets/svg-icons/wall.svg';

const Group = React.lazy(() => import('../../assets/svg-icons/Group'));

import { ReactComponent as Small1 } from '../../assets/svg-icons/small1.svg';
import { ReactComponent as Small2 } from '../../assets/svg-icons/small2.svg';
import { ReactComponent as Small3 } from '../../assets/svg-icons/small3.svg';
import { ReactComponent as Small4 } from '../../assets/svg-icons/small4.svg';
import { ReactComponent as Small5 } from '../../assets/svg-icons/small5.svg';
import { ReactComponent as Small6 } from '../../assets/svg-icons/small6.svg';

import { ReactComponent as QuoteMobile } from '../../assets/svg-icons/quote-mobile.svg';
import { ReactComponent as Clients } from '../../assets/svg-icons/clients.svg';
import useAuth from 'domain/auth/hooks/use-auth';

import BoardingContextProvider from './context/boardingContext';
import BoardingModal from './boarding-modal';

const SonosTrialScreen = () => {
  const history = useHistory();
  const [boardingModal, setBoardingModal] = useState(false);
  const [loadingTrial, setLoadingTrial] = useState(false);
  const { user, sonosFlow, setSonosFlow, decodedToken, setDecodedToken } =
    useAuth();

  const [isWizardCompleted, setIsWizardCompleted] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      companyName: '',
    },
  });

  const { checkStripeCustomer, stripeCheckData } = useCheckStripeCustomer();

  useEffect(() => {
    console.log('isWizardCompleted useEffect', isWizardCompleted);
    if (isWizardCompleted) {
      checkStripeCustomer()
        .then((data) => {
          handleCloseModal();
        })
        .catch((err) => {
          console.log('checkStripeCustomer error:', err);
        });
    }
  }, [isWizardCompleted]);

  useEffect(() => {
    if (user?.profile?.sonosPermit) {
      history.push('/sonos/dashboard?sonosTrial=activate');
    }
  }, [user?.profile?.sonosPermit]);

  useEffect(() => {
    console.log('stripeCheckData useEffect', stripeCheckData);
    if (stripeCheckData?.checkStripeCustomer === 'no company name') {
      setBoardingModal(true);
    }
    if (stripeCheckData?.checkStripeCustomer === 'OK' && rdd?.isMobileOnly) {
      setDecodedToken({});
      window.location.href = 'sonos://something';
    } else if (stripeCheckData?.checkStripeCustomer === 'OK') {
      setDecodedToken({});
      history.push('/sonos/dashboard?sonosTrial=activate');
    }
  }, [stripeCheckData]);

  useEffect(() => {
    if (sonosFlow) {
      setSonosFlow(false);
      acceptTerms();
    }
  }, [sonosFlow]);

  const acceptTerms = () => {
    setLoadingTrial(true);
    if (!isWizardCompleted) {
      setBoardingModal(true);
    } else {
      checkStripeCustomer();
    }
  };

  const handleCloseModal = () => {
    setBoardingModal(false);
    setLoadingTrial(false);
  };

  const ref = useRef();

  return (
    <DashboardLayout
      subtitle="Curator"
      title="Sonos"
      backgroundImage={`url(${
        require('../../assets/svg-icons/curator-background.svg').default
      })`}
      modifier="devices"
      showSwitcher={
        user?.serviceType?.includes('CURATOR') &&
        user?.serviceType?.includes('SONOS')
      }
      // onScroll={(e) => console.log(e)}
    >
      <div ref={ref}>
        {/* // =================== FIRST CARD =================== /// */}
        <InfoCard
          reverse
          color="#F4C042"
          image={require('../../assets/images/iphone-sonos.png').default}
          styles={{ height: 'auto' }}
        >
          <LogoSonos style={{ marginBottom: 20 }} />
          <h1>SUPERCHARGE YOUR CUSTOMER EXPERIENCE.</h1>
          <h2 style={{ fontSize: 24, color: '#0B11CE', marginBottom: 20 }}>
            Monthly subscriptions start at $39 per zone.
          </h2>
          <h3 style={{ fontSize: 16, color: '#090E4F', marginBottom: 50 }}>
            Discover unparalleled music selections from Activaire Curator and
            exquisite audio from Sonos sound system.
          </h3>
          {errors.checkbox && (
            <p style={{ margin: 0, color: '#E96187', fontSize: 13 }}>
              Please accept in order to proceed.
            </p>
          )}
          <p
            className="available-text"
            style={{ color: '#090E4F', opacity: 0.75, marginTop: 5 }}
          >
            Activaire Curator for Sonos is available for businesses in the US
            and Canada.
          </p>
          <Button modifier="blue" onClick={handleSubmit(acceptTerms)}>
            {!loadingTrial ? 'Start Your Free Trial*' : 'Loading...'}
          </Button>
          <p
            className="available-text"
            style={{ color: '#090E4F', opacity: 0.75, marginTop: 5 }}
          >
            *No Credit Card Necessary
          </p>

          <BoardingContextProvider>
            <BoardingModal
              boardingModal={boardingModal}
              setIsWizardCompleted={setIsWizardCompleted}
              handleCloseModal={handleCloseModal}
            />
          </BoardingContextProvider>
        </InfoCard>

        {/* // =================== SECOND CARD =================== // */}

        <div className="mobile-group-svg">
          {/* <Frame style={{ marginBottom: 10 }} /> */}
          <div style={{ position: 'relative' }}>
            <Button
              modifier="primary"
              onClick={handleSubmit(acceptTerms, () =>
                ref.current.scrollIntoView({ behavior: 'smooth' })
              )}
              style={{ position: 'absolute', bottom: 40, left: 36 }}
            >
              Get started
            </Button>
            <Suspense fallback={<div></div>}>
              <Group style={{ marginBottom: 10, padding: '0px 10px' }} />
            </Suspense>
          </div>

          <Small1 style={{ marginBottom: 10, padding: '0px 10px' }} />
          <Small2 style={{ marginBottom: 10, padding: '0px 10px' }} />
          <Small3 style={{ marginBottom: 10, padding: '0px 10px' }} />
          <Small4 style={{ marginBottom: 10, padding: '0px 10px' }} />
          <Small5 style={{ marginBottom: 10, padding: '0px 10px' }} />
          <Small6 style={{ marginBottom: 30, padding: '0px 10px' }} />
          <QuoteMobile style={{ marginBottom: 30, padding: '0px 10px' }} />
          <div style={{ position: 'relative' }}>
            <img
              src={require('../../assets/images/img.png').default}
              style={{ position: 'absolute' }}
            />
            <Clients style={{ marginBottom: 100, padding: '0px 10px' }} />
          </div>
        </div>

        <div className="get-started">
          <img
            style={{ borderTopLeftRadius: 13, borderBottomLeftRadius: 13 }}
            src={require('../../assets/images/guest-sparkle.png').default}
          />
          <div
            style={{
              padding: '30px 61px 40px 85px',
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'column',
            }}
          >
            <h1 style={{ fontSize: 24, fontWeight: 'bold' }}>
              Get amazing music and amazing sound. Activaire Curator on Sonos.
            </h1>
            <p style={{ lineHeight: '20px' }}>
              Activaire offers a truly authentic curation service, with music
              selected by humans and delivered by code. We’re bringing the
              forefront of technology to background music, creating something
              beautiful that will excite everyone who walks through your door.
            </p>
            <Button
              modifier="primary"
              onClick={handleSubmit(acceptTerms, () =>
                ref.current.scrollIntoView({ behavior: 'smooth' })
              )}
              style={{ alignSelf: 'flex-start' }}
            >
              Get started
            </Button>
          </div>
        </div>

        {/* // =================== SECOND CARD =================== // */}

        {/* // =================== SVGS =================== // */}
        <div className="desktop-group-svg">
          <Features style={{ marginBottom: 180, width: '100%' }} />
          <Quote style={{ width: '100%', marginBottom: 100 }} />

          <div style={{ position: 'relative' }}>
            <img
              src={require('../../assets/images/img.png').default}
              style={{ position: 'absolute', width: 650, height: 550 }}
            />
            <LogoWall style={{ width: '100%', marginBottom: 100 }} />
          </div>
        </div>

        {/* // =================== SVGS =================== // */}
      </div>
    </DashboardLayout>
  );
};

export default SonosTrialScreen;

import React, { useContext } from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import Card from './components/card';

const Account = () => {
  const { user } = useContext(AuthContext);

  const permissions = [
    'Limited',
    'Super Admin',
    'Super User',
    'Admin',
    'Power User',
    'Authorized User',
  ];

  return (
    <DashboardLayout title="Profile" modifier="profile">
      <Card color="#0B72CE" height={490}>
        {rdd?.isMobileOnly ? null : (
          <img
            src={require('../../../assets/images/account.png').default}
            style={{ height: '100%', width: '50%' }}
          />
        )}
        <div
          style={{
            // flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            height: 250,
            padding: 50,
            flexDirection: 'column',
            width: '50%',
          }}
        >
          {/* <LoadingProvider loading={!count}> */}
          <h3 style={{ fontSize: 23, marginBottom: 20 }}>Your Details</h3>

          <div style={{ marginBottom: 20 }}>
            <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
              Name
            </p>
            <p style={{ fontSize: 16, lineHeight: '20px' }}>
              {user?.profile?.name}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
              Email
            </p>
            <p style={{ fontSize: 16, lineHeight: '20px' }}>
              {user?.emails[0]?.address}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
              Company
            </p>
            <p style={{ fontSize: 16, lineHeight: '20px' }}>
              {user?.companyName || ''}
            </p>
          </div>

          <div style={{ marginBottom: 20 }}>
            <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
              Device permissions
            </p>
            <p style={{ fontSize: 16, lineHeight: '20px' }}>
              {permissions[user?.rolesConsole?.roleAudio]}
            </p>
          </div>

          <div>
            <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
              Sonos authorization
            </p>
            <p style={{ fontSize: 16, lineHeight: '20px' }}>
              {user?.profile?.sonosPermit ? 'Yes' : 'No'}
            </p>
          </div>

          <div>
            <p style={{ fontSize: 12, opacity: 0.7, lineHeight: '20px' }}>
              Q-SYS authorization
            </p>
            <p style={{ fontSize: 16, lineHeight: '20px' }}>
              {user?.profile?.qsysPermit ? 'Yes' : 'No'}
            </p>
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Account;

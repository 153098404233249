/**
 * External dependencies
 */
import React from 'react';
import _ from 'lodash';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import ResultItem from 'components/result-item/result-item';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllPlaylists from 'domain/music/hooks/use-all-playlists';
import getRandomColor from 'helpers/get-random-color';
import Card from '../components/card';

const AllGenres = ({ deviceId, sonosDeviceId }) => {
  const isSonosDevice = !!sonosDeviceId;

  const { allPlaylists, loading: loadingPlaylists } = useAllPlaylists({
    deviceId: isSonosDevice ? sonosDeviceId : deviceId,
  });

  const allMixes = allPlaylists;

  const allGenres = _.uniq(
    _.flattenDeep(
      allMixes?.map((playlist) => (playlist?.genre ? [...playlist.genre] : []))
    ).map((item) => item.toUpperCase())
  );

  return (
    <LoadingProvider loading={loadingPlaylists}>
      {rdd?.isMobileOnly ? (
        <div
          style={{
            width: '100vw',
            marginBottom: 135,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: 13,
            padding: '0px 13px',
          }}
        >
          {!loadingPlaylists &&
            allGenres?.sort().map((genre, index) => {
              return (
                <Card
                  index={index}
                  key={genre}
                  title={genre}
                  to={
                    isSonosDevice
                      ? `/sonos/${sonosDeviceId}/search/all/genres/${genre}`
                      : `/${deviceId}/search/all/genres/${genre}`
                  }
                />
              );
            })}
        </div>
      ) : (
        <div className="list-results-small">
          {!loadingPlaylists &&
            allGenres?.length > 0 &&
            allGenres
              .sort()
              .map((item) => (
                <ResultItem
                  color={getRandomColor(_.random(14))}
                  key={item}
                  to={
                    isSonosDevice
                      ? `/sonos/${sonosDeviceId}/search/all/genres/${item}`
                      : `/${deviceId}/search/all/genres/${item}`
                  }
                  title={item}
                  hasImage
                />
              ))}
        </div>
      )}
    </LoadingProvider>
  );
};

export default AllGenres;

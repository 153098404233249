import React from 'react';

export function DeviceButton({ text, selected, onClick }) {
  return (
    <button
      onClick={() => onClick()}
      className={
        selected ? 'play-now-list-item_selected' : 'play-now-list-item'
      }
    >
      <p style={{ marginBottom: 0 }}>{text}</p>
    </button>
  );
}

/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';

/**
 * Internal dependencies.
 */
import portalsContainer from 'helpers/portals-container';
import { ReactComponent as IcoX } from '../../assets/svg-icons/ico-x.svg';
import { ReactComponent as IcoLoading } from '../../assets/svg-icons/off-piste.svg';

const ModalSupportStatus = ({
  notificationVisible,
  setNotificationVisible,
}) => {
  if (!notificationVisible) return null;

  return createPortal(
    <div className="modal-status" style={{ backgroundColor: '#670BCE' }}>
      <button
        onClick={() => setNotificationVisible(false)}
        className="modal-status__close"
      >
        <IcoX />
      </button>
      <h6
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#ffffff',
        }}
      >
        <IcoLoading style={{ marginRight: 10 }} />
        Great! That's been sent
      </h6>
      <h4 style={{ marginLeft: 28 }}>
        We’ll get back to you as soon as possible within the next 24hrs
      </h4>
    </div>,
    portalsContainer
  );
};

export default ModalSupportStatus;

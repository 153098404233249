/**
 * External dependencies
 */
import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import * as rdd from 'react-device-detect';
import {
  NavLink,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

/**
 * Internal dependencies
 */
import PoweredBy from 'components/powered-by/powered-by';
import LogoutButton from 'components/logout-button/logout-button';

import SwitchSelector from 'react-switch-selector';

import { ReactComponent as IcoBack } from 'assets/svg-icons/ico-back.svg';
import { ReactComponent as IcoHome } from 'assets/svg-icons/ico-home.svg';
import { ReactComponent as IcoBell } from 'assets/svg-icons/ico-bell.svg';
import { ReactComponent as IcoMusic } from 'assets/svg-icons/ico-music.svg';
import { ReactComponent as IcoSupport } from 'assets/svg-icons/ico-support.svg';
import { ReactComponent as IcoProfile } from 'assets/svg-icons/ico-profile.svg';
import { ReactComponent as IcoDevices } from 'assets/svg-icons/ico-devices.svg';

import Button from 'components/button/button';

import { ReactComponent as IcoX } from 'assets/svg-icons/ico-x.svg';
import useAuth from 'domain/auth/hooks/use-auth';

import useToggleServiceType from 'domain/sonos/hooks/use-toggle-service-type';
import TrialModal from 'domain/sonos/trial-modal';

import _ from 'lodash';
import PlayNowModal from '../components/play-now-modal/play-now-modal';
import MusicSearchInput from '../domain/music/components/music-search-input';
import DisconnectedModal from '../components/modal-status/modal-disconnected';

function useQuery() {
  const URLSearchParams = window.URLSearchParams;
  return new URLSearchParams(useLocation().search);
}

const DashboardLayout = ({
  children,
  subtitle = 'Curator',
  title,
  backgroundColor,
  modifier,
  onScroll,
  isSinglePlaylist = false,
  playlistInfo,
  isOnMusicRoute,
  sonosDeviceIdProp,
}) => {
  const {
    path,
    params: { id, sonosDeviceId },
  } = useRouteMatch();

  const isSonosDevice = !!sonosDeviceId || !!sonosDeviceIdProp;
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  let query = useQuery();
  const [sonosTrial, setSonosTrial] = useState(query.get('sonosTrial'));
  const [trialModal, setTrialModal] = useState(false);

  const { user, setUser } = useAuth();

  const handleGoBack = (e) => {
    if (
      path === '/' ||
      path === '/:id' ||
      path === '/:id/dashboard' ||
      path === '/qsys/:id'
    )
      return;

    history.goBack();

    e.preventDefault();
  };

  const { toggleServiceType } = useToggleServiceType();
  const [randomNumber, setRandomNumber] = useState();

  const [iconBackground, setIconBackground] = useState('transparent');

  useEffect(() => {
    setRandomNumber(_.random(-420, -100));
  }, []);

  useEffect(() => {
    if (sonosTrial === 'activate') {
      const newUser = JSON.parse(JSON.stringify(user));
      newUser.profile.sonosPermit = true;
      if (newUser.stripe) {
        newUser.stripe.sonosTrial = true;
      }
      setUser(newUser);
      setTrialModal(true);
      setSonosTrial(false);
    }
  }, [sonosTrial]);

  const dashboardRef = React.useRef();

  useLayoutEffect(() => {
    dashboardRef?.current?.addEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });

    dashboardRef?.current?.removeEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });
  }, []);

  const handleSwitch = (e) => {
    if (e === 'SONOS') {
      toggleServiceType({ variables: { serviceType: e } });
      history.push('/sonos');
      setUser(Object.assign({}, user, { defaultServiceType: e }));
    } else if (e === 'QSYS') {
      toggleServiceType({ variables: { serviceType: e } });
      history.push('/qsys');
      setUser(Object.assign({}, user, { defaultServiceType: e }));
    } else {
      toggleServiceType({ variables: { serviceType: e } });
      history.push('/');
      setUser(Object.assign({}, user, { defaultServiceType: e }));
    }
  };

  const handleOnCloseModal = () => {
    setTrialModal(false);
    if (rdd?.isMobileOnly) {
      window.location.href = 'sonos://something';
    }
  };
  // {
  //   label: 'Curator',
  //   value: 'CURATOR',
  //   selectedBackgroundColor: '#fff',
  // },
  // {
  //   label: 'Sonos',
  //   value: 'SONOS',
  //   selectedBackgroundColor: '#fff',
  // },
  // {
  //   label: 'Q-SYS',
  //   value: 'QSYS',
  //   selectedBackgroundColor: '#fff',
  // },
  const [switcherOptions, setSwitcherOptions] = useState([]);

  useEffect(() => {
    if (!user?.serviceType) {
      setSwitcherOptions([]); // No user, so no options
      return;
    }
    if (user.serviceType?.length === 1) {
      setSwitcherOptions([]); // If just one service type show no switcher
      return;
    }

    const options = user.serviceType.map((item) => {
      const label = item === 'QSYS' ? 'Q-SYS' : _.capitalize(item);
      return {
        label,
        value: item,
        selectedBackgroundColor: '#fff',
      };
    });

    setSwitcherOptions(options);
  }, [user?.serviceType]);

  let initialSelectedIndex = 0;

  if (pathname.startsWith('/sonos')) {
    initialSelectedIndex = switcherOptions.findIndex(
      (item) => item.value === 'SONOS'
    );
  } else if (pathname.startsWith('/qsys')) {
    initialSelectedIndex = switcherOptions.findIndex(
      (item) => item.value === 'QSYS'
    );
  }
  const shouldShowSwitcher =
    (pathname === '/' ||
      pathname.startsWith('/sonos/dashboard') ||
      pathname.startsWith('/qsys')) &&
    !id;

  const isQsysPath = pathname.startsWith('/qsys');

  return (
    <div className="dashboard" onScroll={onScroll} ref={dashboardRef}>
      {path !== '/' && path !== '/sonos/dashboard' && (
        <NavLink
          onClick={handleGoBack}
          to="/"
          className="mobile-icon"
          style={{
            top: window.localStorage.getItem('notification') === '1' ? 45 : 10,
            backgroundColor: iconBackground,
          }}
        >
          {path === '/:id' || path === '/:id/dashboard' ? (
            <IcoX />
          ) : (
            <IcoBack />
          )}
        </NavLink>
      )}
      {isSinglePlaylist && !isQsysPath && (
        <PlayNowModal
          playlistId={playlistInfo?.devicePlayTypeId}
          devicePlayType={playlistInfo?.devicePlayType}
        >
          <Button modifier="primary" className="playlist-play-now">
            Play Now
          </Button>
        </PlayNowModal>
      )}
      <div className="dashboard__sidebar">
        <TrialModal visible={trialModal} onClose={() => handleOnCloseModal()} />
        <ul>
          {path !== '/sonos/dashboard' && (
            <li>
              <NavLink
                onClick={handleGoBack}
                to={isQsysPath ? '/qsys/zones' : '/'}
                activeClassName="is-current"
                exact={true}
              >
                {path === '/' ||
                path === '/qsys/:id' ||
                path === '/qsys/zones' ? (
                  <IcoDevices />
                ) : (
                  <IcoBack />
                )}

                <span className="dashboard__sidebar_text">
                  {path === '/' ||
                  path === '/:id' ||
                  path === '/qsys/:id' ||
                  path === '/qsys/zones'
                    ? isQsysPath
                      ? 'Zones'
                      : 'Devices'
                    : 'Back'}
                </span>
              </NavLink>
            </li>
          )}
          {id && id !== 'sonos' && !isQsysPath && (
            <>
              <li>
                <NavLink
                  to={`/${id}/dashboard`}
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoHome />
                  <span className="dashboard__sidebar_text">Playing</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/${id}`}
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoMusic />
                  <span className="dashboard__sidebar_text">Music</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  exact={true}
                  to={`/${id}/highlights`}
                  activeClassName="is-current"
                >
                  <IcoBell />
                  <span className="dashboard__sidebar_text">Blog</span>
                </NavLink>
              </li>
            </>
          )}
          {/* QSYS SIDEBAR */}
          {(!id || id === 'qsys') && isQsysPath && (
            <>
              <li>
                <NavLink
                  exact={true}
                  to={`/qsys/support`}
                  activeClassName="is-current"
                >
                  <IcoSupport />
                  <span className="dashboard__sidebar_text">Support</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  exact={true}
                  to={`/qsys/profile`}
                  activeClassName="is-current"
                >
                  <IcoProfile />
                  <span className="dashboard__sidebar_text">Profile</span>
                </NavLink>
              </li>
            </>
          )}
          {id && id !== 'qsys' && isQsysPath && (
            <>
              <li>
                <NavLink
                  to={`/qsys/${id}/dashboard`}
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoHome />
                  <span className="dashboard__sidebar_text">Playing</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/qsys/${id}`}
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoMusic />
                  <span className="dashboard__sidebar_text">Music</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to={`/qsys/${id}/highlights`}
                  activeClassName="is-current"
                >
                  <IcoBell />
                  <span className="dashboard__sidebar_text">Blog</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to={`/qsys/${id}/support`}
                  activeClassName="is-current"
                >
                  <IcoSupport />
                  <span className="dashboard__sidebar_text">Support</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  exact={true}
                  to={`/qsys/${id}/profile`}
                  activeClassName="is-current"
                >
                  <IcoProfile />
                  <span className="dashboard__sidebar_text">Profile</span>
                </NavLink>
              </li>
            </>
          )}
          {/* QSYS SIDEBAR */}

          {id !== 'sonos' && !path.includes('sonos') && !isQsysPath && (
            <>
              <li>
                <NavLink
                  to={id ? `/${id}/support` : '/support'}
                  activeClassName="is-current"
                >
                  <IcoSupport />
                  <span className="dashboard__sidebar_text">Support</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={id ? `/${id}/profile` : '/profile'}
                  activeClassName="is-current"
                >
                  <IcoProfile />
                  <span className="dashboard__sidebar_text">Profile</span>
                </NavLink>
              </li>
            </>
          )}
          {((id && id === 'sonos') || path.includes('sonos')) && (
            <>
              <li>
                <NavLink
                  to={'/sonos'}
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoHome />
                  <span className="dashboard__sidebar_text">Home</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sonos/highlights`} activeClassName="is-current">
                  <IcoBell />
                  <span className="dashboard__sidebar_text">Highlights</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    id && id !== 'sonos'
                      ? `/${id}/support`
                      : `/sonos/${sonosDeviceId || sonosDeviceIdProp}`
                  }
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoMusic />
                  <span className="dashboard__sidebar_text">Music</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    id && id !== 'sonos' ? `/${id}/support` : '/sonos/support'
                  }
                  activeClassName="is-current"
                >
                  <IcoSupport />
                  <span className="dashboard__sidebar_text">Support</span>
                </NavLink>
              </li>

              <li>
                <NavLink to={'/sonos/profile'} activeClassName="is-current">
                  <IcoProfile />
                  <span className="dashboard__sidebar_text">Profile</span>
                </NavLink>
              </li>
            </>
          )}
          <li>
            <LogoutButton />
          </li>
        </ul>

        <span className="dashboard__sidebar_text">
          <PoweredBy />
        </span>
      </div>

      {/*              MOBILE NAV             */}
      <ul className="mobile-default-nav">
        {((id && id === 'sonos') || path.includes('sonos')) && (
          <>
            <li>
              <NavLink to={'/sonos'} activeClassName="is-current" exact={true}>
                <IcoHome style={{ marginBottom: 10 }} />
                Home
              </NavLink>
            </li>
            <span
              style={{
                backgroundColor: '#fff',
                height: 50,
                width: 1,
                opacity: 0.25,
              }}
            ></span>
            <li>
              <NavLink
                to={`/sonos/${sonosDeviceId || sonosDeviceIdProp}`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoMusic style={{ marginBottom: 10 }} />
                Music
              </NavLink>
            </li>
            <li>
              <NavLink to={`/sonos/highlights`} activeClassName="is-current">
                <IcoBell style={{ marginBottom: 10 }} />
                Highlights
              </NavLink>
            </li>

            <li>
              <NavLink
                to={id && id !== 'sonos' ? `/${id}/support` : '/sonos/support'}
                activeClassName="is-current"
              >
                <IcoSupport style={{ marginBottom: 10 }} />
                Support
              </NavLink>
            </li>

            <li>
              <NavLink to={'/sonos/profile'} activeClassName="is-current">
                <IcoProfile style={{ marginBottom: 10 }} />
                Profile
              </NavLink>
            </li>
          </>
        )}

        {id && id !== 'sonos' && !isQsysPath && (
          <>
            <li>
              <NavLink
                to={`/${id}/dashboard`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoHome style={{ marginBottom: 10 }} />
                Playing
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${id}`} activeClassName="is-current" exact={true}>
                <IcoMusic style={{ marginBottom: 10 }} />
                Music
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/${id}/highlights`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoBell style={{ marginBottom: 10 }} />
                Blog
              </NavLink>
            </li>
          </>
        )}
        {id && id !== 'qsys' && isQsysPath && (
          <>
            <li>
              <NavLink
                to={`/qsys/${id}/dashboard`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoHome style={{ marginBottom: 10 }} />
                Playing
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/qsys/${id}`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoMusic style={{ marginBottom: 10 }} />
                Music
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/qsys/${id}/highlights`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoBell style={{ marginBottom: 10 }} />
                Blog
              </NavLink>
            </li>

            <li>
              <NavLink to={`/qsys/${id}/support`} activeClassName="is-current">
                <IcoSupport style={{ marginBottom: 10 }} />
                Support
              </NavLink>
            </li>
            <li>
              <NavLink to={`/qsys/${id}/profile`} activeClassName="is-current">
                <IcoProfile style={{ marginBottom: 10 }} />
                Profile
              </NavLink>
            </li>
          </>
        )}

        {id !== 'sonos' && !path.includes('sonos') && !isQsysPath && (
          <>
            {path === '/' && (
              <li>
                <NavLink
                  onClick={handleGoBack}
                  to="/"
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoDevices style={{ marginBottom: 10 }} />

                  {'Devices'}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to={id ? `/${id}/support` : '/support'}
                activeClassName="is-current"
              >
                <IcoSupport style={{ marginBottom: 10 }} />
                Support
              </NavLink>
            </li>

            <li>
              <NavLink to={'/profile'} activeClassName="is-current">
                <IcoProfile style={{ marginBottom: 10 }} />
                Profile
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* MOBILE NAV */}

      <div className="dashboard__content">
        <div className={`page page--${modifier}`}>
          <div
            className="page__background"
            style={{ backgroundColor: backgroundColor }}
          >
            <div
              className="page__background-image"
              style={{
                backgroundImage: `url(${
                  require('../assets/svg-icons/curator-background.svg').default
                })`,
                left: randomNumber + '%',
              }}
            ></div>
          </div>

          <div
            className="page__head"
            style={
              isSinglePlaylist
                ? { position: 'absolute', top: -10, zIndex: 5 }
                : {}
            }
          >
            <div
              style={{
                maxWidth: 980,
                // width: rdd?.isMobileOnly || rdd.isTablet ? 'auto' : 980,
                width: 'auto',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h5>{isSinglePlaylist ? <br /> : subtitle}</h5>

                <h2>{title}</h2>
              </div>
              {isOnMusicRoute && (
                <MusicSearchInput id={id} sonosDeviceId={sonosDeviceId} />
              )}
            </div>
            {user && shouldShowSwitcher && (
              <div className="switcher" style={{ minWidth: 200, height: 38 }}>
                <SwitchSelector
                  onChange={handleSwitch}
                  options={switcherOptions}
                  forcedSelectedIndex={initialSelectedIndex}
                  backgroundColor={'transparent'}
                  fontColor={'grey'}
                  selectedFontColor={'#000'}
                  border={'1px solid #fff'}
                  selectionIndicatorMargin={0}
                  wrapperBorderRadius={13}
                  optionBorderRadius={12}
                />
              </div>
            )}
          </div>
          {/* <DisconnectedModal /> */}
          <div className="page__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardLayout);

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation(
    $newPassword: String!
    $recoveryToken: String!
  ) {
    resetPassword(password: $newPassword, recoveryToken: $recoveryToken) {
      id
    }
  }
`;

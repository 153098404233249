/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Player from '../components/player';
import CardLink from 'components/card-link/card-link';

import PauseSvg from '../../../assets/svgComponents/PauseSvg';
import SkipSvg from '../../../assets/svgComponents/SkipSvg';
import PlaySvg from '../../../assets/svgComponents/PlaySvg';

import { toast, Slide } from 'react-toastify';

const customId = 'prevent-duplicate';

import _ from 'lodash';
import SearchSection from '../../../components/search-section/search-section';
import * as rdd from 'react-device-detect';

import CurrentlyPlayingCard from '../components/currently-playing-card';
import CurrentlyPlayingCardMobile from '../components/currently-playing-card-mobile';
import useAllQsysDevices from '../hooks/use-all-qsys-devices';
import useTriggerQsysAction from '../hooks/use-trigger-qsys-action';
import useAuth from '../../auth/hooks/use-auth';
import useQsysDeviceSubscription from '../hooks/use-qsys-device-subscription';
import VolumeSvg from '../../../assets/svgComponents/VolumeSvg';
import ProgressRangeFooter from '../../../components/volume-slider/volume-slider-footer-qsys';

const colors = _.shuffle([
  '#3B46AB',
  '#3B7DAB',
  '#0B72CE',
  '#E96187',
  '#E961CB',
  '#C361E9',
  '#7F61E9',
  '#6187E9',
  '#FFBF00',
  '#FF4000',
  '#FF0040',
  '#0B11CE',
  '#3BAB69',
  '#0BCEC9',
  '#0BCE69',
]);

const getColors = [
  colors[_.random(0, 4)],
  colors[_.random(4, 7)],
  colors[_.random(7, 10)],
  colors[_.random(11, 14)],
];

const DashboardPage = ({
  match: {
    params: { id },
  },
}) => {
  const { allQsysDevices } = useAllQsysDevices();

  const { qsysDevicesSubscription, qsysDevicesSubscriptionLoading } =
    useQsysDeviceSubscription();

  const [zone, setZone] = useState(
    allQsysDevices[0]?.zones?.find((zone) => zone?.zoneId.toString() === id)
  );

  const { currentDevice } = useAuth();
  const { triggerQsysAction } = useTriggerQsysAction();

  useEffect(() => {
    if (!qsysDevicesSubscriptionLoading) {
      const getZones =
        qsysDevicesSubscription?.QsysDeviceSubscription?.node?.zones || [];
      const actualZone = getZones.find((item) => item.zoneId === Number(id));
      setZone(actualZone);
    }
  }, [qsysDevicesSubscription]);

  const notifySkipLimit = () =>
    toast.warning('Sorry, you’re allowed only 3 skips per hour.', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      toastId: customId,
      style: { backgroundColor: '#E96187' },
      bodyStyle: { fontSize: 16, color: '#fff' },
      transition: Slide,
    });

  const notifySkipSong = () =>
    toast.info('Song skipped', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      toastId: customId,
      style: { backgroundColor: '#670BCE' },
      bodyStyle: { fontSize: 16, color: '#fff' },
      transition: Slide,
    });

  const handleReason = (reason) => {
    triggerQsysAction({
      variables: {
        deviceId: currentDevice,
        zoneId: Number(id),
        actions: {
          reason,
        },
      },
    });
  };

  const [displayMode, setDisplayMode] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <DashboardLayout
      subtitle="Zones"
      title={zone?.zoneName}
      backgroundImage={`url(${
        require('assets/svg-icons/background-O.svg').default
      })`}
      modifier={cx('dashboard')}
    >
      <Player
        zone={zone}
        loading={!zone}
        title={zone?.currentTitle || null}
        author={zone?.currentArtist || null}
        album={zone?.currentAlbum || null}
        cover={zone?.currentArtwork || null}
        lastOnline={null}
        pausedStatus={''}
        hasSchedule={null}
        isPlaying={zone?.reason !== 'pause'}
        // manuallyPaused={device?.manuallyPaused}
        isOnline={true}
        hideVolumeBar={true}
      />

      <div
        style={{
          height: 80,
          maxWidth: 980,
          backgroundColor: '#000',
          borderRadius: 13,
          marginBottom: 30,
          marginLeft: rdd?.isMobileOnly ? 13 : 0,
          marginRight: rdd?.isMobileOnly ? 13 : 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: displayMode ? 'space-between' : 'center',
        }}
      >
        <>
          <VolumeSvg />
          <ProgressRangeFooter
            deviceId={currentDevice}
            volume={zone?.volume || 0}
            zoneId={Number(id)}
          />
        </>
        {zone?.reason === 'pause' ? (
          <PlaySvg
            onClick={() => handleReason('play')}
            style={{ margin: '0px' }}
          />
        ) : (
          <PauseSvg
            onClick={() => handleReason('pause')}
            style={{ marginRight: '16px' }}
          />
        )}
        <SkipSvg onClick={() => handleReason('skip')} />
      </div>

      {!rdd?.isMobileOnly ? (
        <SearchSection title={'Current Playlist'}>
          <CurrentlyPlayingCard zone={zone} />
        </SearchSection>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            margin: '13px 13px 68px 13px',
          }}
        >
          <h1 style={{ fontSize: 16, marginBottom: 20 }}>
            Playing off schedule
          </h1>
          <CurrentlyPlayingCardMobile zone={zone} />
        </div>
      )}

      {!rdd?.isMobileOnly ? (
        <SearchSection title={'Device Management'}>
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridGap: 20,
              marginBottom: 20,
            }}
          >
            <CardLink
              to={`/qsys/${id}/dashboard/information`}
              title="Device Info"
              description="See users of this device and other additional information."
              color={getColors[3]}
              graphic={
                require('../../../assets/images/shop-parkle2.png').default
              }
            />
          </div>
        </SearchSection>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 120,
          }}
        >
          <h1 style={{ fontSize: 16, marginBottom: 20, marginLeft: 13 }}>
            Device Management
          </h1>
          <div
            style={{ overflow: 'auto', whiteSpace: 'nowrap', display: 'flex' }}
          >
            <CardLink
              style={{
                height: 300,
                width: 320,
                padding: '40px 26px',
                display: 'inline-block',
                whiteSpace: 'normal',
                marginLeft: 13,
              }}
              to={`/qsys/${id}/dashboard/information`}
              title="Device Info"
              description="See users of this device and other additional information."
              color={getColors[3]}
              graphic={
                require('../../../assets/images/shop-parkle2.png').default
              }
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default DashboardPage;

/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { Toggle } from 'react-powerplug';
import _ from 'lodash';

import Button from '../../../components/button/button';
import Modal from '../../../components/modal/modal';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Map from 'components/map/map';
import Devices from 'domain/devices/blocks/devices';
import Device from 'components/device/device';
import useAllAudioDevices from 'domain/devices/hooks/use-all-audio-devices';
import useSearchDevices from 'domain/devices/hooks/use-search-devices';
import useDevicesSubscription from 'domain/devices/hooks/use-devices-subscription';
import LoadingProvider from 'components/loading-provider/loading-provider';
import Field from 'components/field/field';
import moment from 'moment';
import FormAddDevice from '../../../blocks/forms/form-add-device';
import { useHistory } from 'react-router-dom';
import useUser from 'domain/auth/hooks/use-user';
import getRandomColor from '../../../helpers/get-random-color';
import NoDevicesModal from '../../../components/modal-status/modal-no-devices';
import SearchSvg from '../../../assets/svgComponents/SearchSvg';
import * as rdd from 'react-device-detect';
import useActivateQsysService from '../hooks/use-activate-qsys-service';
import useActivateSonosService from '../hooks/use-activate-sonos-service';
import { toast } from 'react-toastify';
import useAuth from '../../auth/hooks/use-auth';

/**
 * DevicesPage component
 * This component is responsible for rendering the devices page.
 * It includes functionality for searching devices, loading more devices on scroll, and handling device status updates.
 */
const DevicesPage = () => {
  const history = useHistory();
  // Pagination settings:
  const first = 27; // max between this value and count of favourite devices
  const offset = 18;

  // State variables
  const [searchText, setSearchText] = useState('');
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [resultField, setResultField] = useState('');
  const [displayLoading, setDisplayLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Custom hooks for fetching user data and devices data
  const { data: user } = useUser();
  const { allSearchedDevices, loading: loadingSearch } = useSearchDevices({
    searchValue: searchText,
    // fetchPolicy: 'cache-first',
  });
  const {
    allDevices,
    pageInfo,
    loading,
    fetchMoreDevices,
    mutateData,
    count,
    loadedCount,
  } = useAllAudioDevices({ first, offset, fetchPolicy: 'network-and-cache' });

  const hasSonos = user?.serviceType?.includes('SONOS');
  const hasQsys = user?.serviceType?.includes('QSYS');

  // Subscription for device updates
  const { devicesSubscription } = useDevicesSubscription({
    updatedIds: (allDevices && allDevices.map((item) => item?.id)) || [],
    userToken: localStorage.getItem('sessionToken'),
  });

  const { activateQsysService } = useActivateQsysService();
  const { activateSonosService } = useActivateSonosService();

  // Effect for handling device updates
  useEffect(() => {
    const updatedDevice = devicesSubscription?.AudioDeviceSubscription?.node;
    const localDeviceData = filteredDevices?.find(
      (item) => item.id === updatedDevice?.id
    );

    if (updatedDevice && localDeviceData) {
      const index = filteredDevices?.findIndex(
        (item) => item?.id === updatedDevice?.id
      );

      const newData = Object.assign({}, localDeviceData, {
        currentSong: {
          id: updatedDevice?.currentSongId,
        },
        description: updatedDevice?.description,
        deviceStatus: updatedDevice?.deviceStatus,
        id: updatedDevice?.id,
        isOnline: updatedDevice?.isOnline,
        isPlaying: updatedDevice?.isPlaying,
        name: updatedDevice?.name,
      });

      const newDeviceArray = filteredDevices ? [...filteredDevices] : [];
      newDeviceArray.splice(index, 1, newData);
      setFilteredDevices(newDeviceArray);
    }
  }, [devicesSubscription]);

  // Effect for handling search results
  useEffect(() => {
    if (searchText && !loadingSearch && !allSearchedDevices?.length) {
      setResultField('No matches found.');
      setFilteredDevices([]);
    } else if (searchText && !loadingSearch && allSearchedDevices?.length) {
      setResultField('');
      setFilteredDevices(allSearchedDevices);
    } else if (searchText && loadingSearch) {
      setResultField('Searching...');
    } else {
      setFilteredDevices(allDevices);
    }
  }, [searchText, loadingSearch, loading]);

  // Handler for search input change
  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  // Handler for loading more devices on scroll
  const handleLoadMoreOnScroll = async (e, all = false) => {
    const target = e.target;
    if (!displayLoading) {
      if (
        all ||
        (target.scrollHeight - target.scrollTop - 100 <= target.clientHeight &&
          !loading &&
          pageInfo &&
          searchText === '')
      ) {
        setDisplayLoading(count > loadedCount);
        const fetchMore = await fetchMoreDevices(all);
        const newEdges =
          fetchMore?.fetchMoreData?.viewer?.allAudioDevicesFavourites.edges ||
          [];
        setDisplayLoading(false);
        if (newEdges.length > 0) {
          const updateDevices = filteredDevices ? [...filteredDevices] : [];
          updateDevices.push(
            ...mutateData(
              fetchMore?.fetchMoreData?.viewer?.allAudioDevicesFavourites.edges
            )
          );
          setFilteredDevices(_.uniqBy(updateDevices, 'id'));
        }
        setDisplayLoading(false);
      }
    }
  };

  const handleActivateQsys = () => {
    activateQsysService().then(() => {
      toast.success('Q-SYS Service is active', {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      });
    });
  };

  const handleActivateSonos = () => {
    activateSonosService().then(() => {
      // refresh the page to get to sonos
      window.location.reload();
      toast.success('SONOS Service is active', {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      });
    });
  };

  // Function for generating device description based on its status
  const deviceDescription = (device) => {
    if (device.isOnline && device.isPlaying) {
      return device?.description;
    } else if (device.isOnline && !device.isPlaying) {
      return (
        <span style={{ position: 'absolute', top: '10em' }}>
          Device paused.
          <br />
          No music is scheduled to play at this time.
        </span>
      );
    } else {
      return (
        <span
          style={{
            position: 'absolute',
            top: '10em',
            zIndex: 10,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/${device.id}/support`);
          }}
        >
          Offline (last seen {moment(device?.lastOnline).fromNow()})
          <br />
          Get help
        </span>
      );
    }
  };

  // Effect for handling visibility of no devices modal
  useEffect(() => {
    const bool = !loading && !allDevices?.length && !filteredDevices?.length;
    setIsVisible(bool);
  }, [loading]);

  // Handler for closing no devices modal
  const onClose = () => {
    setIsVisible(false);
  };

  // Search bar component
  const searchBar = (
    <div className="map__search">
      <Field
        value={searchText}
        type="search"
        icon={<SearchSvg />}
        onChange={handleSearch}
        style={{ borderColor: '#393B5A' }}
      />

      {searchText && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
          onClick={() => setSearchText('')}
          className="svg-x"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 1L1 17M1 1l16 16"
          />
        </svg>
      )}
    </div>
  );

  // Render
  return (
    <DashboardLayout
      subtitle="Curator"
      title="Devices"
      backgroundImage={`url(${
        require('../../../assets/svg-icons/curator-background.svg').default
      })`}
      modifier="devices"
      onScroll={(e) => handleLoadMoreOnScroll(e)}
      showSwitcher={
        user?.serviceType?.includes('CURATOR') &&
        user?.serviceType?.includes('SONOS')
      }
    >
      <>
        {rdd?.isMobileOnly && (
          <h3 style={{ fontSize: 16, marginBottom: 20, paddingLeft: 13 }}>
            Device locations
          </h3>
        )}
        <Map
          loading={loading}
          data={filteredDevices}
          handleLoadMoreOnScroll={handleLoadMoreOnScroll}
        />
        <Devices showText={allDevices?.length} searchBar={searchBar}>
          <LoadingProvider loading={loading}>
            {!filteredDevices?.length ? (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: 85,
                }}
              >
                {!loading && !allDevices?.length && (
                  <>
                    {user && (
                      <h2 style={{ marginBottom: 10, fontSize: 16 }}>
                        You do not have any devices assigned yet.
                      </h2>
                    )}
                    <Toggle>
                      {({ on, toggle }) => (
                        <>
                          {user && (
                            <Button modifier="as-link" onClick={toggle}>
                              Request access
                            </Button>
                          )}
                          <NoDevicesModal
                            visible={user && isVisible}
                            onClose={onClose}
                            addDevice={toggle}
                          />
                          <Modal
                            subtitle="Curator"
                            title="Request access"
                            visible={on}
                            onClose={toggle}
                          >
                            <p>
                              To add a device provide us with the device tag
                              number (6 -digits), the location name or store
                              number.
                            </p>
                            <p>
                              If you are requesting access to multiple players,
                              regions or zones, please specify this in your
                              message.
                            </p>
                            <FormAddDevice toggle={toggle} />
                            <div className="form form--add-service">
                              <p>Looking for other service?</p>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Button
                                  modifier="primary"
                                  onClick={() => history.push('/sonos')}
                                >
                                  SONOS
                                </Button>
                                <Button
                                  modifier="primary"
                                  onClick={() => history.push('/qsys')}
                                >
                                  Q-SYS
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        </>
                      )}
                    </Toggle>
                  </>
                )}
                <h2 style={{ marginBottom: 10 }}>{resultField}</h2>
              </div>
            ) : (
              filteredDevices?.map((device, index) => (
                <Device
                  key={device.id}
                  deviceId={device.id}
                  deviceClientId={device.clientId}
                  currentSongId={device.currentSong?.id}
                  to={`/${device.id}`}
                  deviceName={device.name}
                  deviceIsOnline={device.isOnline}
                  deviceIsPlaying={device.isPlaying}
                  deviceDescription={deviceDescription(device)}
                  favourite={device.isFavourite}
                  color={
                    device.isOnline && device.isPlaying
                      ? getRandomColor(index % 15)
                      : '#262735'
                  }
                />
              ))
            )}
          </LoadingProvider>
        </Devices>
        {displayLoading && (
          <LoadingProvider
            loading={true}
            style={{ height: 50, marginTop: 90 }}
          />
        )}
      </>
    </DashboardLayout>
  );
};

export default DevicesPage;

/**
 * External dependencies
 */
import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

/**
 * Internal dependencies
 */
import { SEARCH_AUDIO_DEVICE } from 'domain/devices/queries/search-audio-device';

import getRandomColor from '../../../helpers/get-random-color';
import _ from 'lodash';

export default function useSearchDevices(options) {
  const { user } = useContext(AuthContext);

  let { data, loading, error } = useQuery(SEARCH_AUDIO_DEVICE, {
    variables: { text: options?.searchValue },
    skip: options?.searchValue === '',
    // fetchPolicy: 'network-only',
    //added now fetchpolicy
  });

  const allSearchedDevices =
    data?.viewer?.searchAudioDevice &&
    data.viewer.searchAudioDevice.map((device) => ({
      id: device.id,
      isOnline: device.isOnline,
      isPlaying: device.isPlaying,
      description: device.description,
      name: device?.name,
      location: {
        lat: parseFloat(device.latitude),
        lng: parseFloat(device.longitude),
      },
      currentPlaylist: {
        id: device.currentPlaylistId,
        name: device.currentPlaylistName,
      },
      remotePlaylistMode: device.remotePlaylistMode,
      currentSong: {
        id: device?.currentSongId,
        title: device?.currentSongData.node?.title,
        author: device?.currentSongData.node?.artist,
        album: device?.currentSongData.node?.album,
        cover: device?.currentSongData.node?.artworkUrl,
      },
      info: {
        internalIpAddress: device.internalIpAddress,
        publicIpAddress: device.publicIpAddress,
        macAddress: device.macAddress,
        ethernetMacAddress: device.ethernetMacAddress,
        makeAndModel: device.makeAndModel,
        lastOnline: device.lastOnlineFormatted,
        latitude: device.latitude,
        longitude: device.longitude,
      },
      scheduleIds: device.scheduleIds,
      remoteVolume: device.remoteVolume,
      clientId: device.clientId,
      volume: device.volume,
      isFavourite: user?.favouriteDevices?.includes(device.id),
      usersId: device.usersId,
      color:
        device.isOnline && device.isPlaying
          ? getRandomColor(_.random(14))
          : '#262735',
    }));

  return {
    allSearchedDevices,
    loading,
    error,
  };
}

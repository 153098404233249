import React from 'react';

import { ReactComponent as Image } from '../../assets/svg-icons/currentlyplaying.svg';
import { ReactComponent as Eq } from '../../assets/svg-icons/eq.svg';
import { ReactComponent as OnSchedule } from '../../assets/svg-icons/onschedule.svg';

import Button from '../button/button';
import { useHistory } from 'react-router-dom';
import useUpdateDeviceRemotePlaylistMode from '../../domain/dashboard/hooks/use-update-remote-playlist-mode';

const CurrentlyPlayingCardMobile = ({
  //   title,
  //   remotePlaylistMode,
  //   onClickReset,
  //   onClickCard,
  device,
}) => {
  const history = useHistory();

  const {
    updateDeviceRemotePlaylistMode,
    loading: remoteLoading,
    data,
  } = useUpdateDeviceRemotePlaylistMode();

  const resetSchedule = async (e) => {
    e.stopPropagation();
    await updateDeviceRemotePlaylistMode({
      variables: {
        deviceId: device.id,
      },
    });
  };

  const onNavigate = (e) => {
    e.stopPropagation();
    history.push(`/${device.id}/curated-mix`);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 258,
        backgroundColor: device?.remotePlaylistMode ? '#3BABA1' : '#E96187',
        borderRadius: 13,
        display: 'flex',
        flexDirection: 'row',
      }}
      //   onClick={onClickCard}
    >
      <div
        style={{
          flex: 1,
          padding: 25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Eq />
        <h1 style={{ fontSize: 16, marginBottom: 20 }} onClick={onNavigate}>
          {device?.remotePlaylistMode
            ? device?.currentPlaylist?.name
            : 'Curated Mix'}
        </h1>
        <p style={{ fontSize: 13 }}>
          {device?.remotePlaylistMode
            ? `You're currently off-schedule. To resume back to your standard music
          program select "Return to schedule"`
            : 'You are listening to a curated mix of playlists'}
        </p>
        <Button
          modifier="white2"
          style={{ width: '100%' }}
          onClick={device?.remotePlaylistMode ? resetSchedule : onNavigate}
        >
          {device?.remotePlaylistMode
            ? 'Return to schedule'
            : 'View recently played'}
        </Button>
      </div>
    </div>
  );
};

export default CurrentlyPlayingCardMobile;

/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import SystemDetail from 'components/system-detail/system-detail';
import UsersList from 'components/users-list/users-list';

import { ReactComponent as IcoTime } from 'assets/svg-icons/ico-time.svg';
import { ReactComponent as IcoIp } from 'assets/svg-icons/ico-ip.svg';
import { ReactComponent as IcoProcessor } from 'assets/svg-icons/ico-processor.svg';
import { ReactComponent as IcoInternalIp } from 'assets/svg-icons/ico-internal-ip.svg';
import { ReactComponent as IcoMacAddress } from 'assets/svg-icons/ico-mac-address.svg';
import { ReactComponent as IcoLocation } from 'assets/svg-icons/ico-location.svg';
import { ReactComponent as IcoWlan } from 'assets/svg-icons/ico-wlan.svg';
import Page404 from 'views/404/404';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import getFormattedDate from 'utils/get-formatted-date';
import toDMS from 'utils/toDMS';

const DeviceInfoPage = ({
  match: {
    params: { id },
  },
}) => {
  const { device, loading } = useSingleDevice(id);
  if (!loading && !device) {
    return <Page404 />;
  }

  const lastOnlineTime = new Date(device?.info?.lastOnline || null);
  const lastOnlineFormatted = getFormattedDate(lastOnlineTime);

  return (
    <DashboardLayout
      subtitle={device?.name || ''}
      title="Device Info"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="device-info"
    >
      <div className="system-details">
        <h4>System Details</h4>

        <div className="system-details__body">
          <SystemDetail
            icon={<IcoTime />}
            title="Last Online"
            info={device?.info?.lastOnline ? lastOnlineFormatted : 'Unknown'}
          />

          <SystemDetail
            icon={<IcoIp />}
            title="Public IP"
            info={device?.info?.publicIpAddress || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Model"
            info={device?.info?.makeAndModel || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoInternalIp />}
            title="Internal IP"
            info={device?.info?.internalIpAddress || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoMacAddress />}
            title="MAC Address"
            info={device?.info?.macAddress || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoLocation />}
            title="Location"
            info={
              device?.info?.longitude && device?.info?.latitude
                ? toDMS(device.info.latitude, device.info.longitude)
                : 'Unspecified'
            }
          />

          <SystemDetail
            icon={<IcoWlan />}
            title="WLAN MAC Address"
            info={device?.info?.ethernetMacAddress || 'Unspecified'}
          />
        </div>
      </div>

      <UsersList usersArray={device?.usersId || null} loading={!device} />
    </DashboardLayout>
  );
};

export default DeviceInfoPage;

/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { Toggle } from 'react-powerplug';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import FormTrackFeedback from 'blocks/forms/form-track-feedback';
import Modal from 'components/modal/modal';
import LoadingProvider from 'components/loading-provider/loading-provider';
import ProgressRange from 'components/volume-slider/volume-slider';
import getRandomArtwork from 'helpers/get-random-artwork';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import useAuth from '../../auth/hooks/use-auth';
import useGetSongReactionsByDeviceId from '../hooks/use-get-song-reactions-by-deviceid';

const Player = ({
  title,
  author,
  album,
  cover,
  zone,
  lastOnline,
  loading,
  isOnline,
  isPlaying,
  manuallyPaused,
  hideVolumeBar,
  pausedStatus,
  hasSchedule,
  reason,
}) => {
  const lastOnlineTime = new Date(lastOnline).getDate() - new Date().getDate();
  const { user, currentDevice } = useAuth();
  const [reactionText, setReactionText] = useState('No reactions');
  const [offlineArtwork, setOfflineArtwork] = useState(getRandomArtwork());
  const [modalHandler, setModalHandler] = useState(false);
  const [reactionCount, setReactionCount] = useState([]);

  const { getSongReactionsByDeviceId, refetch } = useGetSongReactionsByDeviceId(
    currentDevice,
    zone?.songId
  );

  const reactions = getSongReactionsByDeviceId;

  const volumeBar =
    user?.audioClient?.noVolumeBar && user?.rolesConsole?.roleAudio === 5;
  const playPauseButton =
    user?.audioClient?.noPlayPause && user?.rolesConsole?.roleAudio === 5;

  const emojis = [
    require('../../../assets/images/emoji-party.png').default,
    require('../../../assets/images/emoji-wow.png').default,
    require('../../../assets/images/emoji-hearts.png').default,
    require('../../../assets/images/emoji-ok.png').default,
    require('../../../assets/images/emoji-frowning.png').default,
    require('../../../assets/images/emoji-bore.png').default,
    require('../../../assets/images/emoji-explicit.png').default,
    require('../../../assets/images/emoji-eugh.png').default,
  ];

  useEffect(() => {
    setReactionCount(_.uniq(reactions?.map((item) => item.songReaction)));
    if (!reactions?.length) {
      setReactionText('No reactions.');
    } else if (
      reactions.map((item) => item.userName).includes(user?.profile?.name) &&
      reactions.length > 1
    ) {
      setReactionText(`You and ${reactions.length - 1} others`);
    } else if (
      user?.profile?.name !== reactions[0]?.userName &&
      reactions.length > 1
    ) {
      setReactionText(
        `${reactions[0].userName} and ${reactions.length - 1} others`
      );
    } else if (user?.profile?.name !== reactions[0]?.userName) {
      setReactionText(`${reactions[0]?.userName}`);
    } else {
      setReactionText(`You've reacted.`);
    }
  }, [reactions, user]);

  useEffect(() => {
    setOfflineArtwork(getRandomArtwork());
    if (isOnline) {
      setModalHandler(false);
    }
  }, [title]);

  const statusCheck = () => {
    if (reason && reason === 'pause') {
      return (
        <>
          <h5>Hear that? ...nope</h5> <h2>Device Paused</h2>
        </>
      );
    }

    if (isOnline) {
      if (isPlaying && !manuallyPaused) {
        return (
          <>
            <h5>Current track</h5> <h2>{title}</h2>
          </>
        );
      } else {
        return (
          <>
            <h5>Hear that? ...nope</h5> <h2>Device Paused</h2>
          </>
        );
      }
    } else {
      return (
        <>
          <h5>Kkkrrrrr... [White noise]</h5> <h2>You're Offline</h2>
        </>
      );
    }
  };

  const pausedState = () => {
    if (manuallyPaused) {
      return <p>Music manually paused.</p>;
    } else if (hasSchedule) {
      if (pausedStatus === 'No active playlist today' || pausedStatus === '') {
        return <p>No active playlist today.</p>;
      } else {
        return <p>{`Music is scheduled to resume at ${pausedStatus}`}</p>;
      }
    } else {
      return <p>Device has no schedule assigned.</p>;
    }
  };

  const descriptionCheck = () => {
    if (reason && reason === 'pause') {
      return (
        <>
          <p>No music is scheduled to play at this time.</p>
          <p>Request a change to your schedule.</p>
        </>
      );
    }

    if (isOnline && isPlaying) {
      return (
        <>
          <p>by {author}</p> <p>from {album}</p>
        </>
      );
    } else if (isOnline && !isPlaying) {
      return (
        <>
          {pausedState()}
          <p>
            Request a change to your schedule or{' '}
            <Link style={{ color: '#3BABA1' }} to={`/qsys/${zone?.zoneId}`}>
              Play music.
            </Link>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>Activaire can't connect to this device</p>
          <p>Last online {lastOnlineTime} days ago</p>
        </>
      );
    }
  };

  const setCover = () => {
    if (reason === 'pause' || (isOnline && !(isPlaying && !manuallyPaused))) {
      return <img src={offlineArtwork} alt={'Offline'} />;
    }
    return <img src={!cover ? `${offlineArtwork}` : cover} alt={title} />;
  };

  return (
    <div className="player-container">
      <div className="player">
        <LoadingProvider loading={loading}>
          <aside className="player__image">{setCover()}</aside>

          <div className="player__content">
            {statusCheck()}

            <div className="player__description">{descriptionCheck()}</div>

            <div className="player__feedback">
              <div className="player__feedback-text">
                {reactions &&
                  !!reactions.length &&
                  emojis?.map((item, index) => {
                    if (_.includes(reactionCount, index + 1)) {
                      return (
                        <img
                          key={item}
                          style={{ width: 25, marginLeft: -20 }}
                          src={item}
                          alt=""
                        />
                      );
                    }
                  })}

                <Button modifier="as-link">{reactionText}</Button>
              </div>

              <Toggle>
                {({ on, toggle }) => {
                  return (
                    <>
                      <Button
                        modifier="primary"
                        onClick={() => {
                          toggle();
                          setModalHandler(true);
                        }}
                      >
                        Feedback
                      </Button>

                      <Modal
                        styles={{ maxWidth: 500 }}
                        visible={on || modalHandler}
                        onClose={() => {
                          toggle();
                          setModalHandler(false);
                        }}
                      >
                        <FormTrackFeedback
                          userReaction={reactions && user ? reactions : 'null'}
                          onSubmit={() => {
                            toggle();
                            setModalHandler(false);
                            refetch();
                          }}
                          zone={zone}
                        />
                      </Modal>
                    </>
                  );
                }}
              </Toggle>
            </div>
          </div>
        </LoadingProvider>
      </div>

      {isOnline && title && !hideVolumeBar && !volumeBar && (
        <ProgressRange deviceId={zone?.zoneId} volume={zone?.volume} />
      )}
    </div>
  );
};

export default Player;

/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const TOGGLE_SERVICE_TYPE = gql`
  mutation toggleServiceType($serviceType: String!) {
    toggleServiceType(serviceType: $serviceType) {
      id
      defaultServiceType
    }
  }
`;

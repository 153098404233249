/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ACTIVATE_SONOS_SERVICE } from '../mutations/activate-sonos-service';

export default function useActivateSonosService() {
  const [activateSonosService, { loading }] = useMutation(
    ACTIVATE_SONOS_SERVICE
  );

  return { activateSonosService, loading };
}

/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SET_SONOS_DEVICE_VOLUME } from '../mutations/set-sonos-device-volume';

export default function useSetSonosDeviceVolume() {
  const [setSonosDeviceVolume, { loading, data }] = useMutation(
    SET_SONOS_DEVICE_VOLUME
  );

  return { setSonosDeviceVolume, loading, data };
}

/**
 * External dependencies
 */
import { useSubscription } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SUBSCRIBE_QSYS_DEVICE } from '../subscriptions/subscribe-qsys-device';

export default function useQsysDeviceSubscription() {
  const { data, loading } = useSubscription(SUBSCRIBE_QSYS_DEVICE, {
    variables: {},
  });

  return {
    qsysDevicesSubscription: data,
    qsysDevicesSubscriptionLoading: loading,
  };
}

const capitalize = (string) => {
  if (string.includes('&')) {
    let newString = '';
    const words = string.split(/( {0,}& {0,})/g);
    words.forEach((item) => {
      newString += (item && item[0].toUpperCase() + item.slice(1)) || '';
    });
    return newString;
  } else {
    return (string && string[0].toUpperCase() + string.slice(1)) || '';
  }
};

export default capitalize;
